import React from "react"
import { Link } from "react-router-dom"

import logo from "assets/svgs/databashi_Logo.svg"

import "./Header.css"

const Header = () => {
  return (
    <header id="top-bar" className="px-2">
      <div className="container">
        <div className="w-100 py-2 d-flex align-items-center justify-content-between">
          <img
            src={logo}
            alt="DataBashi - The Brand logo"
            className="brandLogo"
          ></img>
          <div className="d-flex align-items-center gap-4">
            <Link to="/auth/login" className="">
              <span>Login</span>
            </Link>
            <Link to="/auth/signup" className="">
              <span>Register</span>
            </Link>
            <a
              aria-label="contactUs-btn"
              href="#contact-us"
              className="d-flex flex-row align-items-center btn btn-primary btn-sm rounded-5"
            >
              Contact us
            </a>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
