import React from "react"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import LoginImg from "assets/images/profile-img.png"

import DatabashiLogo from "components/databashiLogo"

import RegisterForm from "./registerForm"

import "./signup.css"
import { ENUMS } from "utils/enums"

const Signup = () => {
  return (
    <div className="card-wrapper">
      <Helmet>
        <title>Register | {ENUMS.BRAND_NAME}</title>
      </Helmet>
      <div className="banner-section">
        <img src={LoginImg} alt="login" className="banner-img" />
      </div>
      <div className="right-section">
        <div className="form-container">
          <div className="d-flex mb-2 w-100 align-items-center justify-content-center">
            <DatabashiLogo />
          </div>
          <div className="form-header">
            <h2>Signup</h2>
            <p>
              Already have an Account? <Link to="/auth/login">Login</Link>
            </p>
          </div>
          <RegisterForm />
        </div>
      </div>
    </div>
  )
}

export default Signup
