import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const MongoDbToBigqueryBlog = () => {
  const data = tags.find(items => items.blogName === "mongodbBigquery")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        In the rapidly evolving landscape of data management, organizations
        often find the need to migrate from NoSQL databases like MongoDB to
        modern cloud-based data warehouses such as Google BigQuery. In this blog
        post, we will explore an efficient and reliable approach to MongoDB to
        bigquery data migration using the dynamic duo of Benthos and Temporal.
      </p>
      <h4>MongoDB</h4>
      <p>
        MongoDB is a NoSQL database that stores data in flexible, JSON-like
        documents. It is designed for applications that require flexibility and
        scalability, making it ideal for projects with evolving data structures.
      </p>
      <h4>Bigquery</h4>
      <p>
        Bigquery is a cloud-based data warehousing platform designed to handle
        large volumes of structured and semi-structured data. It provides a
        fully managed and scalable solution for storing, processing, and
        analyzing data in the cloud. bigquery is known for its unique
        architecture, ease of use, and support for a wide range of data
        analytics and business intelligence applications.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source framework for building, testing, and
        deploying distributed systems. It provides developers with an easy way
        to manage workflows, maintain states, and handle errors. Temporal is
        particularly useful for managing long-running processes and complex,
        multi-step workflows.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos, on the other hand, is a powerful data streaming tool that can
        be used to connect various data sources and sinks. It provides a lot of
        flexibility in terms of data processing, transformation, and routing.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Input Conversion</b>
          <p>
            Click on the <b>Choose Input Conversion</b> dropdown and select{" "}
            <b>MongoDB</b>.
          </p>
          <img
            src="/assets/images/mongodb/mongodb-to-bigquery/snip-1.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on the <b>Choose Conversion</b> dropdown and select{" "}
            <b>MongoDB To Bigquery</b>.
          </p>
          <img
            src="/assets/images/mongodb/mongodb-to-bigquery/snip-2.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Validate the DB URL</b>
          <p>
            Enter the URL of the MongoDB database and click on validate button.
          </p>
          <img
            src="/assets/images/mongodb/mongodb-to-bigquery/snip-3.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select the Database</b>
          <p>
            Choose the database from the dropdown by clicking on the dropdown
            menu.
          </p>
          <img
            src="/assets/images/mongodb/mongodb-to-bigquery/snip-4.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select output Collection</b>
          <p>Select the collection from already created collections.</p>
          <img
            src="/assets/images/mongodb/mongodb-to-bigquery/snip-5.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Enter the Dataset of your Bigquery Database</b>
          <p>Specify the name of your dataset in the BigQuery database.</p>
          <img
            src="/assets/images/mongodb/mongodb-to-bigquery/snip-6.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select the Service Account File</b>
          <p>
            Select the JSON file of your service account you have downloaded
            from bigquery and click on verify button.
          </p>
          <img
            src="/assets/images/mongodb/mongodb-to-bigquery/snip-7.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select output table</b>
          <p>
            Select the output table from the list provided below. If you do not
            see the tables, kindly ensure that you have entered the correct
            dataset or verify that your database indeed contains tables.
          </p>
          <img
            src="/assets/images/mongodb/mongodb-to-bigquery/snip-8.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>
            Now we need to run the migration by clicking on Save & Run button
          </p>
          <img
            src="/assets/images/mongodb/mongodb-to-bigquery/snip-9.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/mongodb/mongodb-to-bigquery/activity.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default MongoDbToBigqueryBlog
