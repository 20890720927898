import React, { useContext, useState } from "react"
import PropTypes from "prop-types"

export const FileHandlingContext = React.createContext(null)

const FileHandlingContextWrapper = ({ children }) => {
  const [fileName, setFileName] = useState("")
  const [filePath, setFilePath] = useState("")

  return (
    <FileHandlingContext.Provider
      value={{ fileName, setFileName, filePath, setFilePath }}
    >
      {children}
    </FileHandlingContext.Provider>
  )
}

FileHandlingContextWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export const useFileHandlingContext = () => {
  return useContext(FileHandlingContext)
}
export default FileHandlingContextWrapper
