import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const SnowflakeToMySQLBlog = () => {
  const data = tags.find(items => items.blogName === "snowflakeMysql")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        In the dynamic landscape of data management, the need for seamless
        integration between different databases is more crucial than ever.
        Snowflake and MySQL are two powerful databases that serve distinct
        purposes, and combining their strengths can be a game-changer for
        businesses. In this blog post, we will explore how to achieve a smooth
        data flow from Snowflake to MySQL using the powerful combination of
        Benthos and Temporal.
      </p>
      <h4>Snowflake</h4>
      <p>
        Snowflake is a cloud-based data warehousing platform designed to handle
        large volumes of structured and semi-structured data. It provides a
        fully managed and scalable solution for storing, processing, and
        analyzing data in the cloud. Snowflake is known for its unique
        architecture, ease of use, and support for a wide range of data
        analytics and business intelligence applications.
      </p>
      <h4>MySQL</h4>
      <p>
        MySQL is a popular open-source relational database management system. It
        is known for its stability, reliability, and performance in handling
        structured data. MySQL employs a table-based data storage model with
        ACID compliance, making it ideal for scenarios where data consistency
        and integrity are paramount.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source orchestration platform that enables you to
        build and manage complex workflows. It is designed to handle
        long-running and stateful processes, making it a powerful tool for
        orchestrating data migration and synchronization tasks.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos is a versatile open-source data processing tool that excels at
        connecting various data sources and sinks. It offers a range of
        processors and connectors for data transformation, enrichment, and
        routing, making it an excellent tool for creating data pipelines.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Input Conversion</b>
          <p>
            Click on the <b>Choose Input Conversion</b> dropdown and select{" "}
            <b>Snowflake</b>.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-mysql/snip-1.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on <b>Choose Conversion</b> dropdown and select{" "}
            <b>Snowflake to MySQL</b>.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-mysql/snip-2.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Validate the DB URL</b>
          <p>
            Enter the URL of the Snowflake database and click on validate
            button.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-mysql/snip-3.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select Columns and WHERE clause</b>
          <p>
            Select the Columns from the tables shown below and then choose the
            WHERE clause.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-mysql/snip-4.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Validate Output DB URL</b>
          <p>
            Enter the URL of the MySQL database and click on validate button.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-mysql/snip-5.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select output Table</b>
          <p>
            Select the output table from the list provided below. If you do not
            see the tables, kindly ensure that you have entered the correct
            database URL or verify that your database indeed contains tables.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-mysql/snip-6.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>
            Now we need to run the migration by clicking on Save & Run button
          </p>
          <img src="/assets/images/snowflake/snowflake-to-mysql/snip-7.png" />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/snowflake/snowflake-to-mysql/activity.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default SnowflakeToMySQLBlog
