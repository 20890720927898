import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Col, Modal } from "reactstrap"

import Loader from "../../components/Loader"

import { getEmail, subscriptionURL } from "apis/email"

import axiosInstance from "services/axiosInstance"

const EmailBasicTemplate = () => {
  const [isLoading, setLoading] = useState(true)
  const [modal_standard, setmodal_standard] = useState(false)
  const [email, setEmail] = useState("")

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      fetchEmail()
    }
    return () => {
      isMounted = false
    }
  }, [])

  const fetchEmail = async () => {
    try {
      const {
        data: { Email },
      } = await axiosInstance.get(getEmail())
      setEmail(Email)
      setLoading(false)
    } catch (err) {
      console.log("getEmail api failed: ", err)
      setLoading(false)
    }
  }

  const handleSubscription = async () => {
    try {
      await axiosInstance.post(subscriptionURL(), {
        email,
      })
      tog_standard()
    } catch (err) {
      console.log("subscription api failed: ", err)
    }
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const tog_standard = () => {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>Data Bash Dev</title>
        </Helmet>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="container">
            <div className="row email-template">
              <p className="mb-4 card-title">Email Subscription</p>
              <Col md="6" style={{ backgroundColor: "white", padding: "2vh" }}>
                <div
                  className="body-wrap"
                  style={{
                    boxSizing: "border-box",
                    fontSize: "14px",
                    margin: "0",
                    width: "100%",
                    backgroundColor: "transparent",
                  }}
                >
                  <div>
                    <div
                      style={{
                        boxSizing: "border-box",
                        fontSize: "14px",
                        margin: "0px",
                      }}
                    >
                      <div
                        className="content-block"
                        style={{
                          boxSizing: "border-box",
                          verticalAlign: "top",
                          margin: "0px",
                          padding: "0 0 20px",
                        }}
                      >
                        Enter your Email here
                      </div>
                    </div>
                    <div
                      className="content-block"
                      style={{
                        boxSizing: "border-box",
                        verticalAlign: "top",
                        margin: "0px",
                      }}
                    >
                      <div className="mb-3">
                        <input
                          className="form-control"
                          type="text"
                          value={email || ""}
                          placeholder="Email Here"
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleSubscription()}
                      >
                        Subscribe Now
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </div>
        )}
        <Modal
          isOpen={modal_standard}
          toggle={() => {
            tog_standard()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel"></h5>
            <button
              type="button"
              onClick={() => {
                setmodal_standard(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h5>Email Subscribe Successfully</h5>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                tog_standard()
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default EmailBasicTemplate
