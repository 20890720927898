/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Input } from "reactstrap"
import { ActivitiesEnum, InputActivitiesEnum } from "utils/enums"

const ConversionSelector = ({
  setConfiguration,
  allActivities,
  setUserInputs,
  setUserOutputs,
}) => {
  const [inputValue, setInput] = useState(InputActivitiesEnum.Postgres)
  const [conversion, setConversion] = useState(ActivitiesEnum.PostgresToMongodb)
  useEffect(() => {
    setInput(inputValue)
    const config = allActivities.filter(activity =>
      activity.name.startsWith(inputValue)
    )
    setUserInputs({})
    setUserOutputs({})
    setConfiguration(config[0].name)
  }, [inputValue])

  const handleChangeConfiguration = value => {
    setUserInputs({})
    setUserOutputs({})
    setConfiguration(value)
  }

  const uniqueInputTypes = [
    ...new Set(allActivities.map(activity => activity.name.split(" ")[0])),
  ]
  const filteredActivities = allActivities.filter(activity =>
    activity.name.startsWith(inputValue)
  )

  return (
    <>
      <div className="mb-3">
        <label className="">Choose Input Conversion</label>
        <Input
          className="form-control"
          type="select"
          onChange={e => {
            setInput(e.target.value.split(" ")[0])
            setConversion(
              allActivities.filter(activity =>
                activity.name.startsWith(inputValue)
              )
            )
          }}
          value={inputValue}
        >
          {uniqueInputTypes.map(inputType => (
            <option key={inputType} value={inputType}>
              {inputType}
            </option>
          ))}
        </Input>
      </div>
      <div className="mb-3">
        <label className="">Choose Conversion</label>
        <Input
          className="form-control"
          type="select"
          onChange={e => {
            setConversion(e.target.value)
            handleChangeConfiguration(e.target.value)
          }}
          value={conversion}
        >
          {filteredActivities.map(inputType => (
            <option key={inputType.name} value={inputType.name}>
              {inputType.name}
            </option>
          ))}
        </Input>
      </div>
    </>
  )
}

export default ConversionSelector
