import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { loadStripe } from "@stripe/stripe-js"
import Cookies from "js-cookie"

import { useAuthContext } from "./authContextWrapper"

import { updateStripeModeURL } from "apis/stripe"
import axiosInstance from "services/axiosInstance"

export const StripeContext = React.createContext(null)

const StripeContextWrapper = ({ children }) => {
  const { user, storeUser, isAddon } = useAuthContext()
  // stripe states
  const [stripe, setStripe] = useState({
    mode: "live",
    promise:
      isAddon || REACT_APP_IS_DOCKERIZED
        ? ""
        : loadStripe(REACT_APP_STRIPE_LIVE_PUBLISH_KEY),
    access: !(isAddon || REACT_APP_IS_DOCKERIZED),
  })

  useEffect(() => {
    if (isAddon || REACT_APP_IS_DOCKERIZED) return null
    let mode = "live"
    if (Cookies.get("stripeMode")) {
      mode = Cookies.get("stripeMode")
    }
    if (user && user.additionalInfo) {
      mode = user.additionalInfo.stripeMode === "test" ? "test" : "live"
    }
    const key =
      mode === "test"
        ? REACT_APP_STRIPE_TEST_PUBLISH_KEY
        : REACT_APP_STRIPE_LIVE_PUBLISH_KEY
    setStripe(prevState => ({
      ...prevState,
      mode,
      promise: loadStripe(key),
    }))

    Cookies.set("stripeMode", mode)
  }, [user])

  console.log(
    "isAddon: ",
    isAddon,
    "access: ",
    stripe.access,
    "isDockerized ? ",
    REACT_APP_IS_DOCKERIZED
  )

  const handleStripeMode = async event => {
    const mode = event.target.checked ? "test" : "live"
    Cookies.set("stripeMode", mode)
    setStripe(prevState => ({ ...prevState, mode }))
    try {
      await axiosInstance.put(updateStripeModeURL(), {
        stripeMode: event.target.checked ? "test" : "live",
      })
      setStripe(prevState => ({
        ...prevState,
        promise: loadStripe(
          mode === "test"
            ? REACT_APP_STRIPE_TEST_PUBLISH_KEY
            : REACT_APP_STRIPE_LIVE_PUBLISH_KEY
        ),
      }))
      window.location.reload()
      storeUser()
    } catch (err) {
      console.log("Failed to update stripe mode: ", err)
    }
  }

  const data = useMemo(
    () => ({
      stripeMode: stripe.mode,
      stripePromise: stripe.promise,
      hasStripeAccess: stripe.access,
      handleStripeMode,
      setStripe,
    }),
    [user, stripe.mode, stripe.promise, stripe.access, setStripe]
  )

  return (
    <StripeContext.Provider value={data}>{children}</StripeContext.Provider>
  )
}

StripeContextWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export const useStripeContext = () => {
  return useContext(StripeContext)
}
export default StripeContextWrapper
