import React, { useState } from "react"
import { Tooltip } from "reactstrap"
import PropTypes from "prop-types"

const CustomTooltip = ({ target = "", description = "" }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen(prevState => !prevState)

  return (
    <Tooltip
      placement="auto"
      isOpen={tooltipOpen}
      target={target}
      toggle={toggle}
    >
      {description}
    </Tooltip>
  )
}

CustomTooltip.propTypes = {
  target: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
export default CustomTooltip
