import { ForgetPassword, Login, ResetPassword, Signup } from "pages/Auth"
import Billing from "pages/Billing"
import Dashboard from "pages/Dashboard"
import EmailBasicTemplate from "pages/Email/email-basic-template"
import Invoices from "pages/Invoices"
import LandingPage from "pages/LandingPage"
import Profile from "pages/Profile"
import SelfHostedDatabashiPoweredByBenthos from "pages/SelfHostedDatabashiPoweredByBenthos"
import DeveloperSettings from "pages/DeveloperSettings"
import ActivityLogs from "pages/ActivityLogs"
import BlogDetails from "pages/BlogDetails"

const PLATFORMS_COMMON_ROUTES = [
  {
    path: "/activity-logs/:childWorkflowId",
    element: ActivityLogs,
    layout: true,
  },
]

const COMMON_NOT_ADDON_ROUTES = [
  {
    path: "/auth/login",
    element: Login,
    layout: false,
  },
  {
    path: "/auth/signup",
    element: Signup,
    layout: false,
  },
  {
    path: "/auth/forget-password",
    element: ForgetPassword,
    layout: false,
  },
  {
    path: "/auth/reset-password/:token",
    element: ResetPassword,
    layout: false,
  },
  {
    path: "/blog/:blogId",
    element: BlogDetails,
    layout: false,
  },
  ...PLATFORMS_COMMON_ROUTES,
  {
    path: "/profile",
    element: Profile,
    layout: true,
  },
  {
    path: "/developer-settings",
    element: DeveloperSettings,
    layout: true,
  },
  {
    path: "/dashboard",
    element: Dashboard,
    layout: true,
    exact: true,
  },
  {
    path: "/self-hosted-databashi-powered-by-benthos",
    element: SelfHostedDatabashiPoweredByBenthos,
    layout: false,
  },
]

export const STRIPE_ROUTES = [
  {
    path: "/billing/invoices",
    element: Invoices,
    layout: true,
    stripe: true,
    exact: true,
  },
  {
    path: "/billing",
    element: Billing,
    layout: true,
    stripe: true,
    exact: true,
  },
]

export const CLOUD_PLATFORM_ROUTES = [
  {
    path: "/",
    element: LandingPage,
    layout: false,
    exact: true,
  },
  ...COMMON_NOT_ADDON_ROUTES,
  ...STRIPE_ROUTES,
]

export const DOCKER_PLATFORM_ROUTES = [...COMMON_NOT_ADDON_ROUTES]

export const ADDON_ROUTES = [
  {
    path: "/",
    element: Dashboard,
    layout: true,
    exact: true,
  },
  ...PLATFORMS_COMMON_ROUTES,
  {
    path: "/email-subscription",
    element: EmailBasicTemplate,
    layout: true,
  },
]
