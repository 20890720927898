/* eslint-disable react/prop-types */
import React from "react"
import { compact } from "lodash"
import { FormFeedback, Input } from "reactstrap"

import useRedis from "hooks/useRedis"

import { Spinner } from "components"
import ReactstrapInput from "components/Inputs/ReactstrapInput"
import TextField from "components/Inputs/textField"

const RedisOutputsForm = ({
  configurationData,
  userOutputs,
  setUserOutputs,
}) => {
  const {
    isDisable,
    isLoading,
    status,
    error,
    channels,
    _SetError,
    setStatus,
    validateConnection,
  } = useRedis()

  const handleChangeChannels = ({ key, event }) => {
    let channels = compact(
      Array.from(event.target.selectedOptions, option => option.value)
    )
    setUserOutputs({
      ...userOutputs,
      [key]: channels.join(","),
    })
  }

  return (
    <>
      {configurationData?.activity?.requiredOutputs.map(
        (requiredOutput, index) => {
          return (
            <div className="mb-3" key={index}>
              {requiredOutput.Key === "url" ? (
                <div className="d-flex flex-row w-100">
                  <div className="w-100 me-2">
                    <ReactstrapInput
                      className={`form-control ${
                        status === null
                          ? ""
                          : status === true
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      name={requiredOutput.Key}
                      value={userOutputs[requiredOutput.Key] || ""}
                      placeholder={
                        requiredOutput.isRequired === true
                          ? requiredOutput.Label + "*"
                          : requiredOutput.Label
                      }
                      onChange={e => {
                        if (!e.target.value) setStatus(false)
                        if (!e.target.value) _SetError()
                        setUserOutputs({
                          ...userOutputs,
                          [requiredOutput.Key]: e.target.value.trim(),
                        })
                      }}
                    />
                    {error && <FormFeedback invalid>{error}</FormFeedback>}
                  </div>
                  <div className="">
                    <button
                      disabled={!userOutputs?.url || isLoading ? true : false}
                      className="d-flex btn btn-primary align-items-center"
                      onClick={() => validateConnection(userOutputs.url)}
                    >
                      <span>Validate</span> {isLoading && <Spinner />}
                    </button>
                  </div>
                </div>
              ) : requiredOutput.Key === "channels" ? (
                <Input
                  className="form-control"
                  type="select"
                  name={requiredOutput.Key}
                  placeholder={
                    requiredOutput.isRequired === true
                      ? requiredOutput.Label + "*"
                      : requiredOutput.Label
                  }
                  multiple
                  disabled={isDisable}
                  onChange={e =>
                    handleChangeChannels({
                      key: requiredOutput.Key,
                      event: e,
                    })
                  }
                >
                  <option value="" disabled selected>
                    {requiredOutput.isRequired === true
                      ? requiredOutput.Label + "*"
                      : requiredOutput.Label}
                  </option>
                  {channels.map(channel => (
                    <option key={channel} value={channel}>
                      {channel}
                    </option>
                  ))}
                </Input>
              ) : (
                <TextField
                  name={requiredOutput.Key}
                  placeholder={
                    requiredOutput.isRequired === true
                      ? requiredOutput.Label + "*"
                      : requiredOutput.Label
                  }
                  disabled={isDisable}
                  onChange={e => {
                    setUserOutputs({
                      ...userOutputs,
                      [requiredOutput.Key]: e.target.value.trim(),
                    })
                  }}
                />
              )}
            </div>
          )
        }
      )}
    </>
  )
}

export default RedisOutputsForm
