import React from "react"
import { Route } from "react-router-dom"
import PropTypes from "prop-types"
import { Elements } from "@stripe/react-stripe-js"
import BasicLayout from "Layout/basicLayout"
import { useStripeContext } from "context/stripeContextWrapper"

const LocalRoute = ({
  children,
  layout,
  path,
  hasStripeAccess,
  isStripePath,
}) => {
  const { stripePromise } = useStripeContext()

  if (layout) {
    return (
      <BasicLayout>
        {hasStripeAccess && isStripePath ? (
          <Route exact path={path}>
            <Elements stripe={stripePromise}>{children}</Elements>
          </Route>
        ) : (
          <Route exact path={path}>
            {children}
          </Route>
        )}
      </BasicLayout>
    )
  }
  return (
    <Route exact path={path}>
      {children}
    </Route>
  )
}

LocalRoute.propTypes = {
  children: PropTypes.node.isRequired,
  layout: PropTypes.bool,
  path: PropTypes.string,
  hasStripeAccess: PropTypes.bool,
  isStripePath: PropTypes.bool,
}

export default LocalRoute
