import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import UseLoadingHook from "hooks/useLoading"

import { forgotPassword } from "apis/auth"
import axiosInstance from "services/axiosInstance"
import { ForgetPasswordSchema } from "utils/validationSchema"

const INITIAL_VALUES = {
  email: "",
}

const useForgetPassword = () => {
  const history = useHistory()
  const { disableLoading, enableLoading, isLoading } = UseLoadingHook()
  const [resMsg, setResMsg] = useState("")

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm({
    defaultValues: INITIAL_VALUES,
    mode: "all",
    reValidateMode: "onSubmit",
    resolver: yupResolver(ForgetPasswordSchema),
  })

  const onSubmit = async data => {
    enableLoading()
    try {
      const {
        data: { message },
      } = await axiosInstance.post(forgotPassword(), data)
      setResMsg(message)
      disableLoading()
      history.push("/auth/login")
    } catch (err) {
      console.log("Forget Password Api failed: ", err)
      disableLoading()
    }
  }

  return {
    register,
    handleSubmit,
    onSubmit,
    isLoading,
    errors,
    touchedFields,
    resMsg,
  }
}

export default useForgetPassword
