import React, { useState } from "react"

import UseLoadingHook from "./useLoading"
import UseStatus from "./useStatus"

import axiosInstance from "services/axiosInstance"

const UseRedis = () => {
  const { disableLoading, enableLoading, isLoading } = UseLoadingHook()
  const { error, _SetError } = UseStatus()
  const [status, setStatus] = useState(null)
  const [isDisable, setIsDisable] = useState(true)
  const [channels, setChannels] = useState([])

  const validateConnection = async connectionURL => {
    try {
      enableLoading()
      const {
        data: { channels },
      } = await axiosInstance.post(`/rest/v1/connect-redis`, {
        connectionURL,
      })
      _SetError(null)
      setStatus(true)
      setChannels(channels)
      disableLoading()
      setIsDisable(false)
    } catch (err) {
      console.log("Redis connection failed to established: ", err)
      _SetError(err.response.data.message)
      disableLoading()
      setStatus(false)
      setIsDisable(true)
    }
  }

  return {
    status,
    channels,
    isLoading,
    isDisable,
    error,
    setStatus,
    _SetError,
    validateConnection,
    setIsDisable,
    setChannels,
  }
}

export default UseRedis
