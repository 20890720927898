import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const SnowflakeToMongoDbBlog = () => {
  const data = tags.find(items => items.blogName === "snowflakeMongodb")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        In the era of cloud-native data warehousing, organizations often find
        the need to transition from NoSQL databases like MongoDB to powerful
        cloud-based platforms such as Snowflake. In this blog post, we will
        explore an efficient and reliable approach to MongoDB to Snowflake data
        migration using the dynamic duo of Benthos and Temporal.
      </p>
      <h4>Snowflake</h4>
      <p>
        Snowflake is a cloud-based data warehousing platform designed to handle
        large volumes of structured and semi-structured data. It provides a
        fully managed and scalable solution for storing, processing, and
        analyzing data in the cloud. Snowflake is known for its unique
        architecture, ease of use, and support for a wide range of data
        analytics and business intelligence applications.
      </p>
      <h4>MongoDB</h4>
      <p>
        MongoDB is a NoSQL database that stores data in flexible, JSON-like
        documents. It is designed for applications that require flexibility and
        scalability, making it ideal for projects with evolving data structures.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source framework for building, testing, and
        deploying distributed systems. It provides developers with an easy way
        to manage workflows, maintain states, and handle errors. Temporal is
        particularly useful for managing long-running processes and complex,
        multi-step workflows.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos, on the other hand, is a powerful data streaming tool that can
        be used to connect various data sources and sinks. It provides a lot of
        flexibility in terms of data processing, transformation, and routing.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Input Conversion</b>
          <p>
            Click on the <b>Choose Input Conversion</b> dropdown and select{" "}
            <b>Snowflake</b>.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-mongodb/snip-1.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on the <b>Choose Conversion</b> dropdown and select{" "}
            <b>Snowflake To MongoDB</b>.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-mongodb/snip-2.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Validate the DB URL</b>
          <p>
            Enter the URL of the Snowflake database and click on validate
            button.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-mongodb/snip-3.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select Columns and WHERE clause</b>
          <p>
            Select the Columns from the tables shown below and then choose the
            WHERE clause.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-mongodb/snip-4.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Validate Output DB URL</b>
          <p>
            Enter the URL of the MongoDB database and click on validate button.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-mongodb/snip-5.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select the Database</b>
          <p>
            Choose the output database from the dropdown by clicking on the
            dropdown menu.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-mongodb/snip-6.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select output Collection</b>
          <p>Select the collection from already created collections.</p>
          <img
            src="/assets/images/snowflake/snowflake-to-mongodb/snip-7.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>
            Now we need to run the migration by clicking on Save & Run button
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-mongodb/snip-8.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/snowflake/snowflake-to-mongodb/activity.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default SnowflakeToMongoDbBlog
