export const StatusEnum = {
  PENDING: "Pending",
  RUNNING: "Running",
  ERROR: "Error",
  STOPPED: "Stopped",
  ALERT: "Alert",
  PAUSED: "Paused",
}

export const ActivitiesEnum = {
  PostgresToBigquery: "Postgres to Bigquery",
  PostgresToMongodb: "Postgres to MongoDB",
  PostgresToPostgres: "Postgres to Postgres",
  PostgresToSnowflake: "Postgres to Snowflake",
  PostgresToRedshift: "Postgres to Redshift",
  RedisToPostgres: "Redis to Postgres",
  RedisToRedis: "Redis to Redis",
  KafkaToPostgres: "Kafka to Postgres",
  KafkaToKafka: "Kafka to Kafka",
  MySQLToBigquery: "MySQL to Bigquery",
  MySQLToMySQL: "MySQL to MySQL",
  MySQLToMongodb: "MySQL to MongoDB",
  MySQLToSnowflake: "MySQL to Snowflake",
  MySQLToRedshift: "MySQL to Redshift",
  MongodbToSnowflake: "MongoDB to Snowflake",
  MongodbToRedshift: "MongoDB to Redshift",
  MongodbToBigquery: "MongoDB to Bigquery",
  RedshiftToPostgres: "Redshift to Postgres",
  RedshiftToMySQL: "Redshift to MySQL",
  RedshiftToMongodb: "Redshift to MongoDB",
  SnowflakeToPostgres: "Snowflake to Postgres",
  SnowflakeToMySQL: "Snowflake to MySQL",
  SnowflakeToMongodb: "Snowflake to MongoDB",
}

export const InputActivitiesEnum = {
  Postgres: "Postgres",
  MySQL: "MySQL",
  Snowflake: "Snowflake",
  Bigquery: "Bigquery",
  Redshift: "Redshift",
  MongoDB: "MongoDB",
  Redis: "Redis",
  Kafka: "Kafka",
}

export const ENUMS = {
  BRAND_NAME: "DataBashi",
}

export const DATA_FORMATS = {
  STRUCTURED: "structured",
  UNSTRUCTURED: "unstructured",
}
