/* eslint-disable react/prop-types */
import React from "react"

import { getInputClasses } from "utils/helpers"

const ControlledInput = ({
  name,
  type,
  label,
  icon,
  errors,
  touchedFields,
  register,
}) => {
  return (
    <>
      <div className={`form-floating ${errors[name] ? "mb-1" : "mb-3"}`}>
        <input
          type={type}
          className={`form-control ${getInputClasses(
            errors,
            touchedFields,
            name
          )}`}
          id={`floatingInput${name}`}
          placeholder="name@example.com"
          {...register(name)}
        />
        <label htmlFor="floatingInput">{label}</label>
        {icon && icon}
      </div>
      {errors[name] && <p className="error">{errors[name].message}</p>}
    </>
  )
}

export default ControlledInput
