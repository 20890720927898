/* eslint-disable react/prop-types */
import React from "react"

import "./StyledHeader.css"

const StyledHeader = ({ name }) => {
  return (
    <div className="one">
      <h1 id="styledName">{name}</h1>
    </div>
  )
}

export default StyledHeader
