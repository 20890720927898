import React from "react"
import PropTypes from "prop-types"

const Placeholder = ({ col = "col-12", height = "auto" }) => {
  return (
    <p className="placeholder-glow">
      <span className={`placeholder ${col}`} style={{ height }}></span>
    </p>
  )
}

Placeholder.propTypes = {
  col: PropTypes.string,
  height: PropTypes.string,
}

export default Placeholder
