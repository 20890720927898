import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const MySQLToMySQLBlog = () => {
  const data = tags.find(items => items.blogName === "mysql")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        In the realm of data management, the need to migrate or synchronize data
        between different MySQL databases is a common scenario. Leveraging the
        power of tools like MySQL, Benthos, and Temporal, you can create a
        dynamic and reliable data pipeline that ensures smooth data movement and
        synchronization while maintaining data integrity and consistency.
      </p>
      <h4>MySQL</h4>
      <p>
        MySQL is a popular open-source relational database management system. It
        is known for its stability, reliability, and performance in handling
        structured data. MySQL employs a table-based data storage model with
        ACID compliance, making it ideal for scenarios where data consistency
        and integrity are paramount.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source orchestration platform that enables you to
        build and manage complex workflows. It is designed to handle
        long-running and stateful processes, making it a powerful tool for
        orchestrating data migration and synchronization tasks.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos is a versatile open-source data processing tool that excels at
        connecting various data sources and sinks. It offers a range of
        processors and connectors for data transformation, enrichment, and
        routing, making it an excellent tool for creating data pipelines.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on the dropdown and select your desired conversion. In this
            case, we are converting from MySQL to MySQL.
          </p>
          <img
            src="/assets/images/mysql/mysql-to-mysql/snip-1.png"
            alt="Mysql to Mysql conversion select"
          />
        </li>
        <br />
        <li>
          <b>Validate the DB URL</b>
          <p>
            In this step, we will validate the URL our MySQL of database. Enter
            the URL of the database and click on validate button.
          </p>
          <img src="/assets/images/mysql/mysql-to-mysql/snip-2.png" />
        </li>
        <br />
        <li>
          <b>Select Columns and WHERE clause</b>
          <p>
            Select the Columns from the tables shown below and then choose the
            WHERE clause.
          </p>
          <img src="/assets/images/mysql/mysql-to-mysql/snip-3.png" />
        </li>
        <br />
        <li>
          <b>Validate Output DB URL</b>
          <p>
            In this step, we will validate the URL of the output MySQL database.
            Enter the URL of the database and click on validate button.
          </p>
          <img src="/assets/images/mysql/mysql-to-mysql/snip-4.png" />
        </li>
        <br />
        <li>
          <b>Select output Table</b>
          <p>
            Select from either already created tables or choose Dynamic tables.
            Let&lsquo;s assume we are creating a new table, so we need to write
            the new table name.
          </p>
          <img src="/assets/images/mysql/mysql-to-mysql/snip-5.png" />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>Now we need to run the migration by clicking on Save & Run</p>
          <img src="/assets/images/mysql/mysql-to-mysql/snip-6.png" />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/mysql/mysql-to-mysql/activity.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Activity Logs</b>
          <p>These are the activity logs.</p>
          <img
            src="/assets/images/mysql/mysql-to-mysql/logs.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default MySQLToMySQLBlog
