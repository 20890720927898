import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"

import Placeholder from "components/Placeholder"

const PaymentCardSkeleton = () => {
  return (
    <Card className="border rounded-full mt-3 bg-light shadow-sm placeholder-glow">
      <CardBody>
        <Row>
          <Col xs="10">
            <Row>
              <Col>
                <Placeholder />
              </Col>
            </Row>
            <Row>
              <Col>
                <Placeholder />
              </Col>
            </Row>
          </Col>
          <Col xs="2">
            <div style={{ height: "20px", width: "100%" }}>
              <Placeholder />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default PaymentCardSkeleton
