import React, { useEffect, useState } from "react"
import { Card, CardBody } from "reactstrap"
import PropTypes from "prop-types"

import { useAuthContext } from "context/authContextWrapper"

import { ConfirmDialogBox, Modal } from "components"
import { PlanCard } from "components/Cards"

import { getAllPlans } from "apis/plan"
import { cancelSubscriptonURL } from "apis/user"

import axiosInstance from "services/axiosInstance"

import PayamentForm from "./PaymentForm"
import UseLoadingHook from "hooks/useLoading"

const CreateSubscription = ({ closeSection }) => {
  const { user, storeUser } = useAuthContext()
  const { disableLoading } = UseLoadingHook(true)
  const [state, setState] = useState("month")
  const [plans, setPlans] = useState([])
  const [filteredPlans, setFilteredPlans] = useState([])
  const [showSubscriptionModal, setShowSubscriptionModal] = useState({})
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      getPriceList()
    }
    return () => {
      isMounted = false
    }
  }, [])

  const getPriceList = async () => {
    try {
      const {
        data: { plans },
      } = await axiosInstance.get(getAllPlans())
      setPlans(plans)
      const filteredPlans = plans.filter(({ interval }) => interval === state)
      setFilteredPlans(filteredPlans)
      disableLoading()
    } catch (err) {
      console.log("getPrice List api failed: ", err)
      disableLoading()
    }
  }

  const filterPlans = role => {
    return plans.filter(({ interval }) => interval === role)
  }

  const toggleSwitch = role => {
    const filteredPlan = filterPlans(role)
    setState(prevState => (prevState = role))
    setFilteredPlans(filteredPlan)
  }

  const onSubscribe = plan => {
    setShowSubscriptionModal({
      isOpen: true,
      title: "Subscription Details",
      type: "create",
      plan,
    })
  }

  const onUpdate = plan => {
    setShowSubscriptionModal({
      isOpen: true,
      title: "Change Subscription",
      type: "update",
      plan,
    })
  }

  const onCancel = () => {
    setShowSubscriptionModal({})
    closeSection && closeSection()
  }

  const onConfirmCancel = async () => {
    try {
      await axiosInstance.post(cancelSubscriptonURL(), {})
      storeUser()
      ToggleConfirmDialog()
    } catch (err) {
      console.log("failed to cancel subscription: ", err)
    }
  }

  const ToggleConfirmDialog = () => {
    setShowConfirmDialog(prevState => !prevState)
  }

  return (
    <Card>
      <CardBody>
        <h4 className="card-title">Select a Plan</h4>
        <div className="SubscriptionPlans-wrapper d-flex flex-column w-100">
          {/* <div className="d-flex flex-row gap-3 align-items-center justify-content-center mb-2">
            <button
              onClick={() => toggleSwitch("month")}
              className={`monthly-btn toggle-btn d-flex align-items-center justify-content-center w-md p-2 rounded ${
                state === "month" ? "selected-btn" : ""
              }`}
            >
              Monthly
            </button>
            <button
              onClick={() => toggleSwitch("year")}
              className={`yearly-btn toggle-btn d-flex align-items-center justify-content-center w-md p-2 rounded ${
                state === "year" ? "selected-btn" : ""
              }`}
            >
              Anually
            </button>
          </div> */}
          <div className="d-flex flex-column flex-md-row mt-2 gap-3 align-items-center justify-content-center">
            {filteredPlans.map(item => {
              return (
                <PlanCard
                  key={item._id}
                  selected={user?.plan?._id === item._id ? true : false}
                  btnText={
                    !user?.plan
                      ? "Select"
                      : user?.plan?._id === item._id
                      ? "Current"
                      : "Select"
                  }
                  {...item}
                  handleClick={() =>
                    !user?.plan ? onSubscribe(item) : onUpdate(item)
                  }
                  cancelSubscription={ToggleConfirmDialog}
                />
              )
            })}
          </div>
        </div>
        {showSubscriptionModal && (
          <Modal
            isOpen={showSubscriptionModal?.isOpen}
            modalTitle={showSubscriptionModal?.title}
            closeModal={onCancel}
            closeText="Close"
          >
            <PayamentForm
              plan={showSubscriptionModal?.plan}
              type={showSubscriptionModal?.type}
              onCancel={onCancel}
            />
          </Modal>
        )}
        {showConfirmDialog && (
          <ConfirmDialogBox
            handleSave={onConfirmCancel}
            onCancel={ToggleConfirmDialog}
            title="Are you sure?"
            text="Your subscription will be cancelled."
            confirmBtnText={"Cancel Subscription"}
          />
        )}
      </CardBody>
    </Card>
  )
}

CreateSubscription.propTypes = {
  closeSection: PropTypes.func,
}

export default CreateSubscription
