/* eslint-disable react/prop-types */
import React from "react"
import { Table } from "reactstrap"

const ActivityOutputTable = ({ data, outputName }) => {
  return (
    <Table bordered responsive>
      <tbody>
        {Object.entries(data).map(([key, value]) => {
          return Array.isArray(value) ? (
            ["sql_insert", "mongodb"].includes(outputName) ? (
              <tr key={key}>
                <td>{key}</td>
                <td>
                  <ul style={{ padding: 0, margin: 0, listStyle: "none" }}>
                    {value?.map(
                      ({ table, collection, inputTableId }, index) => (
                        <li key={inputTableId}>
                          {`${index + 1})`} {table || collection}
                        </li>
                      )
                    )}
                  </ul>
                </td>
              </tr>
            ) : (
              ""
            )
          ) : typeof value !== "object" ? (
            <tr>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ) : (
            ""
          )
        })}
      </tbody>
    </Table>
  )
}

export default ActivityOutputTable
