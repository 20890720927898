import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

import { Footer, Header, Sidebar } from "components"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      <Sidebar />
      <div id="layout-wrapper" className="position-relative">
        <div className="main-content">{children}</div>
      </div>
      <Footer />
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.object,
}

export default withRouter(Layout)
