import React, { useMemo, useState } from "react"

import { StatusEnum } from "utils/enums"

const ACTIVITY_STATES = [
  StatusEnum.PENDING,
  StatusEnum.RUNNING,
  StatusEnum.ERROR,
  StatusEnum.PAUSED,
]

const ITEMS_PER_PAGE = 5

const useUserActivityTable = ({
  userActivities,
  handleStatusUpdate,
  toggleProcedureModal,
  gotoActivityLogs,
}) => {
  const [itemOffset, setItemOffset] = useState(0)

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = useMemo(() => itemOffset + ITEMS_PER_PAGE, [itemOffset])
  const currentItems = useMemo(
    () => userActivities.slice(itemOffset, endOffset),
    [itemOffset, endOffset]
  )
  const pageCount = useMemo(
    () => Math.ceil(userActivities.length / ITEMS_PER_PAGE),
    [userActivities]
  )

  const onStatusUpdate = ({ activityId, status, index, loaderLabel }) => {
    handleStatusUpdate({
      activityId,
      status,
      index,
      loaderLabel,
    })
  }

  const gotoLogs = ({ activityId }) => {
    gotoActivityLogs(activityId)
  }

  const onToggleModal = activity => {
    toggleProcedureModal(activity)
  }

  const getActivityStatusBadge = status =>
    status === StatusEnum.PENDING
      ? "warning"
      : [StatusEnum.RUNNING, StatusEnum.PAUSED].includes(status)
      ? "success"
      : [StatusEnum.STOPPED, StatusEnum.ERROR].includes(status)
      ? "danger"
      : ""

  const handlePageClick = event => {
    const newOffset = (event.selected * ITEMS_PER_PAGE) % userActivities.length
    setItemOffset(newOffset)
  }
  return {
    STATES: ACTIVITY_STATES,
    onStatusUpdate,
    gotoLogs,
    getActivityStatusBadge,
    onToggleModal,
    handlePageClick,
    currentItems,
    pageCount,
  }
}

export default useUserActivityTable
