/* eslint-disable react/prop-types */
import React from "react"
import { Button } from "reactstrap"
import moment from "moment"

import { useAuthContext } from "context/authContextWrapper"

import UseLoadingHook from "hooks/useLoading"

import { AlertBar, Spinner } from "components"

import { cancelSubscriptonURL } from "apis/user"
import axiosInstance from "services/axiosInstance"

const CancelSubscriptionSection = ({
  isOpen,
  closeAlert,
  subscription: { current_period_end = "" } = {},
}) => {
  const { storeUser } = useAuthContext()
  const { isLoading, enableLoading, disableLoading } = UseLoadingHook()

  const handleCancelSubscription = async () => {
    enableLoading()
    try {
      await axiosInstance.post(cancelSubscriptonURL(), {})
      storeUser()
      closeAlert()
      disableLoading()
    } catch (err) {
      console.log("failed to cancel subscription: ", err)
      disableLoading()
    }
  }
  return (
    <AlertBar color="info" showAlert={isOpen} disableAlert={closeAlert}>
      <>
        <h4 className="card-title">
          <i className="mdi mdi-alert-circle-outline me-2"></i>Cancel
          Subscription
        </h4>
        <div className="d-flex flex-column">
          <p className="my-2">Note:</p>
          <ul>
            <li>
              Your current subscription will be cancelled after the end of
              current period{" "}
              {moment(current_period_end * 1000).format("MMM D, YYYY")}
            </li>
            <li>You will not be refunded on subscription cancellation.</li>
          </ul>
        </div>
        <div className="d-flex flex-row">
          <Button
            type="button"
            color="danger"
            size="sm"
            onClick={handleCancelSubscription}
            className="d-flex flex-row"
          >
            Unsubscribe Now {isLoading && <Spinner />}
          </Button>
        </div>
      </>
    </AlertBar>
  )
}

export default CancelSubscriptionSection
