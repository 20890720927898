import React, { useEffect, useState } from "react"
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"

import { useAuthContext } from "context/authContextWrapper"

import useAlert from "hooks/useAlert"
import useLoading from "hooks/useLoading"

import { getPaymentMethodsURL, updatePaymentMethodsURL } from "apis/stripe"
import axiosInstance from "services/axiosInstance"

const INITIAL_CARD_ELEMENT_STATUS = {
  cardNumber: false,
  cardCvc: false,
  cardExpiry: false,
}

const usePaymentMethod = () => {
  const stripe = useStripe()
  const elements = useElements()

  const { user } = useAuthContext()
  const { isLoading, enableLoading, disableLoading } = useLoading()
  const [fetchingCards, setIsFetchingCards] = useState(true)
  const { disableAlert, enableAlert, alert } = useAlert()

  const [name, setName] = useState("")
  const [paymentMethods, setPaymentMethods] = useState([])
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [complete, setComplete] = useState(INITIAL_CARD_ELEMENT_STATUS)

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      fetchPaymentMethods()
    }
    return () => {
      isMounted = false
    }
  }, [])

  const fetchPaymentMethods = async () => {
    try {
      const { data } = await axiosInstance.get(getPaymentMethodsURL())
      setPaymentMethods(data)
      setIsFetchingCards(false)
    } catch (err) {
      console.error(
        "failed to fetch payment methods associated to customer: ",
        err
      )
      setIsFetchingCards(false)
    }
  }

  const togglePaymentMethodModal = () => {
    setComplete(INITIAL_CARD_ELEMENT_STATUS)
    setShowUpdateModal(prevState => !prevState)
    setName("")
    disableAlert()
  }

  const handleChange = event => {
    setComplete(prevState => ({
      ...prevState,
      [event.elementType]: event.complete,
    }))
    if (event?.error?.message) {
      enableAlert({
        visible: true,
        color: "danger",
        message: event.error.message,
      })
    } else {
      disableAlert()
    }
  }

  const handleUpdatePaymentMethod = async event => {
    console.info("updated subscription form submitted: ")
    event.preventDefault()
    enableLoading()

    if (!stripe || !elements) {
      disableLoading()
      return
    }

    // create a payment method
    const paymentMethod = await stripe?.createPaymentMethod({
      type: "card",
      card: elements?.getElement(CardNumberElement),
      billing_details: {
        name,
        email: user?.additionalInfo?.email,
      },
    })

    try {
      await axiosInstance.post(updatePaymentMethodsURL(), {
        paymentMethod: paymentMethod?.paymentMethod.id,
      })
      disableLoading()
      fetchPaymentMethods()
      togglePaymentMethodModal()
    } catch (err) {
      console.log("Failed to update the payment Method: ", err)
      enableAlert({
        visible: true,
        color: "danger",
        message: err?.response?.data?.message,
      })
      disableLoading()
    }
  }

  return {
    handleChange,
    togglePaymentMethodModal,
    isLoading,
    name,
    setName,
    paymentMethods,
    showUpdateModal,
    complete,
    alert,
    fetchingCards,
    disableAlert,
    handleUpdatePaymentMethod,
  }
}

export default usePaymentMethod
