import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const PostgresToBigqueryBlog = () => {
  const data = tags.find(items => items.blogName === "postgresBigquery")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        PostgreSQL, a widely used open-source relational database, may no longer
        fully meet the scalability and analytical requirements of modern
        enterprises. Google BigQuery, a serverless, highly scalable data
        warehouse, offers a cloud-native solution designed for handling large
        datasets and enabling advanced analytics. Migrating data from PostgreSQL
        to BigQuery becomes a strategic move for organizations looking to
        leverage the benefits of cloud-based data warehousing. This blog post
        explores a robust approach to migrating data from PostgreSQL to Google
        BigQuery using the powerful combination of Benthos and Temporal.
      </p>
      <h4>Postgres</h4>
      <p>
        PostgreSQL, often referred to as Postgres, is known for its ACID
        compliance, extensibility, and support for advanced SQL features. It is
        suitable for a wide range of applications, from small-scale projects to
        large-scale enterprise systems.
      </p>
      <h4>Bigquery</h4>
      <p>
        Google BigQuery is a fully managed, serverless data warehouse provided
        by Google Cloud. It is designed for handling large-scale analytics and
        allows users to analyze and query massive datasets in real-time.
        BigQuery is known for its speed, scalability, and ease of use, making it
        a popular choice for organizations looking to perform advanced analytics
        on their data.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source framework for building, testing, and
        deploying distributed systems. It provides developers with an easy way
        to manage workflows, maintain states, and handle errors. Temporal is
        particularly useful for managing long-running processes and complex,
        multi-step workflows.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos, on the other hand, is a powerful data streaming tool that can
        be used to connect various data sources and sinks. It provides a lot of
        flexibility in terms of data processing, transformation, and routing.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Input Conversion</b>
          <p>
            Click on the <b>Choose Input Conversion</b> dropdown and select{" "}
            <b>Postgres</b>.
          </p>
          <img src="/assets/images/postgres/postgres-to-bigquery/snip-1.png" />
        </li>
        <br />
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on the <b>Choose Conversion</b> dropdown and select{" "}
            <b>Postgres To Bigquery</b>.
          </p>
          <img src="/assets/images/postgres/postgres-to-bigquery/snip-2.png" />
        </li>
        <br />
        <li>
          <b>Validate the DB URL</b>
          <p>
            Enter the URL of the Postgres database and click on validate button.
          </p>
          <img src="/assets/images/postgres/postgres-to-bigquery/snip-3.png" />
        </li>
        <br />
        <li>
          <b>Select Columns and WHERE clause</b>
          <p>
            Select the Columns from the tables shown below and then choose the
            WHERE clause.
          </p>
          <img src="/assets/images/postgres/postgres-to-bigquery/snip-4.png" />
        </li>
        <br />
        <li>
          <b>Enter the Dataset of your Bigquery Database</b>
          <p>Specify the name of your dataset in the BigQuery database.</p>
          <img src="/assets/images/postgres/postgres-to-bigquery/snip-5.png" />
        </li>
        <br />
        <li>
          <b>Select the Service Account File</b>
          <p>
            Select the JSON file of your service account you have downloaded
            from bigquery and click on verify button.
          </p>
          <img src="/assets/images/postgres/postgres-to-bigquery/snip-6.png" />
        </li>
        <br />
        <li>
          <b>Select output table</b>
          <p>
            Select the output table from the list provided below. If you do not
            see the tables, kindly ensure that you have entered the correct
            dataset or verify that your database indeed contains tables.
          </p>
          <img src="/assets/images/postgres/postgres-to-bigquery/snip-7.png" />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>Now we need to run the migration by clicking on Save & Run</p>
          <img src="/assets/images/postgres/postgres-to-bigquery/snip-8.png" />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/postgres/postgres-to-bigquery/activity.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default PostgresToBigqueryBlog
