import React from "react"
import { Link } from "react-router-dom"

import logo from "assets/images/logo-light.svg"

import SidebarContent from "./SidebarContent"
import { useAuthContext } from "context/authContextWrapper"

const Sidebar = () => {
  const { isAddon } = useAuthContext()

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to={isAddon ? "/" : "/dashboard"} className="logo logo-light">
            <span className="logo-sm">
              <img src={logo} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" height="40" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

export default Sidebar
