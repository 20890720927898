import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const KafkaToPostgresBlog = () => {
  const data = tags.find(items => items.blogName === "kafkaPostgres")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        In the era of data-driven decision-making, the ability to efficiently
        and reliably move data from one system to another is paramount. Apache
        Kafka, a distributed streaming platform, and PostgreSQL, a powerful
        open-source relational database, are two technologies that play a
        pivotal role in modern data pipelines. In this blog, we will delve into
        the process of transferring data from Kafka to PostgreSQL and explore
        the benefits and considerations involved.
      </p>
      <h4>Apache Kafka</h4>
      <p>
        Apache Kafka is a distributed streaming platform designed to handle
        high-throughput, fault-tolerant, and real-time data streams. It enables
        producers to publish data to topics, and consumers to subscribe to these
        topics, facilitating real-time data processing.
      </p>
      <h4>Postgres</h4>
      <p>
        PostgreSQL is an open-source relational database known for its advanced
        features, extensibility, and ACID compliance. It&lsquo;s well-suited for
        storing structured data and is widely used in applications ranging from
        small projects to large-scale enterprise systems.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source framework for building, testing, and
        deploying distributed systems. It provides developers with an easy way
        to manage workflows, maintain states, and handle errors. Temporal is
        particularly useful for managing long-running processes and complex,
        multi-step workflows.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos, on the other hand, is a powerful data streaming tool that can
        be used to connect various data sources and sinks. It provides a lot of
        flexibility in terms of data processing, transformation, and routing.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on the dropdown and select your desired conversion. In this
            case, we are converting from Kafka to Postgres.
          </p>
          <img
            src="/assets/images/kafka/kafka-to-postgres/snip-1.png"
            alt="kafka to postgres conversion select"
          />
        </li>
        <br />
        <li>
          <b>Validate the Server URL</b>
          <p>
            In this step, we will validate the URL of server. Enter the URL of
            the server and click on validate button.
          </p>
          <img src="/assets/images/kafka/kafka-to-postgres/snip-2.png" />
        </li>
        <br />
        <li>
          <b>Enter Topic-Name and Consumer Group name</b>
          <p>Enter the your kafka topic name and consumer group.</p>
          <img src="/assets/images/kafka/kafka-to-postgres/snip-3.png" />
        </li>
        <br />
        <li>
          <b>Validate Output DB URL</b>
          <p>
            In this step, we will validate the URL of the output Postgres
            database. Enter the URL of the database and click on validate
            button.
          </p>
          <img src="/assets/images/kafka/kafka-to-postgres/snip-4.png" />
        </li>
        <br />
        <li>
          <b>Select output Table </b>
          <p>
            Now, select the output table by clicking on the dropdown menu and
            select your desired table.
          </p>
          <img src="/assets/images/kafka/kafka-to-postgres/snip-5.png" />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>Now we need to run the migration by clicking on Save & Run</p>
          <img src="/assets/images/kafka/kafka-to-postgres/snip-6.png" />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/kafka/kafka-to-postgres/activity.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default KafkaToPostgresBlog
