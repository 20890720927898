/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import ReactSelect from "react-select"
import { Col, FormFeedback, Row } from "reactstrap"

import { Spinner } from "components"
import ReactstrapInput from "components/Inputs/ReactstrapInput"

import {
  collectionDropdownOptions,
  databaseDropdownOptions,
} from "utils/helpers"
import useMongoOutputForm from "./useMongoOutputForm"

const MongoOutputsForm = ({
  configurationData,
  userOutputs,
  userInputs,
  setUserOutputs,
  sequelTreeData,
}) => {
  const {
    collections,
    databases,
    error,
    isDisable,
    isLoading,
    status,
    isDynamicOutput,
    collectionNames,
    dynamicCollections,
    _SetError,
    setStatus,
    getCollections,
    handleCollections,
    handleDynamicCollections,
    handleDynamicOutputs,
    validateDbConnectionAndGetDatabases,
    setIsDisable,
    setCollectionNames,
    setCollections,
  } = useMongoOutputForm({ userOutputs, setUserOutputs })

  const [selectedOption, setSelectedOption] = useState("")

  useEffect(() => {
    setIsDisable(true)
    setCollections(
      setUserOutputs({
        ...userOutputs,
        collections: [],
      })
    )
    setCollectionNames([])
    setSelectedOption("")
  }, [configurationData?.activity?.name])

  return (
    <>
      {configurationData?.activity?.requiredOutputs.map(
        (requiredOutput, index) => {
          return (
            <div className="mb-3" key={index}>
              {requiredOutput.Key === "url" ? (
                <div className="d-flex flex-row w-100">
                  <div className="w-100 me-2">
                    <ReactstrapInput
                      className={`form-control ${
                        status === null
                          ? ""
                          : status === true
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      name={requiredOutput.Key}
                      value={userOutputs[requiredOutput.Key] || ""}
                      placeholder={
                        requiredOutput.isRequired === true
                          ? requiredOutput.Label + "*"
                          : requiredOutput.Label
                      }
                      onChange={e => {
                        if (!e.target.value) setStatus(false)
                        if (!e.target.value) _SetError()
                        setUserOutputs({
                          ...userOutputs,
                          [requiredOutput.Key]: e.target.value.trim(),
                        })
                      }}
                    />
                    {error && <FormFeedback invalid>{error}</FormFeedback>}
                  </div>
                  <div className="">
                    <button
                      disabled={!userOutputs?.url || isLoading ? true : false}
                      className="d-flex btn btn-primary align-items-center"
                      onClick={() =>
                        validateDbConnectionAndGetDatabases(userOutputs.url)
                      }
                    >
                      <span>Validate</span> {isLoading && <Spinner />}
                    </button>
                  </div>
                </div>
              ) : requiredOutput.Key === "database" ? (
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder={
                    requiredOutput.isRequired
                      ? `${requiredOutput.Label}*`
                      : requiredOutput.Label
                  }
                  isDisabled={isDisable}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  menuPlacement="auto"
                  value={selectedOption ? selectedOption : null}
                  name={requiredOutput.Key}
                  options={databaseDropdownOptions(databases)}
                  onChange={selected => {
                    getCollections({
                      key: requiredOutput.Key,
                      value: !selected ? "" : selected.value.trim(),
                    })
                    setSelectedOption(selected)
                  }}
                />
              ) : requiredOutput.Key === "collection" ? (
                <div className="d-flex flex-column w-100">
                  <p className="p-0 mb-1">
                    Select from already created collections
                  </p>
                  <ReactSelect
                    className="multi"
                    classNamePrefix="select"
                    placeholder={
                      requiredOutput.isRequired
                        ? `${requiredOutput.Label}*`
                        : requiredOutput.Label
                    }
                    isDisabled={isDisable}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    menuPlacement="auto"
                    isMulti
                    value={collectionNames ? collectionNames : null}
                    closeMenuOnSelect={false}
                    name={requiredOutput.Key}
                    options={collectionDropdownOptions(collections)}
                    onChange={selected =>
                      handleCollections({
                        key: requiredOutput.Key,
                        value: selected,
                        type: "select",
                      })
                    }
                  />
                  <p className="text-center w-100 my-1">OR</p>
                  <div className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={isDynamicOutput}
                      disabled={isDisable}
                      id="defaultCheck2"
                      onChange={event =>
                        handleDynamicOutputs(event.target.checked)
                      }
                    />
                    <label className="form-check-label" htmlFor="defaultCheck2">
                      Dynamic Collections
                    </label>
                  </div>
                  {isDynamicOutput &&
                    userInputs?.columns?.map(column =>
                      sequelTreeData?.map(
                        ({ name }, index) =>
                          name === column.table && (
                            <Row
                              key={column.table + index}
                              className="d-flex mb-2 align-items-center"
                            >
                              <Col md={4} className="text-start">
                                <p>{column.table}</p>
                              </Col>
                              <Col md={2}>
                                <p>to</p>
                              </Col>
                              <Col md={6}>
                                <ReactstrapInput
                                  className={`form-control`}
                                  placeholder="Create new table"
                                  value={dynamicCollections[index]?.collection}
                                  onChange={e => {
                                    handleDynamicCollections({
                                      value: e.target.value.trim(),
                                      inputTable: column.table,
                                      inputTableId: column.id,
                                      index,
                                    })
                                  }}
                                />
                              </Col>
                            </Row>
                          )
                      )
                    )}
                </div>
              ) : (
                ""
              )}
            </div>
          )
        }
      )}
    </>
  )
}

export default MongoOutputsForm
