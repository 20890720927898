/* eslint-disable react/prop-types */
import React from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import PropTypes from "prop-types"

const ConfirmDialogBox = ({
  confirmBtnText,
  title,
  handleSave,
  onCancel,
  text,
}) => {
  return (
    <SweetAlert
      warning
      showCancel
      confirmBtnText={confirmBtnText}
      confirmBtnBsStyle="primary"
      title={title}
      onConfirm={handleSave}
      onCancel={onCancel}
      reverseButtons
      cancelBtnCssClass="text-danger"
      style={{ width: "33rem" }}
    >
      {text}
    </SweetAlert>
  )
}

ConfirmDialogBox.propTypes = {
  confirmBtnText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}

export default ConfirmDialogBox
