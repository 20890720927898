import React from "react"
import { Helmet } from "react-helmet-async"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { Link } from "react-router-dom"

import LOGO from "assets/images/databashi-logo.png"
import profile from "assets/images/profile-img.png"

import { Loader } from "components"

import { Error404 } from "pages/ErrorScreens"

import ResetPasswordForm from "./ResetPasswordForm"
import UseResetPassword from "./useResetPassword"
import { ENUMS } from "utils/enums"

const ResetPassword = () => {
  const {
    errors,
    handleSubmit,
    isLoading,
    loading,
    onSubmit,
    register,
    touchedFields,
    email,
  } = UseResetPassword()
  return loading ? (
    <Loader />
  ) : !email ? (
    <Error404 />
  ) : (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Helmet>
          <title>Reset Password | {ENUMS.BRAND_NAME}</title>
        </Helmet>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden w-100">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary"> Reset Password</h5>
                        <p>Reset with {ENUMS.BRAND_NAME}.</p>
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="dashboard">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={LOGO}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <ResetPasswordForm
                      touchedFields={touchedFields}
                      isLoading={isLoading}
                      errors={errors}
                      onSubmit={onSubmit}
                      register={register}
                      handleSubmit={handleSubmit}
                    />
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link to="/auth/login" className="fw-medium text-primary">
                    {" "}
                    Sign In here
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword
