import React, { useState } from "react"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import PropTypes from "prop-types"

import PlanPreview from "./PlanPreview"
import UpcomingInvoice from "./UpcomingInvoice"
import CreateSubscription from "../CreateSubscription"

import "./planDetailsSection.css"

const PlanDetailsSection = ({
  toggleUnsubscribeCard,
  showCancelSubscriptionCard,
  details = {},
  isLoading,
}) => {
  const [showPlans, setShowPlans] = useState(false)

  const togglePlansMenu = () => {
    setShowPlans(prevState => !prevState)
  }

  return showPlans ? (
    <Card>
      <div className="p-3 border-bottom">
        <Button
          onClick={togglePlansMenu}
          color="dark"
          size="sm"
          className="d-flex align-items-center gap-2"
        >
          <i className="bx bx-arrow-back"></i> Back To Details
        </Button>
      </div>
      <CreateSubscription closeSection={togglePlansMenu} />
    </Card>
  ) : (
    <Card>
      <CardBody>
        <Row className="g-0">
          <Col md="4" className="plan-preview-section">
            <PlanPreview
              togglePlanMenu={togglePlansMenu}
              toggleUnsubscribeCard={toggleUnsubscribeCard}
              showCancelSubscriptionCard={showCancelSubscriptionCard}
              {...details}
            />
          </Col>
          <Col md="8">
            <UpcomingInvoice {...details} isLoading={isLoading} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

PlanDetailsSection.propTypes = {
  toggleUnsubscribeCard: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  showCancelSubscriptionCard: PropTypes.bool,
}

export default PlanDetailsSection
