import { useEffect, useState } from "react"

import useLoading from "hooks/useLoading"
import axiosInstance from "services/axiosInstance"
import { apiRequestLogs } from "apis/user"

const UseApiLogs = () => {
  const { isLoading, disableLoading, enableLoading } = useLoading(true)

  const [requestLogs, setRequestLogs] = useState([])
  const [search, setSearch] = useState("")
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
  })

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      fetchApiRequestLogs(pagination.currentPage, search)
    }
    return () => {
      isMounted = false
    }
  }, [])

  const fetchApiRequestLogs = async (currentPage, search) => {
    enableLoading()
    try {
      const {
        data: {
          response: { apiRequests, totalPages, currentPage: current_page },
        },
      } = await axiosInstance.get(apiRequestLogs(currentPage, search))
      setRequestLogs(apiRequests)
      setPagination({
        totalPages,
        currentPage: current_page,
      })
      disableLoading()
    } catch (err) {
      console.log("Failed to fetch API Request Logs: ", err)
      disableLoading()
    }
  }

  const handlePagination = page => {
    if (pagination.currentPage === page) return
    setPagination(prevPagination => ({ ...prevPagination, currentPage: page }))
    fetchApiRequestLogs(page, search)
  }

  const onSubmitSearch = event => {
    event.preventDefault()
    setPagination(prevPagination => ({ ...prevPagination, currentPage: 1 }))
    fetchApiRequestLogs(1, search)
  }

  return {
    requestLogs,
    pagination,
    isLoading,
    handlePagination,
    onSubmitSearch,
    setSearch,
  }
}

export default UseApiLogs
