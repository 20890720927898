/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Button, FormFeedback, Input } from "reactstrap"
import { compact } from "lodash"

import useRedis from "hooks/useRedis"

import { Spinner } from "components"
import TextField from "components/Inputs/textField"
import ReactstrapInput from "components/Inputs/ReactstrapInput"
import { ActivitiesEnum, DATA_FORMATS } from "utils/enums"
import StructureFormatToolTip from "../utils/structure-format-tooltip"

const RedisInputForm = ({
  configuration,
  configurationData,
  userInputs,
  setUserInputs,
}) => {
  const {
    isDisable,
    isLoading,
    status,
    error,
    channels,
    setStatus,
    _SetError,
    validateConnection,
    setIsDisable,
    setChannels,
  } = useRedis()

  const [selectedFormat, setSelectedFormat] = useState(DATA_FORMATS.STRUCTURED)

  useEffect(() => {
    setIsDisable(true)
    setChannels([])
  }, [configurationData?.activity?.name])

  const handleChangeChannels = ({ key, event }) => {
    let channels = compact(
      Array.from(event.target.selectedOptions, option => option.value)
    )
    setUserInputs({
      ...userInputs,
      [key]: channels.join(","),
    })
  }

  const handleChangeFormat = value => {
    setSelectedFormat(value)
    setUserInputs({
      ...userInputs,
      dataStructure: value,
    })
  }

  return (
    <>
      {configurationData?.activity?.requiredInputs.map(
        (requiredInput, index) => {
          return (
            <div className="mb-3" key={index}>
              {requiredInput.Key === "url" ? (
                <div className="d-flex flex-row w-100">
                  <div className="w-100 me-2">
                    <ReactstrapInput
                      className={`form-control ${
                        status === null
                          ? ""
                          : status === true
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      type="text"
                      value={userInputs[requiredInput.Key] || ""}
                      name={requiredInput.Key}
                      placeholder={
                        requiredInput.isRequired === true
                          ? requiredInput.Label + "*"
                          : requiredInput.Label
                      }
                      required={
                        requiredInput.isRequired
                          ? requiredInput.isRequired
                          : true
                      }
                      onChange={e => {
                        if (!e.target.value) setStatus(false)
                        if (!e.target.value) _SetError()
                        setUserInputs({
                          ...userInputs,
                          [requiredInput.Key]: e.target.value.trim(),
                          dataStructure: selectedFormat,
                        })
                      }}
                    />
                    {error && <FormFeedback invalid>{error}</FormFeedback>}
                  </div>
                  <div>
                    <button
                      disabled={!userInputs?.url || isLoading ? true : false}
                      className="d-flex btn btn-primary align-items-center"
                      onClick={() => validateConnection(userInputs.url)}
                    >
                      <span>Validate</span> {isLoading && <Spinner />}
                    </button>
                  </div>
                </div>
              ) : requiredInput.Key === "channels" ? (
                <Input
                  className="form-control"
                  type="select"
                  name={requiredInput.Key}
                  placeholder={
                    requiredInput.isRequired === true
                      ? requiredInput.Label + "*"
                      : requiredInput.Label
                  }
                  multiple
                  required={
                    requiredInput.isRequired ? requiredInput.isRequired : true
                  }
                  disabled={isDisable}
                  onChange={e => {
                    handleChangeChannels({
                      key: requiredInput.Key,
                      event: e,
                    })
                  }}
                >
                  <option value="" disabled selected>
                    {requiredInput.isRequired === true
                      ? requiredInput.Label + "*"
                      : requiredInput.Label}
                  </option>
                  {channels.map(channel => (
                    <option key={channel} value={channel}>
                      {channel}
                    </option>
                  ))}
                </Input>
              ) : (
                <TextField
                  className="form-control"
                  type="text"
                  name={requiredInput.Key}
                  placeholder={
                    requiredInput.isRequired === true
                      ? requiredInput.Label + "*"
                      : requiredInput.Label
                  }
                  required={
                    requiredInput.isRequired ? requiredInput.isRequired : true
                  }
                  disabled={isDisable}
                  onChange={e => {
                    setUserInputs({
                      ...userInputs,
                      [requiredInput.Key]: e.target.value.trim(),
                    })
                  }}
                />
              )}
            </div>
          )
        }
      )}
      {configuration === ActivitiesEnum.RedisToPostgres && (
        <>
          <span className="d-flex align-items-center p-0 mb-2">
            Select Data Dump Format &nbsp;
            <i id="redis-structure-label" className="bx bx-info-circle"></i>
          </span>
          <StructureFormatToolTip targetId="redis-structure-label" />

          <div className="d-flex justify-content-evenly mb-3 gap-3">
            <div className="w-100">
              <Button
                className="button-hover w-100"
                outline={!(selectedFormat === DATA_FORMATS.STRUCTURED)}
                color="primary"
                value={DATA_FORMATS.STRUCTURED}
                onClick={() => handleChangeFormat(DATA_FORMATS.STRUCTURED)}
              >
                Structured
              </Button>
            </div>
            <div className="w-100">
              <Button
                className="button-hover w-100"
                outline={!(selectedFormat === DATA_FORMATS.UNSTRUCTURED)}
                color="primary"
                value={DATA_FORMATS.UNSTRUCTURED}
                onClick={() => handleChangeFormat(DATA_FORMATS.UNSTRUCTURED)}
              >
                Unstructured
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default RedisInputForm
