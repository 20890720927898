import React from "react"
import { Table } from "reactstrap"

import { Placeholder } from "components"

const ApiLogsSkeleton = () => {
  return (
    <Table responsive className="table mb-0">
      <thead>
        <tr>
          <th>DATE</th>
          <th>METHOD</th>
          <th>URL</th>
          <th>STATUS CODE</th>
        </tr>
      </thead>
      <tbody>
        {Array(3)
          .fill("*")
          .map((item, index) => (
            <tr key={`apiLog-${index}`}>
              <td>
                <Placeholder />
              </td>
              <td>
                <Placeholder />
              </td>
              <td>
                <Placeholder />
              </td>
              <td>
                <Placeholder />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}

export default ApiLogsSkeleton
