import React, { useState } from "react"

const UsePasswordVisiblity = () => {
  const [isVisible, setIsVisible] = useState(false)

  const TogglePasswordVisiblity = () => {
    setIsVisible(prevState => !prevState)
  }

  return { TogglePasswordVisiblity, isVisible }
}

export default UsePasswordVisiblity
