import React from "react"
import { Card, CardBody } from "reactstrap"
import PropTypes from "prop-types"

const StatsWidget = ({ title = "", description = "", iconClass = "" }) => {
  return (
    <Card className="mini-stats-wid">
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1">
            <p className="text-muted fw-medium">{title}</p>
            <h4 className="mb-0">{description}</h4>
          </div>
          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
            <span className="avatar-title rounded-circle bg-primary">
              <i className={`bx ${iconClass} font-size-24`}></i>
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

StatsWidget.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
}

export default StatsWidget
