import { useState } from "react"
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"

import { useAuthContext } from "context/authContextWrapper"

import useAlert from "hooks/useAlert"
import useLoading from "hooks/useLoading"

import { createSubscriptionURL, updateSubscriptionURL } from "apis/stripe"
import { updateSubscriptonIdURL } from "apis/user"

import axiosInstance from "services/axiosInstance"

const INITIAL_CARD_ELEMENT_STATUS = {
  cardNumber: false,
  cardCvc: false,
  cardExpiry: false,
}

const UsePaymentForm = ({ onCancel }) => {
  const stripe = useStripe()
  const elements = useElements()

  const { user, storeUser } = useAuthContext()
  const { disableLoading, enableLoading, isLoading } = useLoading()
  const { disableAlert, enableAlert, alert } = useAlert()

  const [name, setName] = useState("")
  const [showPaymentSection, setShowPaymentSection] = useState(false)
  const [complete, setComplete] = useState({ ...INITIAL_CARD_ELEMENT_STATUS })

  const handleChange = event => {
    setComplete(prevState => ({
      ...prevState,
      [event.elementType]: event.complete,
    }))
    if (event?.error?.message) {
      enableAlert({
        visible: true,
        color: "danger",
        message: event.error.message,
      })
    } else {
      disableAlert()
    }
  }

  const createSubscription = async (event, plan) => {
    event.preventDefault()
    try {
      enableLoading()

      if (!stripe || !elements) {
        disableLoading()
        return
      }

      // create a payment method
      const { error, paymentMethod } = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardNumberElement),
        billing_details: {
          name,
          email: user?.additionalInfo?.email,
        },
      })

      if (!paymentMethod) {
        console.log("ERROR:", error?.message)
        setAlertColor("danger")
        onSetAlertMessage(error?.message)
        return enableAlert({
          visible: true,
          color: "danger",
          message: error?.message,
        })
      }

      // create subscription
      const {
        data: { subscriptionId },
      } = await axiosInstance.post(createSubscriptionURL(), {
        name,
        email: user?.additionalInfo?.email,
        priceId: plan?.price_id,
        paymentMethod: paymentMethod?.id,
      })

      // update subscriptionId in database
      await axiosInstance.put(
        updateSubscriptonIdURL(user?.additionalInfo?._id),
        {
          subscriptionId,
          planId: plan?._id,
        }
      )
      storeUser()
      disableLoading()
      onCancel()
    } catch (error) {
      console.log("create subscription API failed: ", error)
      enableAlert({
        visible: true,
        color: "danger",
        message: error?.response?.data?.message,
      })
      disableLoading()
    }
  }

  const handleUpdateSubscription = async (event, plan) => {
    console.log("updated subscription form submitted: ")
    event.preventDefault()
    enableLoading()
    let paymentMethod = null

    if (!stripe || !elements) {
      disableLoading()
      return
    }
    if (showPaymentSection) {
      console.log("should change to new paymentMethod")
      // create a payment method
      paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardNumberElement),
        billing_details: {
          name,
          email: user?.additionalInfo?.email,
        },
      })
    }

    try {
      await axiosInstance.post(updateSubscriptionURL(), {
        newPriceId: plan?.price_id,
        newPlanId: plan?._id,
        paymentMethod: paymentMethod?.paymentMethod?.id || "",
      })
      storeUser()
      onCancel()
    } catch (error) {
      console.log("failed to update subscription: ", error)
      console.log("ERROR:", error?.message)
      enableAlert({
        visible: true,
        color: "danger",
        message: error?.response?.data?.message,
      })
      disableLoading()
    }
  }

  const togglePaymentSection = () => {
    setComplete({ ...INITIAL_CARD_ELEMENT_STATUS })
    setShowPaymentSection(prevState => !prevState)
  }

  return {
    togglePaymentSection,
    handleUpdateSubscription,
    createSubscription,
    handleChange,
    setName,
    disableAlert,
    name,
    showPaymentSection,
    complete,
    user,
    alert,
    isLoading,
  }
}
export default UsePaymentForm
