import React, { useState } from "react"
import useStatus from "./useStatus"

import {
  retrieveSequelColumnsURL,
  retrieveSequelTablesURL,
  validateSequelDBConnectionURL,
  retrieveSnowflakeColumnsURL,
  validateSnowflakeConnectionURL,
  validateRedshiftConnectionURL,
  retrieveRedshiftColumnsURL,
  retrieveBigqueryColumnsURL,
} from "apis/dbConnections.apis"

import axiosInstance from "services/axiosInstance"
import { InputActivitiesEnum } from "utils/enums"

const useSequel = ({ type, conversionName }) => {
  const { error, _SetError } = useStatus()

  const [isDisable, setIsDisable] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isFetchingColumns, setIsFetchingColumns] = useState(false)

  const [tables, setTables] = useState([])
  const [columns, setColumns] = useState([])
  const [status, setStatus] = useState(null)

  const validateDbConnection = async connectionURL => {
    if (!connectionURL) return
    setColumns([])
    try {
      setIsLoading(true)
      await axiosInstance.post(validateSequelDBConnectionURL(), {
        connectionURL,
        dialect:
          conversionName.split(" ")[0] === InputActivitiesEnum.Postgres
            ? "postgres"
            : "mysql",
      })
      _SetError(null)
      setStatus(true)
      setIsDisable(false)
      setIsLoading(false)
    } catch (err) {
      console.log(`${type} db failed to establish connection: `, err)
      _SetError(err.response.data.message)
      setStatus(false)
      setIsDisable(true)
      setIsLoading(false)
    }
  }

  const getTables = async connectionURL => {
    try {
      setIsLoading(true)
      let data = null
      const conversionOutputName = conversionName.split(" ")[2]
      switch (conversionOutputName) {
        case InputActivitiesEnum.Snowflake:
          data = await axiosInstance.post(validateSnowflakeConnectionURL(), {
            connectionURL,
          })
          break
        case InputActivitiesEnum.Redshift:
          data = await axiosInstance.post(validateRedshiftConnectionURL(), {
            connectionURL,
          })
          break
        case InputActivitiesEnum.MySQL:
        case InputActivitiesEnum.Postgres:
          data = await axiosInstance.post(retrieveSequelTablesURL(), {
            connectionURL,
            dialect: conversionName.includes(InputActivitiesEnum.Postgres)
              ? "postgres"
              : "mysql",
          })
        default:
          break
      }
      setTables(data.data.tables)
      setIsDisable(false)
      setIsLoading(false)
    } catch (err) {
      console.log(`Failed to fetch ${type} tables`)
    }
  }

  const getColumns = async connectionURL => {
    setIsFetchingColumns(true)
    setIsLoading(true)
    try {
      let data = null
      const conversionInputName = conversionName.split(" ")[0]
      switch (conversionInputName) {
        case InputActivitiesEnum.Snowflake:
          data = await axiosInstance.post(retrieveSnowflakeColumnsURL(), {
            connectionURL,
          })
          break
        case InputActivitiesEnum.Redshift:
          data = await axiosInstance.post(retrieveRedshiftColumnsURL(), {
            connectionURL,
            dialect: "postgres",
          })
          break
        case InputActivitiesEnum.Bigquery:
          data = await axiosInstance.post(retrieveBigqueryColumnsURL(), {
            connectionURL,
          })
          break
        case InputActivitiesEnum.Postgres:
        case InputActivitiesEnum.MySQL:
          data = await axiosInstance.post(retrieveSequelColumnsURL(), {
            connectionURL,
            dialect: conversionName.includes(InputActivitiesEnum.Postgres)
              ? "postgres"
              : "mysql",
          })
        default:
          break
      }
      setColumns(data.data.columns)
      setIsLoading(false)
      setIsFetchingColumns(false)
    } catch (err) {
      console.log(`Failed to fetch ${type} columns`)
      setIsFetchingColumns(false)
    }
  }

  const resetColumns = () => {
    setColumns([])
  }

  return {
    status,
    isDisable,
    columns,
    isLoading,
    error,
    tables,
    setStatus,
    _SetError,
    getTables,
    getColumns,
    resetColumns,
    isFetchingColumns,
    validateDbConnection,
    setTables,
    setIsDisable,
  }
}

export default useSequel
