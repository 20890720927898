import { useState } from "react"

const UseLoadingHook = defaultVal => {
  const [isLoading, setIsLoading] = useState(defaultVal || false)

  const enableLoading = () => {
    setIsLoading(true)
  }

  const disableLoading = () => {
    setIsLoading(false)
  }

  return { isLoading, enableLoading, disableLoading }
}

export default UseLoadingHook
