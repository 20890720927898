import React from "react"
import { Helmet } from "react-helmet-async"
import { Container } from "reactstrap"

import DeveloperKeySection from "./DeveloperKeySection"

import { ENUMS } from "utils/enums"
import AccessApiLogs from "./ApiLogsSection"

const Settings = () => {
  return (
    <div className="page-content">
      <Helmet>
        <title>Developer Settings | {ENUMS.BRAND_NAME}</title>
      </Helmet>
      <>
        <Container>
          <DeveloperKeySection />
          <AccessApiLogs />
        </Container>
      </>
    </div>
  )
}

export default Settings
