import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import PropTypes from "prop-types"

import logo from "assets/images/databashi-logo.png"
import profileImg from "assets/images/profile-img.png"

import { useAuthContext } from "context/authContextWrapper"

const WelcomeComp = ({ text }) => {
  const { user } = useAuthContext()

  return (
    <Card className="overflow-hidden">
      <div className="bg-primary bg-soft">
        <Row>
          <Col xs="7" sm="10">
            <div className="text-primary p-3">
              <h5 className="text-primary">{text}!</h5>
              <p>
                {user?.additionalInfo?.firstName}{" "}
                {user?.additionalInfo?.lastName}
              </p>
            </div>
          </Col>
          <Col xs="5" sm="2" className="align-self-end">
            <img src={profileImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <Row>
          <Col sm="4">
            <div className="avatar-md profile-user-wid mb-4">
              <img
                src={logo}
                alt=""
                className="img-thumbnail rounded-circle avatar-md"
              />
            </div>
            <h5 className="font-size-15 text-truncate">
              {user?.additionalInfo?.username}
            </h5>
            <p className="text-muted mb-0 text-truncate">{user?.email}</p>
          </Col>

          <Col sm="8">
            <div className="pt-4">
              <Row>
                <Col xs="6">
                  <h5 className="font-size-15">
                    {user?.totalProcessedRecords || 0}
                  </h5>
                  <p className="text-muted mb-0">Current Processed Count</p>
                </Col>
                <Col xs="6">
                  <h5 className="font-size-15">
                    {user?.plan?.maxActivities || 0}
                  </h5>
                  <p className="text-muted mb-0">Max Allowed Process Count</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

WelcomeComp.propTypes = {
  text: PropTypes.string.isRequired,
}
export default WelcomeComp
