import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const MySQLToMongoDbBlog = () => {
  const data = tags.find(items => items.blogName === "mysqlMongodb")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        In the ever-evolving landscape of data management, the need to
        seamlessly transfer data between different databases is a common
        challenge. MySQL, a popular open-source relational database, and
        MongoDB, a NoSQL document-oriented database, offer unique strengths. By
        leveraging tools like Benthos and Temporal, you can create a robust data
        pipeline that connects MySQL and MongoDB, ensuring efficient data
        transfer and transformation.
      </p>
      <h4>MySQL</h4>
      <p>
        MySQL is a widely used open-source relational database management
        system. It excels at handling structured data through tables with
        predefined schemas, making it suitable for applications where data
        integrity and consistency are critical.
      </p>
      <h4>MongoDB</h4>
      <p>
        MongoDB, on the other hand, is a NoSQL database that stores data in
        flexible, JSON-like documents. It&lsquo;s designed for applications that
        require flexibility and scalability, making it ideal for projects with
        evolving data structures.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source framework for building, testing, and
        deploying distributed systems. It provides developers with an easy way
        to manage workflows, maintain states, and handle errors. Temporal is
        particularly useful for managing long-running processes and complex,
        multi-step workflows.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos, on the other hand, is a powerful data streaming tool that can
        be used to connect various data sources and sinks. It provides a lot of
        flexibility in terms of data processing, transformation, and routing.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on the dropdown and select your desired conversion. In this
            case, we are converting from
          </p>
          <img src="/assets/images/mysql/mysql-to-mongodb/snip-1.png" />
        </li>
        <br />
        <li>
          <b>Validate the DB URL</b>
          <p>
            In this step, we will validate the URL of our MySQL database. Enter
            the URL of the database and click on validate button.
          </p>
          <img src="/assets/images/mysql/mysql-to-mongodb/snip-2.png" />
        </li>
        <br />
        <li>
          <b>Select Columns and WHERE clause</b>
          <p>
            Select the Columns from the tables shown below and then choose the
            WHERE clause.
          </p>
          <img src="/assets/images/mysql/mysql-to-mongodb/snip-3.png" />
        </li>
        <br />
        <li>
          <b>Validate Output DB URL</b>
          <p>
            In this step, we will validate the URL of the output MongoDB
            database. Enter the URL of the database and click on validate
            button.
          </p>
          <img src="/assets/images/mysql/mysql-to-mongodb/snip-4.png" />
        </li>
        <br />
        <li>
          <b>Select the Database</b>
          <p>
            Choose the output database from the dropdown by clicking on the
            dropdown menu.
          </p>
          <img src="/assets/images/mysql/mysql-to-mongodb/snip-5.png" />
        </li>
        <br />
        <li>
          <b>Select output Collection</b>
          <p>
            Select from either already created collections or choose Dynamic
            Collections. Let&lsquo;s assume we are creating a new collection, so
            we need to write the new collection name.
          </p>
          <img src="/assets/images/mysql/mysql-to-mongodb/snip-6.png" />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>Now we need to run the migration by clicking on Save & Run</p>
          <img src="/assets/images/mysql/mysql-to-mongodb/snip-7.png" />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/mysql/mysql-to-mongodb/activity.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Activity Logs</b>
          <p>These are the activity logs.</p>
          <img
            src="/assets/images/mysql/mysql-to-mongodb/logs.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default MySQLToMongoDbBlog
