import React from "react"
import { HelmetProvider } from "react-helmet-async"

import AuthContextWrapper from "context/authContextWrapper"
import StripeContextWrapper from "context/stripeContextWrapper"

import AppRoutes from "routes"

import "./assets/scss/theme.scss"
import FileHandlingContextWrapper from "context/fileHandlingContextWrapper"

const App = () => {
  return (
    <AuthContextWrapper>
      <StripeContextWrapper>
        <HelmetProvider>
          <FileHandlingContextWrapper>
            <AppRoutes />
          </FileHandlingContextWrapper>
        </HelmetProvider>
      </StripeContextWrapper>
    </AuthContextWrapper>
  )
}

export default App
