import React from "react"
import propTypes from "prop-types"
import { CustomTooltip } from "components"

const StructureFormatToolTip = ({ targetId }) => {
  return (
    <CustomTooltip
      description={
        <div style={{ textAlign: "left", paddingTop: "10px" }}>
          <p className="mb-1 Text-fontWeight--700">Structured Format</p>
          <p>
            This will insert the data from input columns to the output table
            columns.
          </p>
          <p className="mb-1 Text-fontWeight--700">Unstructured Format</p>
          <p>
            {`This will dump the data from input to the output table's column 'data' in jsonB format.`}
          </p>
        </div>
      }
      target={targetId}
    />
  )
}

StructureFormatToolTip.propTypes = {
  targetId: propTypes.string.isRequired,
}

export default StructureFormatToolTip
