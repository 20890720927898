import React from "react"
import Pagination from "react-responsive-pagination"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
} from "reactstrap"

import ApiLogsSkeleton from "./ApiLogsSkeleton"
import ApiLogsTable from "./ApiLogsTable"
import useApiLogs from "./useApiLogs"

const AccessApiLogs = () => {
  const {
    isLoading,
    requestLogs,
    pagination,
    handlePagination,
    onSubmitSearch,
    setSearch,
  } = useApiLogs()

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="h4">API Access Logs </CardTitle>
          <Col md={4}>
            <Form className="p-3 ps-0" onSubmit={onSubmitSearch}>
              <FormGroup className="m-0">
                <InputGroup>
                  <Input
                    type="text"
                    className="form-control"
                    name="search"
                    onChange={event => setSearch(event.target.value)}
                    placeholder="Search by Method, URL, StatusCode ..."
                    aria-label="Search by Method, URL, StatusCode ..."
                  />
                  <Button color="primary" type="submit">
                    <i className="mdi mdi-magnify" />
                  </Button>
                </InputGroup>
              </FormGroup>
            </Form>
          </Col>
          {isLoading ? (
            <ApiLogsSkeleton />
          ) : (
            <ApiLogsTable data={requestLogs} />
          )}

          <div
            className="d-flex align-items-end justify-content-end my-3 ms-auto"
            style={{ maxWidth: "50vw" }}
          >
            <Pagination
              current={pagination.currentPage}
              total={pagination.totalPages}
              onPageChange={handlePagination}
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default AccessApiLogs
