import React, { useEffect, useState } from "react"

import { useStripeContext } from "context/stripeContextWrapper"

import useLoading from "./useLoading"

import { getInvoicesURL } from "apis/stripe"

import axiosInstance from "services/axiosInstance"

const UseInvoices = ({ limit }) => {
  const { stripeMode } = useStripeContext()
  const { isLoading, disableLoading } = useLoading(true)
  const [invoices, setInvoices] = useState([])
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    let isMounted = true

    if (stripeMode && isMounted) {
      fetchInvoices()
    }
    return () => {
      isMounted = false
    }
  }, [stripeMode])

  const fetchInvoices = async () => {
    try {
      const {
        data: { hasMore, invoices: records },
      } = await axiosInstance.get(
        getInvoicesURL(limit, invoices ? invoices[invoices.length - 1]?.id : "")
      )
      setInvoices(prevRecords => [...prevRecords, ...records])
      setHasMore(hasMore)
      disableLoading()
    } catch (err) {
      console.log("Failed to fetch invoices: ", err)
      disableLoading()
    }
  }
  return {
    invoices,
    hasMore,
    isLoading,
    fetchInvoices,
  }
}

export default UseInvoices
