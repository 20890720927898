import React, { useEffect, useState, useCallback } from "react"
import { useParams } from "react-router-dom"

import {
  fetchActivityLogsURL,
  exportAllActivityLogsByIdURL,
} from "apis/activityLogs"

import axiosInstance from "services/axiosInstance"
import useLoading from "hooks/useLoading"
import { StatusEnum } from "utils/enums"

const UseActivityLogs = () => {
  const { disableLoading, isLoading, enableLoading } = useLoading(true)
  const { childWorkflowId = "" } = useParams()
  const [pagination, setPagination] = useState({})
  const [activityLogs, setActivityLogs] = useState([])
  const [limit] = useState(30)

  useEffect(() => {
    fetchActivityLogs({ offset: 0 })
  }, [])

  const fetchActivityLogs = async ({ offset }) => {
    enableLoading()
    try {
      const {
        data: {
          data: { activityLogs, pagination },
        },
      } = await axiosInstance.get(
        fetchActivityLogsURL(childWorkflowId, limit, offset)
      )
      setPagination(pagination)
      setActivityLogs(activityLogs)
      disableLoading()
    } catch (err) {
      console.log("Fetch activity logs api failed: ", err.response.data.message)
      disableLoading()
    }
  }

  const handlePagination = selected => {
    if (pagination.currentPage + 1 === selected) return
    fetchActivityLogs({ offset: limit * (selected - 1) })
  }

  const handleExportCSV = async () => {
    try {
      const data = await axiosInstance.get(
        exportAllActivityLogsByIdURL(childWorkflowId)
      )
      const url = data.data.filePath
      window.open(url)
    } catch (err) {
      console.log("Something went wrong:", err)
    }
  }

  const handleRefresh = useCallback(() => {
    fetchActivityLogs({ offset: 0 })
  }, [])

  const getStatusStyle = status => {
    return status === StatusEnum.ERROR
      ? "text-danger"
      : status === StatusEnum.STOPPED
      ? "text-success"
      : ""
  }

  return {
    pagination,
    activityLogs,
    isLoading,
    handleRefresh,
    handleExportCSV,
    handlePagination,
    getStatusStyle,
  }
}

export default UseActivityLogs
