import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const MySQLToBigqueryBlog = () => {
  const data = tags.find(items => items.blogName === "mysqlBigquery")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        As organizations evolve and embrace the power of cloud-based data
        solutions, migrating from traditional relational databases like MySQL to
        modern, scalable platforms such as Google BigQuery becomes a strategic
        imperative. In this blog post, we will explore the seamless migration
        journey from MySQL to BigQuery and delve into the fundamental aspects of
        databases, shedding light on their unique characteristics and use cases.
      </p>
      <h4>MySQL</h4>
      <p>
        MySQL is a popular open-source relational database management system. It
        is known for its stability, reliability, and performance in handling
        structured data. MySQL employs a table-based data storage model with
        ACID compliance, making it ideal for scenarios where data consistency
        and integrity are paramount.
      </p>
      <h4>Bigquery</h4>
      <p>
        Google BigQuery is a fully managed, serverless data warehouse provided
        by Google Cloud. It is designed for handling large-scale analytics and
        allows users to analyze and query massive datasets in real-time.
        BigQuery is known for its speed, scalability, and ease of use, making it
        a popular choice for organizations looking to perform advanced analytics
        on their data.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source orchestration platform that enables you to
        build and manage complex workflows. It is designed to handle
        long-running and stateful processes, making it a powerful tool for
        orchestrating data migration and synchronization tasks.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos is a versatile open-source data processing tool that excels at
        connecting various data sources and sinks. It offers a range of
        processors and connectors for data transformation, enrichment, and
        routing, making it an excellent tool for creating data pipelines.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Input Conversion</b>
          <p>
            Click on the <b>Choose Input Conversion</b> dropdown and select{" "}
            <b>MySQL</b>.
          </p>
          <img
            src="/assets/images/mysql/mysql-to-bigquery/snip-1.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on the <b>Choose Conversion</b> dropdown and select{" "}
            <b>Mysql To Bigquery</b>.
          </p>
          <img
            src="/assets/images/mysql/mysql-to-bigquery/snip-2.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Validate the DB URL</b>
          <p>
            Enter the URL of the MySQL database and click on validate button.
          </p>
          <img
            src="/assets/images/mysql/mysql-to-bigquery/snip-3.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select Columns and WHERE clause</b>
          <p>
            Select the Columns from the tables shown below and then choose the
            WHERE clause.
          </p>
          <img
            src="/assets/images/mysql/mysql-to-bigquery/snip-4.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Enter the Dataset of your Bigquery Database</b>
          <p>Specify the name of your dataset in the BigQuery database.</p>
          <img
            src="/assets/images/mysql/mysql-to-bigquery/snip-5.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select the Service Account File</b>
          <p>
            Select the JSON file of your service account you have downloaded
            from bigquery and click on verify button.
          </p>
          <img
            src="/assets/images/mysql/mysql-to-bigquery/snip-6.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select output table</b>
          <p>
            Select the output table from the list provided below. If you do not
            see the tables, kindly ensure that you have entered the correct
            dataset or verify that your database indeed contains tables.
          </p>
          <img
            src="/assets/images/mysql/mysql-to-bigquery/snip-7.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>Now we need to run the migration by clicking on Save & Run</p>
          <img
            src="/assets/images/mysql/mysql-to-bigquery/snip-8.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/mysql/mysql-to-bigquery/activity.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default MySQLToBigqueryBlog
