import React from "react"
import { Col, Row } from "reactstrap"
import { Helmet } from "react-helmet-async"

import SubscriptionSection from "./Subscription"
import { ENUMS } from "utils/enums"

const Billing = () => {
  return (
    <div className="page-content">
      <Helmet>
        <title>Billing | {ENUMS.BRAND_NAME}</title>
      </Helmet>
      <Row>
        <Col>
          <SubscriptionSection />
        </Col>
      </Row>
    </div>
  )
}

export default Billing
