import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"

import UseLoadingHook from "hooks/useLoading"

import { Spinner, Modal } from "components"

import { getEmail, subscriptionURL } from "apis/email"

import axiosInstance from "services/axiosInstance"

const SubscriptionCard = () => {
  const { isLoading, disableLoading, enableLoading } = UseLoadingHook()
  const [modal_standard, setmodal_standard] = useState(false)
  const [email, setEmail] = useState("")

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      fetchEmail()
    }
    return () => {
      isMounted = false
    }
  }, [])

  const fetchEmail = async () => {
    try {
      const {
        data: { Email },
      } = await axiosInstance.get(getEmail())
      setEmail(Email)
    } catch (err) {
      console.log("getEmail api failed: ", err)
    }
  }

  const handleSubscription = async () => {
    enableLoading()
    try {
      await axiosInstance.post(subscriptionURL(), {
        email,
      })
      tog_standard()
      disableLoading()
    } catch (err) {
      console.log("subscription api failed: ", err)
      disableLoading()
    }
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const tog_standard = () => {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody className="p-4">
          <div className="text-center">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <Row className="justify-content-center">
              <Col xl={10}>
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay updated.
                </p>

                <div className="input-group bg-light rounded">
                  <input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    value={email || ""}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      className="d-flex align-items-center btn btn-primary rounded"
                      type="button"
                      id="button-addon2"
                      onClick={() => handleSubscription()}
                    >
                      {isLoading ? (
                        <Spinner />
                      ) : (
                        <i className="bx bxs-paper-plane"></i>
                      )}
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
      <Modal
        isOpen={modal_standard}
        openModal={tog_standard}
        closeModal={tog_standard}
        closeText="Close"
      >
        <h5 className="text-center">Email Subscribed Successfully</h5>
      </Modal>
    </React.Fragment>
  )
}

export default SubscriptionCard
