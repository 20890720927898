/* eslint-disable react/prop-types */
import React from "react"
import moment from "moment"

import { Badge, Table } from "reactstrap"
import { isEmpty } from "lodash"

const ApiLogsTable = ({ data }) => {
  return (
    <Table responsive className="table mb-0">
      <thead>
        <tr>
          <th>DATE</th>
          <th>METHOD</th>
          <th>URL</th>
          <th>STATUS CODE</th>
        </tr>
      </thead>
      <tbody>
        {isEmpty(data) ? (
          <tr>
            <td colSpan={4} style={{ textAlign: "center" }}>
              <p className="my-3 Text-fontWeight--600">
                No API Access Logs Yet!{" "}
              </p>
            </td>
          </tr>
        ) : (
          data.map(({ _id, method, statusCode, url, createdAt }) => (
            <tr key={_id}>
              <td>{moment(createdAt).format("MMM Do, YYYY, hh:mm:ss")}</td>
              <td className="fw-bold">{method}</td>
              <td>{url}</td>
              <td>
                <Badge
                  id={`request-${_id}`}
                  className={`font-size-12 ${
                    statusCode >= 200 && statusCode < 400
                      ? "badge-soft-success"
                      : "badge-soft-danger"
                  }`}
                  color={`${
                    statusCode >= 200 && statusCode < 400 ? "" : "danger"
                  }`}
                  pill
                >
                  {statusCode}
                </Badge>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  )
}

export default ApiLogsTable
