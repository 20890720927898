import React, { useEffect, useRef } from "react"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"

import { useAuthContext } from "context/authContextWrapper"

const SidebarContent = ({ location }) => {
  const ref = useRef()
  const { isAddon } = useAuthContext()

  useEffect(() => {
    const initMenu = () => {
      new MetisMenu("#side-menu")
    }
    initMenu()
  }, [location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Menu</li>

            <li>
              <Link to={isAddon ? "/" : "/dashboard"}>
                <i className="bx bx-home-circle"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            {isAddon && (
              <li>
                <Link to="/email-subscription">
                  <i className="bx bx-envelope"></i>
                  <span>Notifications</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
}

export default withRouter(SidebarContent)
