/* eslint-disable react/prop-types */
import React, { useRef } from "react"
import { FormFeedback } from "reactstrap"

import { TreeViewList, Spinner } from "components"
import ReactstrapInput from "components/Inputs/ReactstrapInput"
import useBigqueryInputForm from "./useBigqueryInputForm"

const BigqueryInputForm = ({
  configurationData,
  setUserInputs,
  setUserOutputs,
  userInputs,
  userOutputs,
  outputName,
  sequelWhereTreeData,
  setSequelColumnTreeData,
  setSequelWhereTreeData,
}) => {
  const fileInputRef = useRef()

  const {
    isFetchingColumns,
    isLoading,
    columns,
    error,
    status,
    getColumns,
    setStatus,
    _SetError,
    handleMultiSelectedColumns,
    handleValidateAndFetchColumns,
    handleSingleSelectedColumn,
  } = useBigqueryInputForm({
    userInputs,
    userOutputs,
    outputName,
    setUserInputs,
    setUserOutputs,
    setSequelWhereTreeData,
  })

  return (
    <>
      {configurationData?.activity?.requiredInputs.map(
        (requiredInput, index) => {
          return (
            <div className="mb-3" key={index}>
              {requiredInput.Key === "dataset" ? (
                <div className="d-flex flex-row w-100">
                  <ReactstrapInput
                    className={`form-control  ${
                      status === null
                        ? ""
                        : status === true
                        ? "is-valid"
                        : "is-invalid"
                    }`}
                    name={requiredInput.Key}
                    value={userOutputs[requiredInput.Key] || ""}
                    placeholder={
                      requiredInput.isRequired === true
                        ? requiredInput.Label + "*"
                        : requiredInput.Label
                    }
                    onChange={e => {
                      if (!e.target.value) setStatus(false)
                      if (!e.target.value) _SetError()
                      setUserOutputs({
                        ...userOutputs,
                        [requiredInput.Key]: e.target.value.trim(),
                      })
                    }}
                  />
                </div>
              ) : requiredInput.Key === "file" ? (
                <div className="d-flex flex-row w-100">
                  <div className="w-100 me-2">
                    <ReactstrapInput
                      className={`form-control  ${
                        status === null
                          ? ""
                          : status === true
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      innerRef={fileInputRef}
                      name={requiredInput.Key}
                      type="file"
                      accept=".json"
                      onChange={e => {
                        if (!e.target.file) setStatus(false)
                        if (!e.target.file) _SetError()
                        setUserOutputs({
                          ...userOutputs,
                          [requiredInput.Key]: e.target.files[0],
                        })
                      }}
                    />
                    {error && <FormFeedback invalid>{error}</FormFeedback>}
                  </div>
                  <div className="">
                    <button
                      disabled={
                        !(userOutputs?.dataset && userOutputs?.file) ||
                        isLoading
                          ? true
                          : false
                      }
                      className="d-flex btn btn-primary align-items-center"
                      onClick={() => {
                        getColumns(userOutputs.dataset, userOutputs.file)
                      }}
                    >
                      <span>Validate</span> {isLoading && <Spinner />}
                    </button>
                  </div>
                </div>
              ) : requiredInput.Key === "columns" ? (
                <>
                  <p className="p-0 mb-1">Select Columns*</p>
                  {isLoading || isFetchingColumns ? (
                    <div className="position-relative">
                      <ReactstrapInput
                        className={`form-control`}
                        type="text"
                        value={userInputs?.dns || ""}
                        disabled={isLoading || isFetchingColumns}
                      />
                      <p
                        style={{
                          fontSize: "12px",
                          top: "25%",
                          left: "15px",
                          color: "dimgray",
                          position: "absolute",
                        }}
                      >
                        Fetching Columns, Please wait...
                      </p>
                    </div>
                  ) : (
                    <TreeViewList
                      treeData={columns}
                      type="column"
                      multiSelect={true}
                      propagateSelect={true}
                      propagateSelectUpwards={true}
                      setTreeData={setSequelColumnTreeData}
                      handleSelectedColumn={handleMultiSelectedColumns}
                      configurationData={configurationData}
                    />
                  )}
                </>
              ) : requiredInput.Key === "where" ? (
                <>
                  <p className="p-0 mb-1">Where Clause*</p>
                  <div className="position-relative">
                    <TreeViewList
                      treeData={sequelWhereTreeData.whereClauseTreeData}
                      selectedColumns={sequelWhereTreeData.selectedColumns}
                      multiSelect={true}
                      type="where"
                      setTreeData={setSequelColumnTreeData}
                      userInputs={userInputs}
                      handleSelectedColumn={handleSingleSelectedColumn}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          )
        }
      )}
    </>
  )
}

export default BigqueryInputForm
