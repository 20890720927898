/* eslint-disable react/prop-types */
import React from "react"

import { Spinner } from "components"
import { Badge, Table } from "reactstrap"

import { StatusEnum } from "utils/enums"

const UserActivityTable = ({
  userActivities,
  activityStates = [],
  activitiesStatus,
  getActivityStatusBadge,
  gotoLogs,
  onToggleModal,
  onStatusUpdate,
}) => {
  return (
    <Table responsive className="userActivityTable">
      <thead>
        <tr>
          <th>#</th>
          <th style={{ minWidth: "150px" }}>Name</th>
          <th>Status</th>
          <th>Details</th>
          <th style={{ minWidth: "150px" }}>Activity Logs</th>
          <th style={{ minWidth: "300px" }}>Actions</th>
        </tr>
      </thead>

      <tbody style={{ backgroundColor: "white" }}>
        {userActivities?.map((activity, index) => (
          <tr key={index + 1}>
            <td style={{ padding: "2vh" }}>{index + 1}</td>
            <td style={{ padding: "2vh" }}>{activity?.activity?.name}</td>
            <td style={{ padding: "2vh" }}>
              <Badge
                pill
                color={getActivityStatusBadge(activity?.status)}
                className="badge"
              >
                {activity?.status}
              </Badge>
            </td>
            <td
              style={{ padding: "2vh", maxWidth: "250px" }}
              className="text-truncate"
            >
              {activity?.details && activity?.details}
            </td>
            <td style={{ padding: "2vh" }}>
              <button
                className={`btn btn-primary`}
                onClick={() => gotoLogs({ activityId: activity?._id })}
              >
                Activity Logs
              </button>
            </td>
            <td
              className="d-flex align-items-center justify-content-between"
              style={{ padding: "2vh" }}
            >
              <button
                className={`btn btn-primary`}
                onClick={() => onToggleModal(activity)}
              >
                View Config
              </button>
              {activityStates?.includes(activity?.status) && (
                <>
                  <button
                    className={`d-flex btn btn-primary`}
                    style={{ marginLeft: "3vh" }}
                    disabled={activitiesStatus[index]?.isPausingOrResuming}
                    onClick={() => {
                      onStatusUpdate({
                        activityId: activity?._id,
                        status:
                          activity?.status === StatusEnum.PAUSED
                            ? "Resumed"
                            : "Paused",
                        index,
                        loaderLabel: "isPausingOrResuming",
                      })
                    }}
                  >
                    {activity?.status === StatusEnum.PAUSED
                      ? "Resume"
                      : "Pause"}
                    {activitiesStatus[index]?.isPausingOrResuming && (
                      <Spinner />
                    )}
                  </button>
                  <button
                    className={`d-flex btn btn-danger`}
                    style={{ marginLeft: "3vh" }}
                    disabled={activitiesStatus[index]?.isStopping}
                    onClick={() => {
                      onStatusUpdate({
                        activityId: activity?._id,
                        status: "Stopped",
                        index,
                        loaderLabel: "isStopping",
                      })
                    }}
                  >
                    Stop {activitiesStatus[index]?.isStopping && <Spinner />}
                  </button>
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default UserActivityTable
