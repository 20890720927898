import * as yup from "yup"

const username = yup
  .string()
  .min(4, "Minimum 4 characters are required")
  .required("Username is a required field")

const password = yup
  .string()
  .required("Please Enter your password")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase and One Number"
  )

const email = yup.string().email().required("Email is a required field")

const confirmPassword = yup
  .string()
  .oneOf([yup.ref("password"), null], "Passwords must match")
  .required("Confirm password is a required field")

export const LoginFormSchema = yup.object().shape({
  email,
  password,
})

export const SignupFormSchema = yup.object().shape({
  firstName: yup.string().required("First name is a required field"),
  lastName: yup.string().required("Last name is a required field"),
  email,
  username,
  password,
  confirmPassword,
})

export const ForgetPasswordSchema = yup.object().shape({
  email,
})

export const ResetPasswordSchema = yup.object().shape({
  password,
  confirmPassword,
})

export const updateUserSchema = yup.object().shape({
  firstName: yup.string().required("First name is a required field"),
  lastName: yup.string().required("Last name is a required field"),
  email,
  username,
})

export const contactSchema = yup.object().shape({
  email,
  fullName: yup.string().required("Full name is a required field"),
  message: yup.string().required("Message is a required field"),
})
