import React from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"

import LoginImg from "assets/images/profile-img.png"

import DatabashiLogo from "components/databashiLogo"

import LoginForm from "./form"

import { ENUMS } from "utils/enums"

import "./index.css"

const Login = () => {
  return (
    <div className="card-wrapper">
      <Helmet>
        <title>Login | {ENUMS.BRAND_NAME}</title>
      </Helmet>
      <div className="banner-section">
        <img src={LoginImg} alt="login" className="banner-img" />
      </div>
      <div className="right-section">
        <div className="form-container">
          <Link
            to="/"
            className="d-flex mb-2 align-items-center justify-content-center"
          >
            <DatabashiLogo />
          </Link>
          <div className="form-header">
            <h2>Login</h2>
            <p>
              New User? <Link to="/auth/signup">Create an Account</Link>
            </p>
          </div>
          <LoginForm />
          <div className="forgot-password mt-3 d-flex align-items-center justify-content-center">
            <Link to="/auth/forget-password">Forget Password?</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
