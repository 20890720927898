import React, { useState } from "react"
import useStatus from "./useStatus"

import {
  validateBigqueryConnectionURL,
  retrieveBigqueryColumnsURL,
} from "apis/dbConnections.apis"
import axiosInstance from "services/axiosInstance"
import { useFileHandlingContext } from "context/fileHandlingContextWrapper"

const UseBigquery = ({ type }) => {
  const { setFilePath, setFileName } = useFileHandlingContext()
  const { error, _SetError } = useStatus()
  const [isDisable, setIsDisable] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const [isFetchingColumns, setIsFetchingColumns] = useState(false)

  const [tables, setTables] = useState([])
  const [columns, setColumns] = useState([])

  const validateDbConnection = async (dataset, file) => {
    if (!dataset) return
    try {
      setIsLoading(true)
      const formData = new FormData()
      formData.append("file", file)
      formData.append("dataset", dataset)
      const data = await axiosInstance.post(
        validateBigqueryConnectionURL(),
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      setFileName(data.data.fileName)
      setFilePath(data.data.filePath)
      const tables = data.data.tableNames
      _SetError(null)
      setStatus(true)
      setIsDisable(false)
      setIsLoading(false)
      setTables(tables)
    } catch (err) {
      console.log(`${type} db failed to establish connection: `, err)
      _SetError(err.response.data.message)
      setStatus(false)
      setIsDisable(true)
      setIsLoading(false)
    }
  }

  const getColumns = async (dataset, file) => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append("file", file)
    formData.append("dataset", dataset)
    setIsFetchingColumns(true)
    try {
      const {
        data: { columns },
      } = await axiosInstance.post(retrieveBigqueryColumnsURL(), formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      setColumns(columns)
      setStatus(true)
      setIsDisable(false)
      setIsLoading(false)
      setIsFetchingColumns(false)
    } catch (err) {
      console.log(`Failed to fetch ${type} columns`)
      setIsFetchingColumns(false)
    }
  }

  return {
    status,
    isDisable,
    isLoading,
    error,
    tables,
    columns,
    isFetchingColumns,
    getColumns,
    setIsDisable,
    setTables,
    setStatus,
    _SetError,
    validateDbConnection,
  }
}

export default UseBigquery
