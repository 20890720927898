import React from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import PropTypes from "prop-types"

import LOGO from "assets/images/databashi-logo.png"

import { useAuthContext } from "context/authContextWrapper"
import { useStripeContext } from "context/stripeContextWrapper"

const Index = ({ isOpen, toggleMenu }) => {
  const { user, logout } = useAuthContext()
  const { hasStripeAccess } = useStripeContext()

  const history = useHistory()
  return (
    <Dropdown isOpen={isOpen} toggle={toggleMenu} className="d-inline-block">
      <DropdownToggle
        className="btn header-item"
        id="page-header-user-dropdown"
        tag="button"
      >
        <img
          className="rounded-circle header-profile-user"
          src={LOGO}
          alt="Header Avatar"
        />
        <span className="d-none d-xl-inline-block ms-2 me-1 Text-fontWeight--600">
          {user?.additionalInfo?.firstName}
        </span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block animate-bounce" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem tag="a" onClick={() => history.push("/profile")}>
          <i className="bx bx-user font-size-16 align-middle me-1" />
          Profile
        </DropdownItem>
        {hasStripeAccess && (
          <DropdownItem tag="a" onClick={() => history.push("/billing")}>
            <i className="bx bx-credit-card font-size-16 align-middle me-1" />
            Billing
          </DropdownItem>
        )}
        <DropdownItem
          tag="a"
          onClick={() => history.push("/developer-settings")}
        >
          <i className="bx bx-cog font-size-16 align-middle me-1" />
          Developer Settings
        </DropdownItem>
        <div className="dropdown-divider" />
        <Link to="#" onClick={() => logout()} className="dropdown-item">
          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
          <span>Logout</span>
        </Link>
      </DropdownMenu>
    </Dropdown>
  )
}

Index.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
}

export default Index
