/* eslint-disable react/prop-types */
import React, { useMemo } from "react"

import KafkaOutputsForm from "./kafkaOutputForm"
import MongoOutputsForm from "./mongoOutputForm"
import SequelOutputsForm from "./sequelOutputForm"
import RedisOutputsForm from "./redisOutputForm"
import BigqueryOutputForm from "./bigqueryOutputForm"

const RequiredOutputsForm = ({
  configurationData,
  userOutputs,
  userInputs,
  setUserOutputs,
  configuration = "",
  sequelColumnTreeData,
}) => {
  const outputName = useMemo(() => {
    return (configuration && configuration?.split(" ")[2]) || ""
  }, [configuration])

  const commonSequelProps = {
    configuration,
    configurationData,
    setUserOutputs,
    userOutputs,
    userInputs,
    sequelTreeData: sequelColumnTreeData,
  }

  const outputComponents = {
    Kafka: {
      component: KafkaOutputsForm,
      props: {
        configurationData,
        setUserOutputs,
        userOutputs,
      },
    },
    Redis: {
      component: RedisOutputsForm,
      props: {
        configurationData,
        setUserOutputs,
        userOutputs,
      },
    },
    Postgres: {
      component: SequelOutputsForm,
      props: commonSequelProps,
    },
    MongoDB: {
      component: MongoOutputsForm,
      props: commonSequelProps,
    },
    MySQL: {
      component: SequelOutputsForm,
      props: commonSequelProps,
    },
    Snowflake: {
      component: SequelOutputsForm,
      props: commonSequelProps,
    },
    Redshift: {
      component: SequelOutputsForm,
      props: commonSequelProps,
    },
    Bigquery: {
      component: BigqueryOutputForm,
      props: { configurationData, configuration, userOutputs, setUserOutputs },
    },
  }

  const OutputFormComponent = outputComponents[outputName]?.component
  const OutputFormProps = outputComponents[outputName]?.props

  return !OutputFormComponent ? null : (
    <div>
      <label className="">Required Outputs</label>
      <OutputFormComponent {...OutputFormProps} />
    </div>
  )
}

export default RequiredOutputsForm
