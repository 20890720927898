import React, { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"

import { useAuthContext } from "context/authContextWrapper"

import UseLoadingHook from "hooks/useLoading"

import { Spinner } from "components"

import { updateUserAccountDetailsURL } from "apis/user"
import axiosInstance from "services/axiosInstance"
import { updateUserSchema } from "utils/validationSchema"

const defaultValues = {
  firstName: "",
  lastName: "",
  username: "",
  email: "",
}

const UserUpdateForm = () => {
  const { user, storeUser } = useAuthContext()
  const { isLoading, enableLoading, disableLoading } = UseLoadingHook()

  const { handleSubmit, reset, control } = useForm({
    mode: "all",
    reValidateMode: "onSubmit",
    defaultValues: defaultValues,
    resolver: yupResolver(updateUserSchema),
  })

  useEffect(() => {
    if (user) {
      reset({
        firstName: user.additionalInfo?.firstName,
        lastName: user.additionalInfo?.lastName,
        username: user.additionalInfo?.username,
        email: user.additionalInfo?.email,
      })
    }
  }, [user])

  const onSubmit = async data => {
    enableLoading()
    try {
      await axiosInstance.put(
        updateUserAccountDetailsURL(user?.additionalInfo?._id),
        {
          ...data,
        }
      )
      storeUser()
      disableLoading()
    } catch (err) {
      console.log("update user api failed: ", err.response.data.message)
      disableLoading()
    }
  }

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">User Details</CardTitle>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-firstname-Input">First name</Label>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="text"
                      className="form-control"
                      id="formrow-firstname-Input"
                      placeholder="Enter Your First Name"
                      {...field}
                    />
                  )}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-lastname-Input">Last name</Label>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="text"
                      className="form-control"
                      id="formrow-firstname-Input"
                      placeholder="Enter Your Last Name"
                      {...field}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-email-Input">Email</Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="email"
                      disabled
                      className="form-control"
                      id="formrow-email-Input"
                      placeholder="Enter Your Email ID"
                      {...field}
                    />
                  )}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label htmlFor="formrow-username-Input">User name</Label>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="text"
                      className="form-control"
                      id="formrow-username-Input"
                      placeholder="Enter Your User Name"
                      {...field}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
          <div>
            <Button
              type="submit"
              disabled={isLoading}
              color="primary"
              className="d-flex flex-row align-items-center justify-content-center w-md"
            >
              Update {isLoading && <Spinner />}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default UserUpdateForm
