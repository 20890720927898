/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react"
import { FormFeedback } from "reactstrap"

import { Spinner } from "components"
import ReactstrapInput from "components/Inputs/ReactstrapInput"
import { tableDropdownOptions } from "utils/helpers"
import Select from "react-select"

import UseBigquery from "hooks/useBigquery"

const BigqueryOutputForm = ({
  configurationData,
  userOutputs,
  setUserOutputs,
}) => {
  const {
    status,
    isLoading,
    isDisable,
    error,
    tables,
    setTables,
    setStatus,
    _SetError,
    setIsDisable,
    validateDbConnection,
  } = UseBigquery({ type: "output" })
  const [tableNames, setTableNames] = useState(null)
  const fileInputRef = useRef()
  useEffect(() => {
    setUserOutputs({})
    setTables([])
    setTableNames([])
    setIsDisable(true)
    return () => {
      if (fileInputRef.current) fileInputRef.current.value = null
    }
  }, [configurationData?.activity?.name])

  const handleTables = ({ key, value: selected, type }) => {
    function filterValues(tables) {
      return tables.map(({ value }) => value.trim())
    }
    if (type === "select") {
      const allSelected = selected.find(option => option.value === "all")
      const remainingTables = tableDropdownOptions(tables).slice(2)
      allSelected ? setTableNames(remainingTables) : setTableNames(selected)
      const values = allSelected
        ? filterValues(remainingTables).join(",")
        : filterValues(selected).join(",")
      setUserOutputs({
        ...userOutputs,
        [key]: !values ? "" : values.trim(),
      })
    }
  }

  return (
    <>
      {configurationData?.activity?.requiredOutputs.map(
        (requiredOutput, index) => {
          return (
            <div className="mb-3" key={index}>
              {requiredOutput.Key === "dataset" ? (
                <div className="d-flex flex-row w-100">
                  <ReactstrapInput
                    className={`form-control  ${
                      status === null
                        ? ""
                        : status === true
                        ? "is-valid"
                        : "is-invalid"
                    }`}
                    name={requiredOutput.Key}
                    value={userOutputs[requiredOutput.Key] || ""}
                    placeholder={
                      requiredOutput.isRequired === true
                        ? requiredOutput.Label + "*"
                        : requiredOutput.Label
                    }
                    onChange={e => {
                      if (!e.target.value) setStatus(false)
                      if (!e.target.value) _SetError()
                      setUserOutputs({
                        ...userOutputs,
                        [requiredOutput.Key]: e.target.value.trim(),
                      })
                    }}
                  />
                </div>
              ) : requiredOutput.Key === "file" ? (
                <div className="d-flex flex-row w-100">
                  <div className="w-100 me-2">
                    <ReactstrapInput
                      className={`form-control  ${
                        status === null
                          ? ""
                          : status === true
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      innerRef={fileInputRef}
                      name={requiredOutput.Key}
                      type="file"
                      accept=".json"
                      onChange={e => {
                        setTables(null)
                        setTableNames(null)
                        if (!e.target.file) setStatus(false)
                        if (!e.target.file) _SetError()
                        setUserOutputs({
                          ...userOutputs,
                          [requiredOutput.Key]: e.target.files[0],
                        })
                      }}
                    />
                    {error && <FormFeedback invalid>{error}</FormFeedback>}
                  </div>
                  <div className="">
                    <button
                      disabled={
                        !(userOutputs?.dataset && userOutputs?.file) ||
                        isLoading
                          ? true
                          : false
                      }
                      className="d-flex btn btn-primary align-items-center"
                      onClick={() => {
                        validateDbConnection(
                          userOutputs.dataset,
                          userOutputs.file
                        )
                      }}
                    >
                      <span>Validate</span> {isLoading && <Spinner />}
                    </button>
                  </div>
                </div>
              ) : requiredOutput.key !== "table" ? (
                <Select
                  className="multi"
                  classNamePrefix="select"
                  placeholder={
                    requiredOutput.isRequired
                      ? `${requiredOutput.Label}*`
                      : requiredOutput.Label
                  }
                  isDisabled={isDisable}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  menuPlacement="auto"
                  isMulti
                  value={tableNames ? tableNames : null}
                  closeMenuOnSelect={false}
                  name={requiredOutput.Key}
                  options={tableDropdownOptions(tables)}
                  onChange={selected =>
                    handleTables({
                      key: requiredOutput.Key,
                      value: selected,
                      type: "select",
                    })
                  }
                />
              ) : (
                ""
              )}
            </div>
          )
        }
      )}
    </>
  )
}

export default BigqueryOutputForm
