import React from "react"

const Notes = () => {
  return (
    <ul className="bg-light fs-6 rounded py-2">
      <li>Amount will be prorated and adjusted automatically.</li>
      <li>
        If not entered new card details, previously provided payment details
        will be used to charge.
      </li>
    </ul>
  )
}

export default Notes
