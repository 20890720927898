/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from "react"

import KafkaInputForm from "./kafkaInputForm"
import SequelInputForm from "./sequelInputForm"
import RedisInputForm from "./redisInputForm"
import MongoInputForm from "./mongoInputForm"
import BigqueryInputForm from "./bigqueryInputForm"

const RequiredInputsForm = ({
  configurationData,
  setUserInputs,
  setUserOutputs,
  userInputs,
  userOutputs,
  configuration = "",
  sequelColumnTreeData,
  sequelWhereTreeData,
  setSequelColumnTreeData,
  setSequelWhereTreeData,
}) => {
  const inputName = useMemo(() => {
    return (configuration && configuration?.split(" ")[0]) || ""
  }, [configuration])
  const outputName = useMemo(() => {
    return (configuration && configuration?.split(" ")[2]) || ""
  }, [configuration])

  useEffect(() => {
    setSequelWhereTreeData({
      whereClauseTreeData: [],
      selectedColumns: [],
    })
  }, [inputName, outputName])

  const commonSequelProps = {
    configurationData,
    setUserInputs,
    setUserOutputs,
    userInputs,
    userOutputs,
    outputName,
    sequelColumnTreeData,
    sequelWhereTreeData,
    setSequelColumnTreeData,
    setSequelWhereTreeData,
  }

  const inputComponents = {
    Kafka: {
      component: KafkaInputForm,
      props: {
        configuration,
        configurationData,
        setUserInputs,
        userInputs,
      },
    },
    Redis: {
      component: RedisInputForm,
      props: {
        configuration,
        configurationData,
        setUserInputs,
        userInputs,
      },
    },
    MongoDB: {
      component: MongoInputForm,
      props: {
        configuration,
        configurationData,
        setUserInputs,
        userOutputs,
        userInputs,
      },
    },
    MySQL: {
      component: SequelInputForm,
      props: commonSequelProps,
    },
    Postgres: {
      component: SequelInputForm,
      props: commonSequelProps,
    },
    Redshift: {
      component: SequelInputForm,
      props: commonSequelProps,
    },
    Snowflake: {
      component: SequelInputForm,
      props: commonSequelProps,
    },
    Bigquery: {
      component: BigqueryInputForm,
      props: commonSequelProps,
    },
  }

  const InputFormComponent = inputComponents[inputName]?.component
  const InputFormProps = inputComponents[inputName]?.props

  return !InputFormComponent ? null : (
    <div>
      <label className="">Required Inputs</label>
      <InputFormComponent {...InputFormProps} />
    </div>
  )
}

export default RequiredInputsForm
