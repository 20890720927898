/* eslint-disable react/prop-types */
import React from "react"

import UserActivityTable from "./userActivityTable"
import ReactPaginate from "react-paginate"
import useUserActivityTable from "./useUserActivityTable"

import "./userActivityTable.css"

const UserActivityWrapper = ({
  userActivities,
  handleStatusUpdate,
  toggleProcedureModal,
  activitiesStatus,
  gotoActivityLogs,
}) => {
  const {
    STATES,
    getActivityStatusBadge,
    gotoLogs,
    onStatusUpdate,
    onToggleModal,
    currentItems,
    pageCount,
    handlePageClick,
  } = useUserActivityTable({
    userActivities,
    handleStatusUpdate,
    toggleProcedureModal,
    gotoActivityLogs,
  })

  return (
    <div className="row">
      <div className="col mb-3">
        <label className="mb-3">Activities</label>
        <UserActivityTable
          userActivities={currentItems}
          activitiesStatus={activitiesStatus}
          activityStates={STATES}
          getActivityStatusBadge={getActivityStatusBadge}
          gotoLogs={gotoLogs}
          onStatusUpdate={onStatusUpdate}
          onToggleModal={onToggleModal}
        />
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  )
}

export default UserActivityWrapper
