import React, { useRef, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"

import useAlert from "hooks/useAlert"
import useLoading from "hooks/useLoading"

import { postQuery } from "apis/auth"
import axiosInstance from "services/axiosInstance"
import { contactSchema } from "utils/validationSchema"

const DEFAULT_VALUES = {
  email: "",
  fullName: "",
  message: "",
}

const useContact = () => {
  const captchaRef = useRef(null)

  const { enableLoading, disableLoading, isLoading } = useLoading()
  const { alert, disableAlert, enableAlert } = useAlert()
  const [initialValues, setInitialValues] = useState(DEFAULT_VALUES)

  const { handleSubmit, control } = useForm({
    defaultValues: initialValues,
    mode: "all",
    reValidateMode: "onSubmit",
    resolver: yupResolver(contactSchema),
  })

  const onSubmit = async values => {
    const token = captchaRef.current.getValue()
    captchaRef.current.reset()
    const data = { ...values, token: token }
    try {
      enableLoading()
      const {
        data: { message },
      } = await axiosInstance.post(postQuery(), data)
      setRecaptcha(null)
      setInitialValues(DEFAULT_VALUES)
      disableLoading()
      enableAlert({ visible: true, color: "success", message })
    } catch (err) {
      console.log("contact us api failed: ", err)
      enableAlert({
        visible: true,
        color: "danger",
        message: err.response.data.message,
      })
      disableLoading()
    }
  }

  const handleExpireCaptcha = () => {
    setRecaptcha(null)
  }

  return {
    isLoading,
    alert,
    captchaRef,
    control,
    handleSubmit,
    onSubmit,
    disableAlert,
    handleExpireCaptcha,
  }
}

export default useContact
