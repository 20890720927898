import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { Breadcrumb, BreadcrumbItem } from "reactstrap"

import { ProfileMenu, Switch } from "components"
import { useAuthContext } from "context/authContextWrapper"
import { useStripeContext } from "context/stripeContextWrapper"
import axiosInstance from "services/axiosInstance"
import { exportServerLogsURL } from "apis/user"

const Header = () => {
  const { pathname } = useLocation()
  const { user = {}, isAddon } = useAuthContext()
  const { stripeMode, handleStripeMode, setStripe, hasStripeAccess } =
    useStripeContext()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (user?.additionalInfo) {
      setStripe(prevState => ({
        ...prevState,
        mode: user?.additionalInfo?.stripeMode,
      }))
    }
  }, [user])

  const toggleMenu = () => {
    setIsOpen(prevState => !prevState)
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }
  // async function exportLogs() {
  //   try {
  //     const { data } = await axiosInstance.get(exportServerLogsURL())
  //     window.open(data.filePath)
  //   } catch (err) {
  //     console.log("Something went wrong:", err)
  //   }
  // }

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            {pathname.includes("activity-logs") && (
              <Breadcrumb className="mb-0">
                <BreadcrumbItem>
                  <Link
                    to={`${isAddon ? "/client" : "/dashboard"}`}
                    className="text-primary text-decoration-underline"
                  >
                    Dashboard
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem active className="text-decoration-underline">
                  Activity Logs
                </BreadcrumbItem>
              </Breadcrumb>
            )}
            {pathname.includes("billing") && pathname.includes("invoices") && (
              <Breadcrumb className="mb-0">
                <BreadcrumbItem>
                  <a
                    href="/billing"
                    className="text-primary text-decoration-underline"
                  >
                    Billing
                  </a>
                </BreadcrumbItem>
                <BreadcrumbItem active className="text-decoration-underline">
                  Invoices
                </BreadcrumbItem>
              </Breadcrumb>
            )}
          </div>
          {!isAddon && (
            <div className="d-flex flex-row align-items-center justify-content-between gap-1">
              {/* <button onClick={exportLogs}>Export Logs</button> */}
              {hasStripeAccess && user?.additionalInfo?.role === "dev" && (
                <span className="d-flex position-relative align-items-center gap-2 ">
                  <p className="m-0 text-muted d-none d-md-block ">
                    {stripeMode === "test" ? "Test Mode" : "Live Mode"}
                  </p>
                  <Switch
                    value={stripeMode === "test"}
                    handleToggle={handleStripeMode}
                  />
                </span>
              )}
              <ProfileMenu isOpen={isOpen} toggleMenu={toggleMenu} />
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
