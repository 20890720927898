import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types"
import moment from "moment"

const MiniWidget = ({ title = "", stats = [] }) => {
  const series = [
    {
      name: "Processed Count",
      data: stats.map(({ processedCount }) => processedCount),
    },
  ]
  const options = {
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#f1b44c"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [25, 100, 100, 100],
      },
    },
    xaxis: {
      categories: stats.map(({ _id }) => moment(_id).format("MMM - YYYY")),
    },
    tooltip: { fixed: { enabled: !1 }, x: { show: 1 }, marker: { show: !1 } },
  }
  return (
    <Card className="mini-stats-wid" style={{ minHeight: "99px" }}>
      <CardBody>
        <Row>
          <Col>
            <p className="text-muted fw-medium">{title}</p>
          </Col>
          <Col>
            <ReactApexChart
              options={options}
              series={series}
              type="area"
              height={40}
              className="apex-charts"
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

MiniWidget.propTypes = {
  title: PropTypes.string.isRequired,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      processedCount: PropTypes.number.isRequired,
    })
  ).isRequired,
}

export default MiniWidget
