import React from "react"
import { Alert } from "reactstrap"
import PropTypes from "prop-types"

import "./alertbar.scss"

const AlertBar = ({
  color,
  showAlert,
  disableAlert,
  children,
  className,
  ...rest
}) => {
  return (
    <Alert
      color={color}
      isOpen={showAlert}
      toggle={disableAlert}
      className={className}
      style={{ ...rest }}
    >
      {children}
    </Alert>
  )
}

AlertBar.propTypes = {
  color: PropTypes.string,
  showAlert: PropTypes.bool,
  disableAlert: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  className: PropTypes.string,
}

export default AlertBar
