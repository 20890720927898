/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { fetchUserActivities, getUserStats } from "apis/user"
import {
  createUserActivityURL,
  fetchAllActivities,
  fetchSelectedActivity,
  updateActivityStatus,
} from "apis/activities"
import { fetchEnvVariables } from "apis/envVariable"
import { fetchMonthlyStats, fetchTotalProcessedCount } from "apis/stats"
import axiosInstance from "services/axiosInstance"
import { ActivitiesEnum } from "utils/enums"
import { useLoading } from "hooks"
import { useAuthContext } from "context/authContextWrapper"

import useAlert from "./useAlert"
import { useFileHandlingContext } from "context/fileHandlingContextWrapper"

const CONFIGS = [
  ActivitiesEnum.KafkaToKafka,
  ActivitiesEnum.KafkaToPostgres,
  ActivitiesEnum.PostgresToPostgres,
  ActivitiesEnum.PostgresToMongodb,
  ActivitiesEnum.PostgresToBigquery,
  ActivitiesEnum.PostgresToRedshift,
  ActivitiesEnum.PostgresToSnowflake,
  ActivitiesEnum.MySQLToBigquery,
  ActivitiesEnum.MySQLToMongodb,
  ActivitiesEnum.MySQLToMySQL,
  ActivitiesEnum.MySQLToRedshift,
  ActivitiesEnum.MySQLToSnowflake,
  ActivitiesEnum.MongodbToBigquery,
  ActivitiesEnum.MongodbToRedshift,
  ActivitiesEnum.MongodbToSnowflake,
  ActivitiesEnum.RedisToRedis,
  ActivitiesEnum.RedisToPostgres,
  ActivitiesEnum.RedshiftToPostgres,
  ActivitiesEnum.RedshiftToMySQL,
  ActivitiesEnum.RedshiftToMongodb,
  ActivitiesEnum.SnowflakeToMongodb,
  ActivitiesEnum.SnowflakeToMySQL,
  ActivitiesEnum.SnowflakeToPostgres,
]

const UseDashboard = () => {
  const history = useHistory()
  const { disableAlert, enableAlert, alert } = useAlert()
  const {
    disableLoading: disableUserActivityLoading,
    isLoading: userActivityLoading,
  } = useLoading(true)
  const {
    disableLoading: disableAllActivitiesLoading,
    isLoading: allActivitiesLoading,
  } = useLoading(true)
  const { isAddon } = useAuthContext()

  const [isSaved, setIsSaved] = useState(false)
  const [modal_standard, setmodal_standard] = useState(false)
  const [isButtonSave, setIsButtonSave] = useState(false)
  const [userActivities, setUserActivities] = useState([]) //User Activities logs
  const [allActivities, setAllActivities] = useState([]) //Activities
  const [userEnv, setUserEnv] = useState([]) //User Environmental Variables
  const [isActivityAllow, setIsActivityAllow] = useState(true)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [configuration, setConfiguration] = useState(
    ActivitiesEnum.PostgresToMongodb
  ) //Configuration Name for Selected Dropdown  "Postgres to MongoDB"
  const [configurationData, setConfigurationData] = useState({}) //Configuration Details for Selected Dropdown
  const [userInputs, setUserInputs] = useState({})
  const [userOutputs, setUserOutputs] = useState({})
  const [showProcedureModal, setShowProcedureModal] = useState(false)
  const [selectedActivityProcedure, setSelectedActivityProcedure] = useState({})
  const [responseMsg, setResponseMsg] = useState("")
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [user, setUser] = useState({})
  const [stats, setStats] = useState([])
  const [activitiesStatus, setActivitiesStatus] = useState([])
  const [sequelColumnTreeData, setSequelColumnTreeData] = useState([])
  const [sequelWhereTreeData, setSequelWhereTreeData] = useState({
    whereClauseTreeData: [],
    selectedColumns: [],
  })
  const [showUniqueWhereClauseAlert, setShowUniqueWhereClauseAlert] =
    useState(false)
  const { fileName, filePath } = useFileHandlingContext()

  useEffect(() => {
    let isMounted = true
    getStats()
    getMonthlyStats()
    getUserActivities()
    getAllActivities()
    isAddon && getEnvironmentalVariables()
    const intervalCall = setInterval(() => {
      if (isMounted) {
        getStats()
      }
    }, 60000)
    return () => {
      // clean up
      isMounted = false
      clearInterval(intervalCall)
    }
  }, [])

  useEffect(() => {
    let isMounted = true
    if (configuration && isMounted) {
      getSelectedActivity()
    }
    return () => {
      isMounted = false
    }
  }, [configuration])

  useEffect(() => {
    let objInputCount = 0
    let objOutputCount = 0
    configurationData?.activity?.requiredInputs.map(requiredInput => {
      if (
        userInputs[requiredInput.Key] &&
        requiredInput.isRequired &&
        userInputs["columns"] !== "*"
      ) {
        objInputCount++
      }
    })
    configurationData?.activity?.requiredOutputs.map(requiredOutput => {
      if (userOutputs[requiredOutput.Key] && requiredOutput.isRequired) {
        objOutputCount++
      }
    })

    if (
      objInputCount === configurationData.inputCount &&
      objOutputCount === configurationData.outputCount
    ) {
      setIsButtonSave(true)
    } else {
      setIsButtonSave(false)
    }
  }, [userInputs, userOutputs, configuration])

  const getStats = async () => {
    try {
      const {
        data: { stats },
      } = await axiosInstance.get(getUserStats())
      setUser(stats)
    } catch (err) {
      console.log("Fetch user profile api failed: ", err)
    }
  }

  const getUserActivities = async () => {
    try {
      const { data } = await axiosInstance.get(fetchUserActivities())
      setActivitiesStatus(
        data?.activities?.map(() => {
          return { isPausingOrResuming: false, isStopping: false }
        })
      )
      setUserActivities(data.activities)
      setIsSubscribed(data.isSubscribed)
      setIsActivityAllow(data.isAllowActivitiy)
      disableUserActivityLoading()
    } catch (err) {
      console.log("get user activities api failed: ", err)
      disableUserActivityLoading()
    }
  }

  const getAllActivities = async () => {
    try {
      const { data } = await axiosInstance.get(fetchAllActivities())
      const conversions = data
        .map(item => {
          if (CONFIGS.includes(item?.name)) return item
        })
        .filter(item => item !== undefined)
      setAllActivities(conversions)
      setConfiguration(conversions[0]?.name)
      disableAllActivitiesLoading()
    } catch (err) {
      console.log("get all activities api failed: ", err)
      disableAllActivitiesLoading()
    }
  }
  const getEnvironmentalVariables = async () => {
    try {
      const { data } = await axiosInstance.get(fetchEnvVariables())
      setUserEnv(data)
    } catch (err) {
      console.log("get environment variable error: ", err)
    }
  }

  const getSelectedActivity = async () => {
    try {
      const { data } = await axiosInstance.get(
        fetchSelectedActivity(configuration)
      )
      setConfigurationData(data)
    } catch (err) {
      console.log("get selected activity api failed: ", err)
    }
  }

  const handleSave = async () => {
    try {
      setShowConfirmDialog(false)
      const {
        data: { isAllowActivitiy },
      } = await axiosInstance.post(createUserActivityURL(), {
        configuration: configuration,
        userInputs: userInputs,
        userOutputs: userOutputs,
        fileName,
        filePath,
      })
      if (isAllowActivitiy) {
        setIsActivityAllow(false)
        setIsSaved(false)
        return null
      }
      tog_standard()
      window.location.reload(false)
      setIsSaved(false)
      setResponseMsg("Operation done successfully")
    } catch (err) {
      console.log("handleSaved api failed: ", err)
      if (err.status === 401) {
        enableAlert({
          visible: true,
          color: "danger",
          message:
            "Your session is timedout. Please refresh your page to continue your session",
        })
      } else {
        enableAlert({
          visible: true,
          color: "danger",
          message: err.response.data.message,
        })
      }
      setIsSaved(false)
    }
  }

  const onSaveAndRun = async () => {
    try {
      setIsSaved(true)
      let response = CONFIGS.includes(configuration)
        ? await handleConfirmBeforeStart()
        : false
      if (response && !response.isAllowedActivities) {
        setIsActivityAllow(false)
        return null
      }
      if (response?.showConfirmDialog) {
        setShowConfirmDialog(true)
      } else {
        setShowConfirmDialog(false)
        handleSave()
      }
    } catch (err) {
      console.log("handle save api failed: ", err)
    }
  }

  const onCancel = () => {
    setShowConfirmDialog("")
    setIsSaved(false)
    return null
  }

  const handleConfirmBeforeStart = async () => {
    const {
      data: { showConfirmDialog, isAllowedActivities },
    } = await axiosInstance.post(fetchTotalProcessedCount(), {
      configuration: configuration,
      userInputs: userInputs,
    })
    return { showConfirmDialog, isAllowedActivities }
  }

  const changeLoadingStatus = ({ index, loaderLabel, loaderCurrentValue }) => {
    setActivitiesStatus(prevStatus =>
      [...prevStatus]?.map((status, i) => {
        if (index === i) {
          return {
            ...status,
            [loaderLabel]: loaderCurrentValue,
          }
        }
        return status
      })
    )
  }

  const handleStatusUpdate = async ({
    activityId,
    status,
    index,
    loaderLabel,
  }) => {
    try {
      changeLoadingStatus({ index, loaderLabel, loaderCurrentValue: true })
      const {
        data: { message },
      } = await axiosInstance.post(updateActivityStatus(), {
        activityId: activityId,
        status: status,
      })
      tog_standard()
      if (!message) {
        setResponseMsg(
          `Operation ${
            ["stopped", "paused", "resumed"].includes(status.toLowerCase()) &&
            status.toLowerCase()
          } successfully`
        )
        setTimeout(() => {
          window.location.reload(false)
        }, 3000)
        return null
      }
      status === "Resumed" && setResponseMsg(message)
      changeLoadingStatus({ index, loaderLabel, loaderCurrentValue: false })
    } catch (err) {
      console.log("handle status update api failed: ", err)
      changeLoadingStatus({ index, loaderLabel, loaderCurrentValue: false })
    }
  }

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const tog_standard = () => {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }

  const _ToggleProcedureModal = activity => {
    setSelectedActivityProcedure(activity)
    setShowProcedureModal(!showProcedureModal)
  }

  const gotoActivityLogs = childWorkflowId => {
    history.push(`/activity-logs/${childWorkflowId}`)
  }

  const getMonthlyStats = async () => {
    try {
      const {
        data: { stats },
      } = await axiosInstance.get(fetchMonthlyStats())
      setStats(stats)
    } catch (err) {
      console.log("monthly stats api failed: ", err)
    }
  }

  return {
    disableAlert,
    enableAlert,
    alert,
    userActivityLoading,
    allActivitiesLoading,
    isSaved,
    modal_standard,
    isButtonSave,
    userActivities,
    allActivities,
    userEnv,
    isActivityAllow,
    configuration,
    configurationData,
    userInputs,
    userOutputs,
    showProcedureModal,
    selectedActivityProcedure,
    responseMsg,
    user,
    stats,
    activitiesStatus,
    isSubscribed,
    showConfirmDialog,
    setConfiguration,
    setUserInputs,
    setUserOutputs,
    onSaveAndRun,
    handleSave,
    onCancel,
    gotoActivityLogs,
    _ToggleProcedureModal,
    handleStatusUpdate,
    tog_standard,
    setmodal_standard,
    setSequelColumnTreeData,
    sequelColumnTreeData,
    setSequelWhereTreeData,
    sequelWhereTreeData,
    setShowUniqueWhereClauseAlert,
    showUniqueWhereClauseAlert,
  }
}

export default UseDashboard
