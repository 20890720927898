/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Button } from "reactstrap"
import { compact } from "lodash"

import { AlertBar, Spinner } from "components"
import { ActivitiesEnum, InputActivitiesEnum } from "utils/enums"

import ConversionSelector from "./conversionSelector"
import RequiredOutputsForm from "./requiredOutputsForms"
import RequiredInputsForm from "./requiredInputsForms"

const ConversionForm = ({
  setConfiguration,
  allActivities,
  configuration,
  configurationData,
  setUserInputs,
  userInputs,
  userOutputs,
  setUserOutputs,
  handleSave,
  isSaved,
  isButtonSave,
  setSequelColumnTreeData,
  setSequelWhereTreeData,
  sequelColumnTreeData,
  sequelWhereTreeData,
  showUniqueWhereClauseAlert,
  setShowUniqueWhereClauseAlert,
}) => {
  const [isDisabled, setIsDisabled] = useState(true)

  const shouldDisable = () => {
    if (!configuration) return true
    let isWhereClauseColumnUnique = true
    const [inputTable, , outputTable] = configuration?.split(" ")
    let inputLength = 0,
      outputLength = 0,
      inputWhereLength = 0

    if (
      [
        InputActivitiesEnum.Bigquery,
        InputActivitiesEnum.MySQL,
        InputActivitiesEnum.Postgres,
        InputActivitiesEnum.Redshift,
        InputActivitiesEnum.Snowflake,
      ].includes(inputTable)
    ) {
      inputLength = compact(userInputs?.table?.split(",")).length
      inputWhereLength = compact(userInputs?.where).length
      let iteration = 0
      while (
        //* checking if all the "id" number/integer columns in where clause are unique, primaryKey //
        iteration < userInputs?.where?.length &&
        isWhereClauseColumnUnique
      ) {
        isWhereClauseColumnUnique =
          ["int", "integer", "number", "mediumint"].includes(
            userInputs.where[iteration].type
          ) &&
          !userInputs.where[iteration].isPrimaryKey &&
          !userInputs.where[iteration].isUnique
            ? false
            : true
        iteration++
      }
      setShowUniqueWhereClauseAlert(!isWhereClauseColumnUnique)
    }
    if (
      [
        InputActivitiesEnum.Bigquery,
        InputActivitiesEnum.MySQL,
        InputActivitiesEnum.Postgres,
        InputActivitiesEnum.Redshift,
        InputActivitiesEnum.Snowflake,
      ].includes(outputTable)
    ) {
      const splittedValue =
        typeof userOutputs?.table === "string"
          ? userOutputs.table.split(",")
          : compact(userOutputs?.table)
      outputLength = splittedValue.length
    }
    if (outputTable === InputActivitiesEnum.MongoDB) {
      const splittedValue =
        typeof userOutputs?.collection === "string"
          ? userOutputs.collection.split(",")
          : compact(userOutputs?.collection)
      outputLength = splittedValue.length
    }
    if (inputTable === InputActivitiesEnum.MongoDB) {
      const splittedValue =
        typeof userInputs?.collection === "string"
          ? userInputs.collection.split(",")
          : compact(userInputs?.collection)
      inputLength = splittedValue.length
      inputWhereLength = splittedValue.length
    }
    if (
      [
        ActivitiesEnum.KafkaToKafka,
        ActivitiesEnum.KafkaToPostgres,
        ActivitiesEnum.RedisToPostgres,
        ActivitiesEnum.RedisToRedis,
      ].includes(configuration)
    ) {
      return false
    }
    return inputLength === outputLength &&
      inputLength === inputWhereLength &&
      inputWhereLength === outputLength
      ? false
      : true
  }
  useEffect(() => {
    let isMounted = true
    isMounted && setIsDisabled(prevState => (prevState = shouldDisable()))
    return () => {
      isMounted = false
    }
  }, [configuration, userInputs, userOutputs])

  return (
    <div className="pb-3">
      <ConversionSelector
        setConfiguration={setConfiguration}
        allActivities={allActivities}
        configuration={configuration}
        setUserInputs={setUserInputs}
        setUserOutputs={setUserOutputs}
      />
      <RequiredInputsForm
        configuration={configuration}
        configurationData={configurationData}
        setUserInputs={setUserInputs}
        setUserOutputs={setUserOutputs}
        userInputs={userInputs}
        userOutputs={userOutputs}
        sequelColumnTreeData={sequelColumnTreeData}
        sequelWhereTreeData={sequelWhereTreeData}
        setSequelColumnTreeData={setSequelColumnTreeData}
        setSequelWhereTreeData={setSequelWhereTreeData}
      />
      <RequiredOutputsForm
        configuration={configuration}
        configurationData={configurationData}
        setUserOutputs={setUserOutputs}
        userOutputs={userOutputs}
        userInputs={userInputs}
        sequelColumnTreeData={sequelColumnTreeData}
      />
      <AlertBar color="warning" showAlert={showUniqueWhereClauseAlert}>
        <div className="d-flex flex-column">
          <p className="my-2">Note:</p>
          <ul>
            <li>
              While selecting the columns from the where clause field we are
              relying on &quot;id&quot; and &quot;timestamp-based&quot;
              workflows where &quot;id&quot; should be <strong>unique</strong>{" "}
              and <strong>auto-incremented</strong> in order for a smooth
              migration of workflows.
            </li>
          </ul>
        </div>
      </AlertBar>
      <Button
        color="primary"
        onClick={handleSave}
        disabled={isDisabled || !isButtonSave ? true : isSaved ? true : false}
        className="d-flex align-items-center"
      >
        Save & Run {isSaved && <Spinner />}
      </Button>
    </div>
  )
}

export default ConversionForm
