import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const PostgresToPostgresBlog = () => {
  const data = tags.find(items => items.blogName === "postgres")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        Data is the backbone of modern businesses, and efficient data movement
        between different databases is crucial. PostgreSQL, a robust open-source
        relational database, offers powerful features for structured data
        storage. When it comes to migrating or replicating data within the
        PostgreSQL ecosystem, the process can be optimized for efficiency and
        accuracy. In this blog, we will delve into the process of transferring
        and replicating data from one PostgreSQL database to another, ensuring
        seamless integration and consistency.
      </p>
      <h4>Postgres</h4>
      <p>
        PostgreSQL, often referred to as Postgres, is known for its ACID
        compliance, extensibility, and support for advanced SQL features.
        It&lsquo;s suitable for a wide range of applications, from small-scale
        projects to large-scale enterprise systems.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source framework for building, testing, and
        deploying distributed systems. It provides developers with an easy way
        to manage workflows, maintain states, and handle errors. Temporal is
        particularly useful for managing long-running processes and complex,
        multi-step workflows.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos, on the other hand, is a powerful data streaming tool that can
        be used to connect various data sources and sinks. It provides a lot of
        flexibility in terms of data processing, transformation, and routing.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on the dropdown and select your desired conversion. In this
            case, we are converting from Postgres to Postgres.
          </p>
          <img
            src="/assets/images/postgres/postgres-to-postgres/snip-1.png"
            alt="Postgres to postgres conversion select"
          />
        </li>
        <br />
        <li>
          <b>Validate the DB URL</b>
          <p>
            In this step, we will validate the URL of our Postgres database.
            Enter the URL of the database and click on validate button.
          </p>
          <img src="/assets/images/postgres/postgres-to-postgres/snip-2.png" />
        </li>
        <br />
        <li>
          <b>Select Columns and WHERE clause</b>
          <p>
            Select the Columns from the tables shown below and then choose the
            WHERE clause.
          </p>
          <img src="/assets/images/postgres/postgres-to-postgres/snip-3.png" />
        </li>
        <br />
        <li>
          <b>Validate Output DB URL</b>
          <p>
            In this step, we will validate the URL of the output Postgres
            database. Enter the URL of the database and click on validate
            button.
          </p>
          <img src="/assets/images/postgres/postgres-to-postgres/snip-4.png" />
        </li>
        <br />
        <li>
          <b>Select output table</b>
          <p>
            Select from either already created tables or choose Dynamic tables.
            Let&lsquo;s assume we are creating a new table, so we need to write
            the new table name.
          </p>
          <img src="/assets/images/postgres/postgres-to-postgres/snip-5.png" />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>Now we need to run the migration by clicking on Save & Run</p>
          <img src="/assets/images/postgres/postgres-to-postgres/snip-6.png" />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/postgres/postgres-to-postgres/activity.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Activity Logs</b>
          <p>These are the activity logs.</p>
          <img
            src="/assets/images/postgres/postgres-to-postgres/logs.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default PostgresToPostgresBlog
