import React, { useState } from "react"

const UseStatus = () => {
  const [error, setError] = useState(null)

  const _SetError = errorValue => {
    setError(errorValue)
  }

  return { error, _SetError }
}

export default UseStatus
