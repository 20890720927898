import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const KafkaToKafkaBlog = () => {
  const data = tags.find(items => items.blogName === "kafka")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta property="og:locale" content={data.og_locale} />
        <meta property="og:description" content={data.og_description} />
        <meta property="og:site_name" content={data.og_site_name} />
        <meta property="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        In today&lsquo;s data-driven world, efficiently moving and processing
        data is crucial for businesses to gain valuable insights and make
        informed decisions. Apache Kafka has emerged as a powerful distributed
        streaming platform, offering real-time data streaming capabilities. When
        it comes to building robust data pipelines, combining Kafka with tools
        like Benthos and Temporal can provide a seamless and reliable solution.
      </p>
      <h4>Apache Kafka</h4>
      <p>
        Apache Kafka is an open-source stream processing platform designed for
        handling high-throughput, fault-tolerant, and scalable data streaming.
        It is built around the publish-subscribe model, where producers publish
        data to topics, and consumers subscribe to these topics to receive and
        process the data in real time.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source framework for building, testing, and
        deploying distributed systems. It provides developers with an easy way
        to manage workflows, maintain states, and handle errors. Temporal is
        particularly useful for managing long-running processes and complex,
        multi-step workflows.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos, on the other hand, is a powerful data streaming tool that can
        be used to connect various data sources and sinks. It provides a lot of
        flexibility in terms of data processing, transformation, and routing.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on the dropdown and select your desired conversion. In this
            case, we are converting from kafka to kafka.
          </p>
          <img
            src="/assets/images/kafka/kafka-to-kafka/snip-1.png"
            alt="kafka to kafka conversion select"
          />
        </li>
        <br />
        <li>
          <b>Validate the Server URL</b>
          <p>
            In this step, we will validate the URL of server. Enter the URL of
            the server and click on validate button.
          </p>
          <img src="/assets/images/kafka/kafka-to-kafka/snip-2.png" />
        </li>
        <br />
        <li>
          <b>Enter Topic-Name and Consumer Group name</b>
          <p>Enter the your kafka topic name and consumer group.</p>
          <img src="/assets/images/kafka/kafka-to-kafka/snip-3.png" />
        </li>
        <br />
        <li>
          <b>Validate Output Server URL</b>
          <p>
            In this step, we will validate the URL of the output server. Enter
            the URL of the output server and click on validate button.
          </p>
          <img src="/assets/images/kafka/kafka-to-kafka/snip-4.png" />
        </li>
        <br />
        <li>
          <b>Enter TopicName</b>
          <p>
            In this step, we need to write the topic name of the other server.
          </p>
          <img src="/assets/images/kafka/kafka-to-kafka/snip-5.png" />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>Now we need to run the migration by clicking on Save & Run</p>
          <img src="/assets/images/kafka/kafka-to-kafka/snip-6.png" />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/kafka/kafka-to-kafka/activity.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Activity Logs</b>
          <p>These are the activity logs.</p>
          <img
            src="/assets/images/kafka/kafka-to-kafka/logs.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default KafkaToKafkaBlog
