import React from "react"
import { Helmet } from "react-helmet-async"

import BannerSection from "./BannerSection"
import FeatureSection from "./FeatureSection"
import Footer from "./Footer"

import { ENUMS } from "utils/enums"

const SelfHostedDatabashiPoweredByBenthos = () => {
  return (
    <>
      <Helmet>
        <title>
          Self-Hosted-Databashi-Powered-By-Benthos | {ENUMS.BRAND_NAME}
        </title>
      </Helmet>
      <BannerSection />
      <FeatureSection />
      <Footer />
    </>
  )
}
export default SelfHostedDatabashiPoweredByBenthos
