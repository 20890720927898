import React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { Controller } from "react-hook-form"
import { Col, Container, Input, Label, Row } from "reactstrap"

import maintanence from "assets/images/coming-soon.svg"

import useContact from "./useContact"

import { AlertBar, Spinner } from "components"
import StyledHeader from "components/StyledHeader"

const ContactSection = () => {
  const {
    captchaRef,
    control,
    isLoading,
    alert,
    disableAlert,
    onSubmit,
    handleSubmit,
    handleExpireCaptcha,
  } = useContact()

  return (
    <section
      id="contact-us"
      className="bg-light d-flex flex-column align-items-center mb-5 py-3"
    >
      <StyledHeader name={"Contact us"} />
      <Container>
        <Row>
          <Col md="6">
            <div className="d-flex flex-column justify-content-center gap-3 px-4">
              <AlertBar
                color={alert?.color}
                disableAlert={disableAlert}
                showAlert={alert?.visible}
              >
                {alert?.message}
              </AlertBar>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <Label htmlFor="formrow-name-Input">Full Name</Label>
                  <Controller
                    name="fullName"
                    control={control}
                    render={({ field, fieldState: { isTouched, error } }) => (
                      <>
                        <Input
                          type="text"
                          className={`form-control ${
                            isTouched && error
                              ? "is-invalid"
                              : isTouched && !error
                              ? "is-valid"
                              : ""
                          }`}
                          id="formrow-name-Input"
                          placeholder="Enter Your Full Name"
                          {...field}
                        />
                        {error && (
                          <p className="text-danger">{error.message}</p>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="mb-3">
                  <Label htmlFor="formrow-email-Input">Email</Label>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState: { isTouched, error } }) => (
                      <>
                        <Input
                          type="text"
                          className={`form-control ${
                            isTouched && error
                              ? "is-invalid"
                              : isTouched && !error
                              ? "is-valid"
                              : ""
                          }`}
                          id="formrow-name-Input"
                          placeholder="Enter Your Email Address"
                          {...field}
                        />
                        {error && (
                          <p className="text-danger">{error.message}</p>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="mb-3">
                  <Label htmlFor="formrow-message-Input">Message</Label>
                  <Controller
                    name="message"
                    control={control}
                    render={({ field, fieldState: { isTouched, error } }) => (
                      <>
                        <textarea
                          type="textarea"
                          rows={4}
                          className={`form-control ${
                            isTouched && error
                              ? "is-invalid"
                              : isTouched && !error
                              ? "is-valid"
                              : ""
                          }`}
                          id="formrow-message-Input"
                          placeholder="Enter Your Message"
                          {...field}
                        />
                        {error && (
                          <p className="text-danger">{error.message}</p>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="mb-3">
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={REACT_APP_CAPTCHA_SITE_KEY}
                    onExpired={handleExpireCaptcha}
                  />
                </div>
                <button
                  type="submit"
                  className="d-flex align-items-center justify-content-center btn btn-sm btn-primary rounded-pill mb-3"
                  style={{ minWidth: "100px" }}
                  disabled={isLoading}
                >
                  Submit {isLoading && <Spinner />}
                </button>
              </form>
            </div>
          </Col>
          <Col md="6" className="d-none d-md-block">
            <div className="maintenance-img">
              <img
                src={maintanence}
                alt="bannerImg"
                width="70%"
                className="img-fluid mx-auto d-block"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ContactSection
