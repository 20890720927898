/* eslint-disable react/prop-types */
import React from "react"
import { Table } from "reactstrap"
import moment from "moment"

import { useAuthContext } from "context/authContextWrapper"
import UpcomingInvoiceSkeleton from "./UpcomingInvoiceSkeleton"

const UpcomingInvoice = ({
  isLoading,
  upcomingInvoice,
  upcomingInvoice: {
    period_end = "",
    period_start = "",
    lines = {},
    subtotal = 0,
    total = 0,
    amount_due = 0,
  } = {},
}) => {
  const { user: { plan = {} } = {} } = useAuthContext()

  return (
    <div className="p-3 w-100">
      <h4 className="card-title">Upcoming Invoice</h4>
      {!isLoading && !upcomingInvoice ? (
        <div className="d-flex align-items-center justify-content-center">
          <p>No upcoming invoice available</p>
        </div>
      ) : (
        <>
          <p className="text-muted">
            This is a preview of the invoice that will be billed on{" "}
            <span className="text-black Text-fontWeight--600">
              {moment(period_start * 1000)
                .add(1, "M")
                .format("MMM DD")}
            </span>
            . It may change if the subscription is updated.
          </p>
          {isLoading ? (
            <UpcomingInvoiceSkeleton />
          ) : (
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th className="text-uppercase">description</th>
                    <th className="text-uppercase text-end">qty</th>
                    <th className="text-uppercase text-end">unit price</th>
                    <th className="text-uppercase text-end">amount</th>
                  </tr>
                </thead>
                <tbody>
                  {lines?.data?.map(item => (
                    <tr key={item.id}>
                      <td>{plan?.name}</td>
                      <td className="text-end">{item?.quantity}</td>
                      <td className="text-end">
                        ${(item?.price?.unit_amount / 100)?.toFixed(2)}
                      </td>
                      <td className="text-end">
                        ${(item?.amount / 100)?.toFixed(2)}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="text-end">Subtotal</td>
                    <td className="text-end">${(subtotal / 100).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td className="text-end">Total</td>
                    <td className="text-end">${(total / 100).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="text-end">
                      <div className="d-flex flex-column">
                        <p className="p-0 m-0">Amount due</p>
                        <p className="p-0 m-0 text-muted sub-text">
                          {" "}
                          {moment(period_start * 1000)
                            .add(1, "M")
                            .format("MMM DD")}{" "}
                          -{" "}
                          {moment(period_end * 1000)
                            .add(1, "M")
                            .format("MMM DD, YYYY")}
                        </p>
                      </div>
                    </td>
                    <td className="text-end">
                      <span className="fs-1">
                        <sup className="text-muted Text-fontWeight--600 fs-4">
                          $
                        </sup>
                        {(amount_due / 100).toFixed(2)}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default UpcomingInvoice
