import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"

import { useAuthContext } from "context/authContextWrapper"
import useLoading from "hooks/useLoading"
import { AlertBar } from "components"

import InvoiceSection from "./InvoiceSection"
import CreateSubscription from "./CreateSubscription"
import PlanDetailsSection from "./PlanDetailsSection"
import PaymentMethodSection from "./PaymentMethodSection"
import CancelSubscriptionSection from "./UnsubscribeSection"

import { getSubscriptionURL } from "apis/stripe"
import axiosInstance from "services/axiosInstance"

const SubscriptionSection = () => {
  const { user } = useAuthContext()
  const { isLoading, disableLoading } = useLoading(true)
  const [details, setDetails] = useState({})
  const [showCancelSubscriptionCard, setShowCancelSubscriptionCard] =
    useState(false)

  useEffect(() => {
    if (user) {
      user?.plan && fetSubscriptionDetails()
    }
  }, [user])

  const fetSubscriptionDetails = async () => {
    try {
      const { data } = await axiosInstance.get(getSubscriptionURL())
      setDetails(data)
      disableLoading()
    } catch (err) {
      console.log("failed to fetch subscription: ", err)
      disableLoading()
    }
  }

  const toggleUnsubscribeCard = () =>
    setShowCancelSubscriptionCard(prevState => !prevState)

  return (
    <>
      {user?.additionalInfo?.errorLog && (
        <AlertBar color="warning" showAlert={user?.additionalInfo?.errorLog}>
          <i className="mdi mdi-alert-outline me-2 fs-4"></i>
          {user?.additionalInfo?.errorLog}
          <p className="divider line one-line Text-fontWeight--600">NOTE</p>
          <ul>
            <li>
              After 4 consecutive failed attempts your subscription will be
              cancelled
            </li>
            <li>
              Change your payment method from &quot;Payment Methods&quot;
              section for an auto recharge.
            </li>
          </ul>
        </AlertBar>
      )}
      {!user?.plan ? (
        <CreateSubscription />
      ) : (
        <>
          <Row>
            {showCancelSubscriptionCard && (
              <Col xs="12">
                <CancelSubscriptionSection
                  {...details}
                  showAlert={showCancelSubscriptionCard}
                  closeAlert={toggleUnsubscribeCard}
                />
              </Col>
            )}
            <Col xs="12">
              <PlanDetailsSection
                details={details}
                isLoading={isLoading}
                showCancelSubscriptionCard={showCancelSubscriptionCard}
                toggleUnsubscribeCard={toggleUnsubscribeCard}
              />
            </Col>
          </Row>
          <Row>
            <Col md="8" lg="8">
              <InvoiceSection />
            </Col>
            <Col md="4" lg="4">
              <PaymentMethodSection />
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default SubscriptionSection
