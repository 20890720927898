import React from "react"
import PropTypes from "prop-types"

import "./switch.scss"

const Switch = ({ value, handleToggle }) => {
  return (
    <>
      <div className="button r" id="button-2">
        <input
          type="checkbox"
          className="checkbox"
          checked={value}
          onChange={handleToggle}
        />
        <div className="knobs"></div>
        <div className="layer"></div>
      </div>
    </>
  )
}

Switch.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
}

export default Switch
