/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types"

import { Modal } from "components"

import ActivityInfoTable from "./activityInfoTable"
import ActivityInputTable from "./activityInputTable"
import ActivityOutputTable from "./activityOutputTable"

const ActivityProcedureModalWrapper = ({
  modalTitle,
  selectedActivityProcedure,
  showProcedureModal,
  _ToggleProcedureModal,
}) => {
  const {
    activity = {},
    userInputs = {},
    userOutputs = {},
  } = selectedActivityProcedure

  return (
    <Modal
      isOpen={showProcedureModal}
      openModal={_ToggleProcedureModal}
      closeModal={_ToggleProcedureModal}
      modalTitle={modalTitle}
      closeText="Close"
      size="lg"
    >
      <>
        <div className="container">
          <ActivityInfoTable {...activity} />
          <h4>User Inputs</h4>
          <ActivityInputTable
            data={userInputs}
            inputName={activity?.inputName}
          />
          <h4>User Outputs</h4>
          <ActivityOutputTable
            data={userOutputs}
            outputName={activity?.outputName}
          />
        </div>
      </>
    </Modal>
  )
}

ActivityProcedureModalWrapper.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  selectedActivityProcedure: PropTypes.object.isRequired,
  showProcedureModal: PropTypes.bool.isRequired,
  _ToggleProcedureModal: PropTypes.func.isRequired,
}

export default ActivityProcedureModalWrapper
