import React from "react"
import { Container } from "reactstrap"

import { ENUMS } from "utils/enums"

import DatabashiLogo from "assets/images/databashi-logo.png"
import "./BannerSection.scss"

const BannerSection = () => {
  return (
    <Container
      fluid
      className="position-relative d-flex justify-content-center align-items-center self-hosted-hero-container"
    >
      <div className="w-100 top-0 position-absolute">
        <img
          src={DatabashiLogo}
          alt="DataBashi - The Brand logo"
          className="brandLogo"
        />
      </div>
      <Container className="d-flex flex-column gap-3 text-center">
        <h1 className="banner-heading">Welcome to {ENUMS.BRAND_NAME}!</h1>
        <p className="banner-text">
          The ultimate data migration and ETL solution built using the powerful
          open-source tool, Benthos. With our platform, you can easily transfer
          and transform data from various databases including{" "}
          <span className="Text-fontWeight--700">
            Postgres, Redis, MySQL, MongoDB and more.
          </span>
        </p>
        <p className="banner-text Text-fontWeight--700">
          Now available as a self-hosted version (with access to source code).
        </p>
      </Container>
    </Container>
  )
}

export default React.memo(BannerSection)
