import React from "react"
import { isEmpty } from "lodash"
import Pagination from "react-responsive-pagination"
import { Button, Table } from "reactstrap"

import useActivityLogs from "./useActivityLogs"

import "./activitylogs.scss"
import ActivityLogsSkeleton from "./activityLogsSkeleton"

const Index = () => {
  const {
    getStatusStyle,
    activityLogs,
    isLoading,
    pagination,
    handleRefresh,
    handleExportCSV,
    handlePagination,
  } = useActivityLogs()

  return (
    <div className="page-content">
      <div className="container">
        {isLoading ? (
          <ActivityLogsSkeleton />
        ) : isEmpty(activityLogs) ? (
          <div className="w-100 min-vh-100 d-flex flex-column justify-content-center align-items-center">
            <p>No activity logs available</p>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-end gap-5">
              {REACT_APP_IS_DOCKERIZED === true && (
                <Button
                  onClick={handleExportCSV}
                  color="primary"
                  size="sm"
                  className="mb-2 px-4"
                >
                  Export CSV
                </Button>
              )}
              <Button
                onClick={handleRefresh}
                color="primary"
                size="sm"
                className="mb-2 px-4"
              >
                Refresh
              </Button>
            </div>
            <div
              className="col mb-3 logs-container"
              style={{ height: "100%", overflowY: "auto" }}
            >
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ minWidth: "300px" }}>UUID</th>
                    <th style={{ minWidth: "120px" }}>STATUS</th>
                    <th style={{ minWidth: "300px" }}>DETAILS</th>
                    <th style={{ minWidth: "300px" }}>DATETIME</th>
                  </tr>
                </thead>
                <tbody>
                  {activityLogs.map(
                    ({ _id, uuid, status, details, datetime }) => (
                      <tr key={_id}>
                        <td className={`${getStatusStyle(status)}`}>{uuid}</td>
                        <td className={`${getStatusStyle(status)}`}>
                          {status}
                        </td>
                        <td className={`${getStatusStyle(status)}`}>
                          {details}
                        </td>
                        <td className={`${getStatusStyle(status)}`}>
                          {datetime}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
            <div
              className="d-flex align-items-end justify-content-end my-3 ms-auto"
              style={{ maxWidth: "50vw" }}
            >
              <Pagination
                current={pagination.currentPage + 1}
                total={pagination.totalPages}
                onPageChange={handlePagination}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Index
