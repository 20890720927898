export const getInputClasses = (errors, touchedFields, fieldname) => {
  if (touchedFields[fieldname] && errors[fieldname]) {
    return "is-invalid"
  }
  if (touchedFields[fieldname] && !errors[fieldname]) {
    return "is-valid"
  }
  return ""
}

export const tableDropdownOptions = tables => {
  const options = [
    { value: "", label: "Select Table", isDisabled: true },
    { value: "all", label: "Select All" },
  ]
  if (tables) {
    tables.map(table => options.push({ value: table, label: table }))
  }
  return options
}

export const databaseDropdownOptions = databases => {
  const options = [{ value: "", label: "Select Database", isDisabled: true }]
  if (databases) {
    databases.map(database =>
      options.push({ value: database, label: database })
    )
  }
  return options
}

export const collectionDropdownOptions = collections => {
  const options = [
    { value: "", label: "Select Collection", isDisabled: true },
    { value: "all", label: "Select All" },
  ]
  if (collections) {
    collections.map(collection =>
      options.push({ value: collection, label: collection })
    )
  }
  return options
}

export const WHERE_CONDITIONS = [
  "createdAt",
  "created_at",
  "created_At",
  "updated_at",
  "updated_At",
  "updatedAt",
  "deletedAt",
  "deleted_At",
  "deleted_at",
  "created",
  "deleted",
  "updated",
]

export const removeDuplicateColumns = columns => {
  const distinctColumns = new Set()
  columns.map(({ children }) => {
    children.map(({ name: columnName }) => {
      distinctColumns.add(columnName)
    })
  })
  return distinctColumns
}
