import React, { useState, useEffect } from "react"
import { Card, CardBody, Input, Row, Col, Button } from "reactstrap"

import { useAuthContext } from "context/authContextWrapper"

import { CustomTooltip, Spinner } from "components"

import { generateKeyURL, revokeKeyURL } from "apis/auth"
import axiosInstance from "services/axiosInstance"

const DeveloperKeySection = () => {
  const { user, storeUser } = useAuthContext()
  const [copyToClipBoard, setCopyToClipboard] = useState(false)
  const [isLoading, setIsLoading] = useState({
    generate: false,
    revoke: false,
  })
  const [token, setToken] = useState("")

  useEffect(() => {
    setToken(
      prevToken =>
        (prevToken = user?.additionalInfo?.token
          ? user?.additionalInfo?.token
          : "")
    )
  }, [user])

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(token)
    setCopyToClipboard(true)
  }

  const generateToken = async () => {
    setIsLoading(prevState => ({ ...prevState, generate: true }))
    try {
      const {
        data: { accessKey },
      } = await axiosInstance.post(generateKeyURL(), {
        _id: user?.additionalInfo?._id,
      })
      setToken(accessKey)
      storeUser()
      setIsLoading(prevState => ({ ...prevState, generate: false }))
    } catch (err) {
      console.log("failed to generate token: ", err)
      setIsLoading(prevState => ({ ...prevState, generate: false }))
    }
  }

  const revokeToken = async () => {
    try {
      setIsLoading(prevState => ({ ...prevState, revoke: true }))
      await axiosInstance.post(revokeKeyURL(), {
        _id: user?.additionalInfo?._id,
      })
      setToken("")
      storeUser()
      setIsLoading(prevState => ({ ...prevState, revoke: false }))
    } catch (err) {
      console.log("failed to revoke token: ", err)
      setIsLoading(prevState => ({ ...prevState, revoke: false }))
    }
  }

  return (
    <Row>
      <Col md={6}>
        <Card>
          <CardBody>
            <div className="d-flex">
              <div className="me-2">
                <h5 className="card-title mb-4">API Access Key</h5>
              </div>
            </div>

            <div className="position-relative">
              <Input
                type={copyToClipBoard ? "text" : "password"}
                bsSize="lg"
                readOnly
                value={token}
                disabled={!copyToClipBoard}
                placeholder={token ? "xxxxxxxxxxxxxxxxxxxxxx" : ""}
              />
              {user?.additionalInfo?.token && (
                <span
                  onClick={handleCopyToClipboard}
                  className="position-absolute"
                  style={{
                    right: "9px",
                    bottom: "8px",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  <CustomTooltip
                    target="copyToClipboard"
                    description="copy to clipboard"
                  ></CustomTooltip>
                  <i id="copyToClipboard" className="bx bx-copy"></i>
                </span>
              )}
            </div>
            <div className="d-flex flex-row justify-content-end gap-2 mt-4">
              <Button
                size="sm"
                color="primary"
                onClick={generateToken}
                className="d-flex justify-content-center align-items-center"
              >
                {isLoading.generate ? (
                  <Spinner />
                ) : user?.additionalInfo?.token ? (
                  "Regenerate"
                ) : (
                  "Generate"
                )}
              </Button>
              <Button
                size="sm"
                color="danger"
                onClick={revokeToken}
                className="d-flex justify-content-center align-items-center"
              >
                Revoke {isLoading.revoke && <Spinner />}
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default DeveloperKeySection
