/* eslint-disable react/prop-types */
import moment from "moment"
import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"

import VisaCard from "assets/images/VISA.png"
import MasterCard from "assets/images/Mastercard.png"
import { ENUMS } from "utils/enums"

const PaymentMethodCard = ({
  card: {
    brand = "",
    exp_month = 1,
    exp_year = 1990,
    last4 = "",
    ...extra
  } = {},
  ...rest
}) => {
  return (
    <Card className="border rounded-full mt-3 bg-light shadow-sm">
      <CardBody>
        <Row>
          <Col xs="10">
            <Row>
              <Col>
                <p className="p-0 m-0 fs-6">
                  <span className="text-uppercase">{brand}</span> **** **** ****{" "}
                  <span className="text-info">{last4}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="p-0 m-0 text-muted">
                  Expires{" "}
                  {moment()
                    .month(exp_month - 1)
                    .format("MMM")}{" "}
                  {exp_year}
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs="2">
            <img
              src={brand === "visa" ? VisaCard : MasterCard}
              alt={ENUMS.BRAND_NAME}
              className="w-100 h-auto"
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default PaymentMethodCard
