import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const MongoDbToRedshiftBlog = () => {
  const data = tags.find(items => items.blogName === "mongodbRedshift")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        In the dynamic realm of data management, organizations often face the
        challenge of transitioning between databases to meet evolving business
        needs. This blog post explores a powerful strategy for migrating data
        from MongoDB to Amazon Redshift, leveraging the capabilities of Benthos
        for data streaming and Temporal for workflow orchestration. By combining
        these tools, you can ensure a seamless and reliable migration process,
        unlocking new possibilities for analytics and data warehousing.
      </p>
      <h4>MongoDB</h4>
      <p>
        MongoDB is a NoSQL database that stores data in flexible, JSON-like
        documents. It is designed for applications that require flexibility and
        scalability, making it ideal for projects with evolving data structures.
      </p>
      <h4>AWS Redshift</h4>
      <p>
        Amazon Redshift is a fully managed, petabyte-scale data warehouse
        service in the cloud. Amazon Redshift Serverless lets you access and
        analyze data without all of the configurations of a provisioned data
        warehouse. Resources are automatically provisioned and data warehouse
        capacity is intelligently scaled to deliver fast performance for even
        the most demanding and unpredictable workloads. Regardless of the size
        of the dataset, Amazon Redshift offers fast query performance using the
        same SQL-based tools and business intelligence applications that you use
        today.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source framework for building, testing, and
        deploying distributed systems. It provides developers with an easy way
        to manage workflows, maintain states, and handle errors. Temporal is
        particularly useful for managing long-running processes and complex,
        multi-step workflows.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos, on the other hand, is a powerful data streaming tool that can
        be used to connect various data sources and sinks. It provides a lot of
        flexibility in terms of data processing, transformation, and routing.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Input Conversion</b>
          <p>
            Click on the <b>Choose Input Conversion</b> dropdown and select{" "}
            <b>MongoDB</b>.
          </p>
          <img
            src="/assets/images/mongodb/mongodb-to-redshift/snip-1.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on the <b>Choose Conversion</b> dropdown and select{" "}
            <b>MongoDB To Redshift</b>.
          </p>
          <img
            src="/assets/images/mongodb/mongodb-to-redshift/snip-2.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Validate the DB URL</b>
          <p>
            Enter the URL of the MongoDB database and click on validate button.
          </p>
          <img
            src="/assets/images/mongodb/mongodb-to-redshift/snip-3.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select the Database</b>
          <p>
            Choose the database from the dropdown by clicking on the dropdown
            menu.
          </p>
          <img
            src="/assets/images/mongodb/mongodb-to-redshift/snip-4.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select Input Collection</b>
          <p>Select the collection from already created collections.</p>
          <img
            src="/assets/images/mongodb/mongodb-to-redshift/snip-5.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Validate Output DB URL</b>
          <p>
            Enter the URL of the AWS Redshift database and click on validate
            button.
          </p>
          <img
            src="/assets/images/mongodb/mongodb-to-redshift/snip-6.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select output Table</b>
          <p>
            Select the output table from the list. If you do not see the tables,
            kindly ensure that you have entered the correct database URL or
            verify that your database indeed contains tables.
          </p>
          <img
            src="/assets/images/mongodb/mongodb-to-redshift/snip-7.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>
            Now we need to run the migration by clicking on Save & Run button
          </p>
          <img
            src="/assets/images/mongodb/mongodb-to-redshift/snip-8.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/mongodb/mongodb-to-redshift/activity.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default MongoDbToRedshiftBlog
