import React, { useContext, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import Cookies from "js-cookie"

import { logoutURL } from "apis/auth"
import axiosInstance from "services/axiosInstance"
import { profile } from "apis/user"

const AuthContext = React.createContext(null)

const AuthContextWrapper = ({ children }) => {
  const history = useHistory()

  const isAddon =
    !REACT_APP_IS_DOCKERIZED && document.location.origin === HEROKU_DOMAIN

  const [user, setUser] = useState({})

  useEffect(() => {
    if (!isAddon && getAuthToken()) {
      storeUser()
    }
  }, [])

  const getProfile = async () => {
    try {
      const {
        data: { user: userData },
      } = await axiosInstance.get(profile())
      return userData
    } catch (err) {
      console.log("get Profile failed: ", err)
    }
  }

  const setAuthToken = token => {
    localStorage.setItem("databashi_auth_token", token)
  }

  const getAuthToken = () => {
    return localStorage.getItem("databashi_auth_token")
  }

  const storeUser = async () => {
    const userData = await getProfile()
    setUser(userData)
  }

  const getUser = () => {
    return user
  }

  const logout = async () => {
    try {
      await axiosInstance.post(logoutURL(), {})
      localStorage.clear()
      Cookies.remove("stripeMode")
      history.push("/auth/login")
    } catch (err) {
      console.log("logout api failed: ", err)
    }
  }

  const data = useMemo(
    () => ({
      user,
      isAddon,
      storeUser,
      getUser,
      logout,
      setAuthToken,
    }),
    [user]
  )

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>
}

AuthContextWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export const useAuthContext = () => {
  return useContext(AuthContext)
}
export default AuthContextWrapper
