import React from "react"
import { Card, CardBody, CardImg, CardText, CardTitle, Col } from "reactstrap"
import PropTypes from "prop-types"

import maintanence from "assets/images/coming-soon.svg"

import "./FeatureCard.scss"

const Feature1 = ({ title, description, image, alternate = "", list = [] }) => {
  return (
    <Col md={4}>
      <Card className="featureCard">
        <CardImg
          top
          className="img-fluid"
          src={image || maintanence}
          alt={alternate}
        />
        <CardBody>
          <CardTitle className="h4 mt-0">{title}</CardTitle>
          <CardText>{description}</CardText>
          {list && (
            <ul>
              {list.map(option => (
                <li key={option}>{option}</li>
              ))}
            </ul>
          )}
        </CardBody>
      </Card>
    </Col>
  )
}

Feature1.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alternate: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string),
}

export default Feature1
