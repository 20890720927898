import React from "react"
import { Button, Card, CardBody } from "reactstrap"

import { useAuthContext } from "context/authContextWrapper"

import { AlertBar, Modal, Spinner } from "components"
import PaymentMethodCard from "components/Cards/PaymentMethodCard"
import PaymentCardSkeleton from "components/Cards/PaymentMethodCard/PaymentCardSkeleton"

import StripeCardElement from "../PaymentForm/StripeCardElement"
import usePaymentMethod from "./usePaymentMethod"

import "./paymentMethod.scss"

const PaymentMethodSection = () => {
  const {
    complete,
    handleChange,
    isLoading,
    name,
    paymentMethods,
    setName,
    alert,
    showUpdateModal,
    fetchingCards,
    disableAlert,
    togglePaymentMethodModal,
    handleUpdatePaymentMethod,
  } = usePaymentMethod()

  const { user = {} } = useAuthContext()

  const ChangePaymentMethodBtn = (
    <button
      onClick={togglePaymentMethodModal}
      className={`d-flex flex-row btn btn-primary btn-sm ${
        user?.additionalInfo?.errorLog ? "paymentMethod-btn" : ""
      }`}
    >
      Update Payment Method
    </button>
  )

  return (
    <>
      <Card id="paymentMethod-section">
        <CardBody>
          <h4 className="card-title">Payment Methods</h4>
          {fetchingCards ? (
            <PaymentCardSkeleton />
          ) : (
            paymentMethods?.map(paymentMethod => (
              <PaymentMethodCard key={paymentMethod.id} {...paymentMethod} />
            ))
          )}
          <div className="d-flex flex-column w-100 align-items-center justify-content-center">
            {ChangePaymentMethodBtn}
          </div>
        </CardBody>
      </Card>

      <Modal
        closeModal={togglePaymentMethodModal}
        openModal={togglePaymentMethodModal}
        closeText="Close"
        modalTitle="Update Payment Method"
        isOpen={showUpdateModal}
      >
        <>
          <AlertBar
            color={alert?.color}
            disableAlert={disableAlert}
            showAlert={alert?.visible}
          >
            {alert?.message}
          </AlertBar>
          <form
            id="payment-form"
            onSubmit={event => handleUpdatePaymentMethod(event)}
          >
            <div className=" border shadow-md rounded p-3 bg-light">
              <div className="d-flex flex-column">
                <StripeCardElement
                  name={name}
                  setName={setName}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <Button
              color="primary"
              type="submit"
              disabled={
                isLoading
                  ? true
                  : !complete.cardCvc ||
                    !complete.cardExpiry ||
                    !complete.cardNumber
                  ? true
                  : false
              }
              className="d-flex mt-3 w-100 align-items-center justify-content-center"
            >
              {isLoading ? <Spinner /> : "Update Now"}
            </Button>
          </form>
        </>
      </Modal>
    </>
  )
}

export default PaymentMethodSection
