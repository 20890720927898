export const listPriceURL = () => `/rest/v1/stripe/all-prices`
export const createSubscriptionURL = () => `/rest/v1/stripe/create-subscription`
export const cancelSubscriptionURL = () => `/rest/v1/stripe/cancel-subscription`
export const updateSubscriptionURL = () => `/rest/v1/stripe/update-subscription`
export const getSubscriptionURL = () => `/rest/v1/stripe/subscription`
export const getPaymentMethodsURL = () => `rest/v1/stripe/payment-methods`
export const getInvoicesURL = (limit, starting_after) =>
  `rest/v1/stripe/customer-subscription-invoices?limit=${limit}&starting_after=${
    starting_after || ""
  }`
export const updatePaymentMethodsURL = () =>
  `rest/v1/stripe/update-payment-method`
export const updateStripeModeURL = () => `/rest/v1/stripe/update/stripe-mode`
