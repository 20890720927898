import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const PostgresToMongoDbBlog = () => {
  const data = tags.find(items => items.blogName === "postgresMongodb")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        In the diverse landscape of modern data management, combining the
        capabilities of different database systems is key to unlocking new
        possibilities. PostgreSQL, a powerful open-source relational database,
        and MongoDB, a flexible NoSQL document-oriented database, offer distinct
        advantages. By integrating PostgreSQL with MongoDB, businesses can
        harness the benefits of both structured and unstructured data storage.
        This blog explores the process of seamlessly connecting PostgreSQL to
        MongoDB for comprehensive data solutions.
      </p>
      <h4>MongoDB</h4>
      <p>
        MongoDB is designed for storing unstructured or semi-structured data in
        flexible JSON-like documents. It is particularly well-suited for
        applications with evolving data models and the need for fast, scalable
        reads and writes.
      </p>
      <h4>Postgres</h4>
      <p>
        PostgreSQL is one of the most widely used relational database management
        systems. As your business grows, you may need to migrate your data to a
        new PostgreSQL database. This process can be complicated, but using
        Temporal and Benthos on Node.js can simplify the process and make it
        more efficient.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source framework for building, testing, and
        deploying distributed systems. It provides developers with an easy way
        to manage workflows, maintain states, and handle errors. Temporal is
        particularly useful for managing long-running processes and complex,
        multi-step workflows.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos, on the other hand, is a powerful data streaming tool that can
        be used to connect various data sources and sinks. It provides a lot of
        flexibility in terms of data processing, transformation, and routing.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on the dropdown and select your desired conversion. In this
            case, we are converting from Postgres to MongoDB.
          </p>
          <img src="/assets/images/postgres/postgres-to-mongodb/snip-1.png" />
        </li>
        <br />
        <li>
          <b>Validate the DB URL</b>
          <p>
            In this step, we will validate the URL of Postgres database. Enter
            the URL of the database and click on validate button.
          </p>
          <img src="/assets/images/postgres/postgres-to-mongodb/snip-2.png" />
        </li>
        <br />
        <li>
          <b>Select Columns and WHERE clause</b>
          <p>
            Select the Columns from the tables shown below and then choose the
            WHERE clause.
          </p>
          <img src="/assets/images/postgres/postgres-to-mongodb/snip-3.png" />
        </li>
        <br />
        <li>
          <b>Validate Output DB URL</b>
          <p>
            In this step, we will validate the URL of the MongoDB database.
            Enter the URL of the database and click on validate button.
          </p>
          <img src="/assets/images/postgres/postgres-to-mongodb/snip-4.png" />
        </li>
        <br />
        <li>
          <b>Select the Database</b>
          <p>
            Choose the output database from the dropdown by clicking on the
            dropdown menu.
          </p>
          <img src="/assets/images/postgres/postgres-to-mongodb/snip-5.png" />
        </li>
        <br />
        <li>
          <b>Select output Collection</b>
          <p>
            Select from either already created collections or choose Dynamic
            Collections. Let&lsquo;s assume we are creating a new collection, so
            we need to write the new collection name.
          </p>
          <img src="/assets/images/postgres/postgres-to-mongodb/snip-6.png" />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>Now we need to run the migration by clicking on Save & Run</p>
          <img src="/assets/images/postgres/postgres-to-mongodb/snip-7.png" />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/postgres/postgres-to-mongodb/activity.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Activity Logs</b>
          <p>These are the activity logs.</p>
          <img
            src="/assets/images/postgres/postgres-to-mongodb/logs.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default PostgresToMongoDbBlog
