import React from "react"
import { Modal } from "reactstrap"
import PropTypes from "prop-types"

import { Spinner } from "components"

const Index = ({
  isOpen,
  closeModal,
  modalTitle,
  submitText,
  closeText,
  btnType,
  confirmBtn,
  customButton,
  handleSubmit,
  isLoading,
  children,
  size = "md",
}) => {
  return (
    <Modal size={size} isOpen={isOpen} toggle={closeModal} centered>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {modalTitle}
        </h5>
        <button
          type="button"
          onClick={closeModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        className="modal-body"
        style={{ maxHeight: "80vh", overflowY: "auto" }}
      >
        {children}
      </div>
      <div className="modal-footer">
        {confirmBtn &&
          (btnType === "button" ? (
            <button
              type="button"
              onClick={handleSubmit}
              className="d-flex align-items-center btn btn-primary btn-sm"
            >
              {submitText} &nbsp; {isLoading && <Spinner />}
            </button>
          ) : btnType === "submit" ? (
            <button
              type="submit"
              className="d-flex align-items-center btn btn-primary"
            >
              {submitText} &nbsp; {isLoading && <Spinner />}
            </button>
          ) : (
            customButton
          ))}
        <button
          type="button"
          onClick={closeModal}
          className="btn btn-outline-danger"
          data-dismiss="modal"
        >
          {closeText}
        </button>
      </div>
    </Modal>
  )
}

Index.propTypes = {
  isOpen: PropTypes.bool,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.object,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  submitText: PropTypes.string,
  handleSubmit: PropTypes.func,
  closeText: PropTypes.string,
  btnType: PropTypes.string,
  confirmBtn: PropTypes.bool,
  customButton: PropTypes.element,
  isLoading: PropTypes.bool,
  size: PropTypes.string,
}

export default Index
