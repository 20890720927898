/* eslint-disable react/prop-types */
import React from "react"
import { FormFeedback } from "reactstrap"

import { TreeViewList, Spinner } from "components"
import ReactstrapInput from "components/Inputs/ReactstrapInput"
import useSequelInputForm from "./useSequelInputForm"

const SequelInputForm = ({
  configurationData,
  setUserInputs,
  setUserOutputs,
  userInputs,
  userOutputs,
  outputName,
  sequelWhereTreeData,
  setSequelColumnTreeData,
  setSequelWhereTreeData,
}) => {
  const {
    isFetchingColumns,
    isLoading,
    columns,
    error,
    status,
    setStatus,
    _SetError,
    handleMultiSelectedColumns,
    handleValidateAndFetchColumns,
    handleSingleSelectedColumn,
  } = useSequelInputForm({
    userInputs,
    userOutputs,
    outputName,
    setUserInputs,
    setUserOutputs,
    setSequelWhereTreeData,
    configurationData,
  })

  return (
    <>
      {configurationData?.activity?.requiredInputs.map(
        (requiredInput, index) => {
          return (
            <div className="mb-3" key={index}>
              {requiredInput.Key === "dsn" ? (
                <div className="d-flex flex-row w-100">
                  <div className="w-100 me-2">
                    <ReactstrapInput
                      className={`form-control  ${
                        status === null
                          ? ""
                          : status === true
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      type="text"
                      value={userInputs?.dsn || ""}
                      name={requiredInput.Key}
                      placeholder={
                        requiredInput.isRequired === true
                          ? requiredInput.Label + "*"
                          : requiredInput.Label
                      }
                      required={
                        requiredInput.isRequired
                          ? requiredInput.isRequired
                          : true
                      }
                      onChange={e => {
                        if (!e.target.value) setStatus(false)
                        if (!e.target.value) _SetError()
                        setUserInputs({
                          ...userInputs,
                          [requiredInput.Key]: e.target.value.trim(),
                        })
                      }}
                    />
                    {error && <FormFeedback invalid>{error}</FormFeedback>}
                  </div>
                  <button
                    disabled={!userInputs?.dsn || isLoading ? true : false}
                    className="d-flex btn btn-primary align-items-center"
                    onClick={() =>
                      handleValidateAndFetchColumns(userInputs?.dsn)
                    }
                  >
                    <span>Validate</span> {isLoading && <Spinner />}
                  </button>
                </div>
              ) : requiredInput.Key === "columns" ? (
                <>
                  <p className="p-0 mb-1">Select Columns*</p>
                  {isLoading || isFetchingColumns ? (
                    <div className="position-relative">
                      <ReactstrapInput
                        className={`form-control`}
                        type="text"
                        value={userInputs?.dns || ""}
                        disabled={isLoading || isFetchingColumns}
                      />
                      <p
                        style={{
                          fontSize: "12px",
                          top: "25%",
                          left: "15px",
                          color: "dimgray",
                          position: "absolute",
                        }}
                      >
                        Fetching Columns, Please wait...
                      </p>
                    </div>
                  ) : (
                    <TreeViewList
                      treeData={columns}
                      type="column"
                      multiSelect={true}
                      propagateSelect={true}
                      propagateSelectUpwards={true}
                      setTreeData={setSequelColumnTreeData}
                      handleSelectedColumn={handleMultiSelectedColumns}
                      configurationData={configurationData}
                    />
                  )}
                </>
              ) : requiredInput.Key === "where" ? (
                <>
                  <p className="p-0 mb-1">Where Clause*</p>
                  <div className="position-relative">
                    <TreeViewList
                      treeData={sequelWhereTreeData.whereClauseTreeData}
                      selectedColumns={sequelWhereTreeData.selectedColumns}
                      multiSelect={true}
                      type="where"
                      setTreeData={setSequelColumnTreeData}
                      userInputs={userInputs}
                      handleSelectedColumn={handleSingleSelectedColumn}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          )
        }
      )}
    </>
  )
}

export default SequelInputForm
