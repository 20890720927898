import React, { useEffect, useState } from "react"
import { compact } from "lodash"

import useStatus from "hooks/useStatus"
import axiosInstance from "services/axiosInstance"
import { collectionDropdownOptions } from "utils/helpers"
import { validateMongoConnectionURL } from "apis/dbConnections.apis"

const useMongoOutputForm = ({ userOutputs, setUserOutputs }) => {
  const { error, _SetError } = useStatus()

  const [isDisable, setIsDisable] = useState(true)
  const [databases, setDatabases] = useState([])
  const [collections, setCollections] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const [textCollection, setTextCollection] = useState("")
  // tables to show in multi select input
  const [collectionNames, setCollectionNames] = useState(null)
  // selected data to send to backend
  const [selectedCollections, setSelectedCollections] = useState("")
  const [isDynamicOutput, setIsDynamicOutput] = useState(false)
  const [dynamicCollections, setDynamicCollections] = useState([])

  useEffect(() => {
    const remainingDynamicCollections = []
    userOutputs &&
      "collection" in userOutputs &&
      Array.isArray(userOutputs?.collection) &&
      userOutputs?.collection?.forEach(({ inputTableId }) => {
        dynamicCollections?.forEach((dynamicInput, index) => {
          if (dynamicInput?.inputTableId === inputTableId) {
            remainingDynamicCollections[index] = dynamicInput
          }
        })
      })
    setDynamicCollections([...remainingDynamicCollections])
  }, [userOutputs])

  const validateDbConnectionAndGetDatabases = async connectionURL => {
    if (!connectionURL) return
    setIsLoading(true)
    try {
      const {
        data: { databases },
      } = await axiosInstance.post(validateMongoConnectionURL(), {
        connectionURL,
      })
      _SetError(null)
      setStatus(true)
      setDatabases(databases)
      setIsDisable(false)
      setIsLoading(false)
    } catch (err) {
      console.log("postgres output db failed to establish connection: ", err)
      _SetError(err.response.data.message)
      setStatus(false)
      setIsDisable(true)
      setIsLoading(false)
    }
  }
  const fetchCollections = async databaseName => {
    try {
      const {
        data: { collections },
      } = await axiosInstance.post(`/rest/v1/mongo-db-collection`, {
        connectionURL: userOutputs.url,
        databaseName,
      })
      setCollections(collections)
    } catch (err) {
      console.log("failed to fetch columns: ", err)
    }
  }

  const getCollections = ({ key, value }) => {
    setUserOutputs({
      ...userOutputs,
      [key]: value,
    })
    fetchCollections(value)
  }

  const handleCollections = ({ key, value: selected, type }) => {
    function filterValues(collections) {
      return collections.map(({ value }) => value.trim())
    }
    if (type === "input") {
      setCollectionNames(null)
      setTextCollection(selected)
      setUserOutputs({
        ...userOutputs,
        [key]: !selected ? "" : selected.trim(),
      })
    } else if (type === "select") {
      setIsDynamicOutput(false)
      setDynamicCollections([])
      // handle value to display in field
      const allSelected = selected.find(option => option.value === "all")
      const remainingCollections =
        collectionDropdownOptions(collections).slice(2)
      allSelected
        ? setCollectionNames(remainingCollections)
        : setCollectionNames(selected)

      //handle value to send to backend
      const values = allSelected
        ? filterValues(remainingCollections).join(",")
        : filterValues(selected).join(",")
      setSelectedCollections(values)
      setUserOutputs({
        ...userOutputs,
        [key]: !values ? "" : values.trim(),
      })
    }
  }

  const handleDynamicOutputs = checked => {
    setIsDynamicOutput(checked)
    setUserOutputs({
      ...userOutputs,
      collection: [],
    })
    setDynamicCollections([])
    setCollectionNames(null)
  }

  const handleDynamicCollections = ({ value, inputTableId, index }) => {
    setDynamicCollections(collections => {
      const data = {
        collection: value,
        inputTableId,
      }
      collections[index] = value ? data : null
      setUserOutputs({
        ...userOutputs,
        collection: compact(collections),
      })
      return collections
    })
  }

  return {
    handleCollections,
    getCollections,
    fetchCollections,
    validateDbConnectionAndGetDatabases,
    handleDynamicCollections,
    handleDynamicOutputs,
    setStatus,
    setIsDisable,
    setCollections,
    setCollectionNames,
    _SetError,
    error,
    isDisable,
    isLoading,
    databases,
    collections,
    status,
    textCollection,
    selectedCollections,
    dynamicCollections,
    collectionNames,
    isDynamicOutput,
  }
}

export default useMongoOutputForm
