/* eslint-disable react/prop-types */
import React from "react"
import { Button, Form } from "reactstrap"

import { Spinner } from "components"
import { ControlledInput } from "components/Inputs"
import UsePasswordVisiblity from "hooks/usePasswordVisiblility"

const ResetPasswordForm = ({
  errors,
  handleSubmit,
  isLoading,
  onSubmit,
  register,
  touchedFields,
}) => {
  const { isVisible, TogglePasswordVisiblity } = UsePasswordVisiblity()
  return (
    <Form
      className="login-form"
      inline="true"
      onSubmit={handleSubmit(onSubmit)}
    >
      <ControlledInput
        errors={errors}
        label="Password"
        name="password"
        register={register}
        touchedFields={touchedFields}
        type={isVisible ? "text" : "password"}
        icon={
          <span
            onClick={TogglePasswordVisiblity}
            className="position-absolute fs-2"
            style={{ right: "9px", bottom: "8px", cursor: "pointer" }}
          >
            {isVisible ? (
              <i className={`mdi mdi-eye`}></i>
            ) : (
              <i className={`mdi mdi-eye-off text-muted`}></i>
            )}
          </span>
        }
      />
      <ControlledInput
        errors={errors}
        label="Confirm Password"
        name="confirmPassword"
        register={register}
        touchedFields={touchedFields}
        type={isVisible ? "text" : "password"}
        icon={
          <span
            onClick={TogglePasswordVisiblity}
            className="position-absolute fs-2"
            style={{ right: "9px", bottom: "8px", cursor: "pointer" }}
          >
            {isVisible ? (
              <i className={`mdi mdi-eye`}></i>
            ) : (
              <i className={`mdi mdi-eye-off text-muted`}></i>
            )}
          </span>
        }
      />
      <Button
        type="submit"
        color="primary"
        size="lg"
        className="w-100 mt-1 d-flex justify-content-center align-items-center"
        disabled={isLoading}
      >
        Submit {isLoading && <Spinner />}
      </Button>
    </Form>
  )
}

export default ResetPasswordForm
