/* eslint-disable react/prop-types */
import React from "react"
import { Card, CardBody, CardHeader } from "reactstrap"
import moment from "moment"

import { useAuthContext } from "context/authContextWrapper"
import { Placeholder } from "components"

const PlanPreview = ({
  togglePlanMenu,
  toggleUnsubscribeCard,
  showCancelSubscriptionCard,
  subscription: { current_period_end = "", status = "" } = {},
}) => {
  const { user, user: { plan = {} } = {} } = useAuthContext()

  return (
    <Card className="me-md-3 shadow-sm border">
      <CardHeader className="bg-light">
        <h4 className="card-title">Plan Details</h4>
      </CardHeader>
      <CardBody>
        <div className="d-flex flex-column w-100">
          <h5 className="m-0">
            {plan ? `${plan?.name} Plan` : <Placeholder />}{" "}
          </h5>
          {current_period_end ? (
            <p className="sub-text text-muted p-0">
              {`${moment(current_period_end * 1000).diff(moment(), "days")} days
              remaining`}
            </p>
          ) : (
            <Placeholder />
          )}
        </div>
        <p className="pt-2">
          You are currently subscribed to the <b>{plan?.name} Plan</b> with
          amount{" "}
          <b>
            ${plan?.price} / {plan?.interval}
          </b>{" "}
        </p>
        <h4 className="card-title">Features</h4>
        <ul>
          {plan?.features.map((feature, index) => (
            <li key={`${feature}-${index}`}>{feature}</li>
          ))}
        </ul>
        {!user?.additionalInfo?.unsubscribed && (
          <div className="d-flex flex-row justify-content-end gap-3">
            {status === "active" && (
              <button
                onClick={togglePlanMenu}
                className="btn btn-dark btn-sm mt-1"
              >
                Update Plan
              </button>
            )}
            <button
              onClick={toggleUnsubscribeCard}
              disabled={showCancelSubscriptionCard}
              className="btn btn-outline-danger btn-sm mt-1"
            >
              Cancel Plan
            </button>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default PlanPreview
