import React from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Container, Form, Row } from "reactstrap"
import { Helmet } from "react-helmet-async"

import LOGO from "assets/images/databashi-logo.png"
import profile from "assets/images/profile-img.png"

import { useAuthContext } from "context/authContextWrapper"

import { Spinner } from "components"
import { ControlledInput } from "components/Inputs"
import useForgetPassword from "./useForgetPassword"
import { ENUMS } from "utils/enums"

const ForgetPassword = () => {
  const { isAddon } = useAuthContext()
  const {
    errors,
    handleSubmit,
    isLoading,
    onSubmit,
    register,
    touchedFields,
    resMsg,
  } = useForgetPassword()

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Helmet>
          <title>Forgot Password | {ENUMS.BRAND_NAME}</title>
        </Helmet>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden w-100">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary"> Forgot Password?</h5>
                        <span>Reset with {ENUMS.BRAND_NAME}.</span>
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to={!isAddon ? "/auth/login" : "#"}>
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={LOGO}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>

                  <div className="p-2">
                    {resMsg && (
                      <div
                        className="alert alert-success text-center mb-4"
                        role="alert"
                      >
                        {resMsg}
                      </div>
                    )}

                    <Form
                      className="login-form"
                      inline="true"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <ControlledInput
                        type="text"
                        label="Email Address"
                        name="email"
                        errors={errors}
                        touchedFields={touchedFields}
                        register={register}
                        icon={
                          <span
                            className="position-absolute fs-2"
                            style={{
                              right: "9px",
                              bottom: "8px",
                              pointerEvents: "none",
                            }}
                          >
                            <i className="mdi mdi-email"></i>
                          </span>
                        }
                      />
                      <Button
                        type="submit"
                        color="primary"
                        size="lg"
                        className="w-100 mt-1 d-flex justify-content-center align-items-center"
                        disabled={isLoading}
                      >
                        Submit {isLoading && <Spinner />}
                      </Button>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link to="/auth/login" className="fw-medium text-primary">
                    {" "}
                    Sign In here
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ForgetPassword
