import React, { useEffect, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"

import UseLoadingHook from "hooks/useLoading"

import { resetPassword, verifyTokenURL } from "apis/auth"
import axiosInstance from "services/axiosInstance"
import { ResetPasswordSchema } from "utils/validationSchema"

const INITIAL_VALUES = {
  password: "",
  confirmPassword: "",
}

const UseResetPassword = () => {
  const history = useHistory()
  const { token } = useParams()
  const { disableLoading, enableLoading, isLoading } = UseLoadingHook()
  const [loading, setIsLoading] = useState(true)
  const [email, setEmail] = useState("")

  const verifyToken = async () => {
    setIsLoading(true)
    try {
      const {
        data: { email },
      } = await axiosInstance.post(verifyTokenURL(), {
        token,
      })
      setEmail(email)
      setIsLoading(false)
    } catch (err) {
      console.log("Failed to verify user token")
      setIsLoading(false)
    }
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      verifyToken()
    }
    return () => {
      isMounted = false
    }
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm({
    defaultValues: INITIAL_VALUES,
    mode: "all",
    reValidateMode: "onSubmit",
    resolver: yupResolver(ResetPasswordSchema),
  })

  const onSubmit = async data => {
    enableLoading()
    try {
      const payload = {
        email,
        password: data.password,
      }
      await axiosInstance.post(resetPassword(), payload)
      disableLoading()
      history.push("/auth/login")
    } catch (err) {
      console.log("Forget Password Api failed: ", err)
      disableLoading()
    }
  }

  return {
    register,
    errors,
    touchedFields,
    handleSubmit,
    onSubmit,
    isLoading,
    loading,
    email,
  }
}

export default UseResetPassword
