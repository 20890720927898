import React from "react"
import PropTypes from "prop-types"
import PaymentForm from "./paymentForm"

const PaymentFormWrapper = ({ plan, onCancel, type = "" }) => {
  return <PaymentForm plan={plan} onCancel={onCancel} modalType={type} />
}

PaymentFormWrapper.propTypes = {
  plan: PropTypes.object,
  onCancel: PropTypes.func,
  type: PropTypes.string,
}

export default PaymentFormWrapper
