/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { FaSquare, FaCheckSquare, FaMinusSquare } from "react-icons/fa"
import { IoMdArrowDropright } from "react-icons/io"
import TreeView from "react-accessible-treeview"
import cx from "classnames"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"

import "./treeView.scss"

const DATA_TYPES = [
  "integer",
  "number",
  "mediumint",
  "timestamp with time zone",
  "int",
  "int64",
  "timestamp",
  "timestampz",
  "timestamptz",
  "timestamp_tz",
  "timestamp_ntz",
  "datetime",
]

function TreeViewList({
  treeData,
  handleSelectedColumn,
  selectedColumns,
  setTreeData,
  userInputs,
  multiSelect = false,
  propagateSelect = false,
  propagateSelectUpwards = false,
  type,
  configurationData,
}) {
  const [tree, setTree] = useState([
    {
      id: 0,
      name: "",
      children: [],
      parent: null,
    },
  ])
  useEffect(() => {
    setTree([
      {
        id: 0,
        name: "",
        children: [],
        parent: null,
      },
    ])
  }, [configurationData?.activity?.name])
  const [selectedIds, setSelectedIds] = useState([])
  useEffect(() => {
    let isMounted = true
    let remainingColumns = []
    if (isMounted && type === "where") {
      //removing ids for whereClause on unselection of column
      userInputs?.columns?.forEach(({ children }) => {
        selectedIds.forEach(id => {
          children.includes(id) && remainingColumns.push(id)
        })
      })
    }
    setSelectedIds([...remainingColumns])
    return () => {
      isMounted = false
    }
  }, [userInputs])

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (!isEmpty(treeData)) {
        setTree(treeData)
        setTreeData(treeData)
      }
    }
    return () => {
      isMounted = false
    }
  }, [treeData])

  const _handleSelect = e => {
    let { selectedIds: selectedIDs, lastUserSelect } = e.treeState
    let deepCopySelectedIds = [...selectedIDs]
    if (type === "where") {
      const TABLE_IDS = tree[0]?.children // table ids from flatten tree
      TABLE_IDS?.forEach(tableId => {
        const CHILD_NODES = tree.find(
          ({ id, children }) => tableId === id && children
        )
        let columnSelectionCount = 0
        deepCopySelectedIds.forEach(selectedId => {
          if (CHILD_NODES.children.includes(selectedId)) {
            columnSelectionCount++
          }
          if (columnSelectionCount > 1) {
            deepCopySelectedIds = [...deepCopySelectedIds].filter(
              id => !CHILD_NODES.children.includes(id) && id
            )
            deepCopySelectedIds.push(lastUserSelect)
          }
        })
      })
    }
    if (
      type === "column" &&
      !isEmpty(selectedIds) &&
      selectedIds.length !== deepCopySelectedIds.length
    ) {
      deepCopySelectedIds = selectedIds.filter(
        id => deepCopySelectedIds.includes(id) && id
      )
    }
    deepCopySelectedIds = [...deepCopySelectedIds].sort((a, b) => a - b)
    setSelectedIds([...deepCopySelectedIds])
    handleSelectedColumn([...deepCopySelectedIds], tree)
  }

  const _handleNodeSelect = e => {
    if (type === "where") return null
    let { selectedIds } = e.treeState
    let deepCopySelectedIds = [...selectedIds]
    setSelectedIds([...deepCopySelectedIds])
    handleSelectedColumn([...deepCopySelectedIds], tree)
  }

  return (
    <div>
      <div className="treeview-checkbox">
        <TreeView
          data={tree}
          aria-label="Checkbox tree"
          multiSelect={multiSelect}
          propagateSelect={propagateSelect}
          propagateSelectUpwards={propagateSelectUpwards}
          togglableSelect
          onSelect={_handleSelect}
          onNodeSelect={_handleNodeSelect}
          selectedIds={selectedIds}
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            isSelected,
            isHalfSelected,
            level,
            getNodeProps,
            handleSelect,
            handleExpand,
          }) => {
            return type === "where" ? (
              selectedColumns.includes(element.id) ||
              (selectedColumns.includes(element.parent) &&
                DATA_TYPES.includes(element.type)) ? (
                <div
                  {...getNodeProps({ onClick: handleExpand })}
                  style={{ marginLeft: 40 * (level - 1) }}
                >
                  {isBranch && <ArrowIcon isOpen={isExpanded} />}
                  {type === "where" && element.parent === 0 ? (
                    ""
                  ) : (
                    <CheckBoxIcon
                      className="checkbox-icon"
                      onClick={e => {
                        handleSelect(e)
                        e.stopPropagation()
                      }}
                      variant={
                        isHalfSelected ? "some" : isSelected ? "all" : "none"
                      }
                    />
                  )}
                  <span className="name">{element.name}</span>
                </div>
              ) : (
                ""
              )
            ) : type === "column" ? (
              <div
                {...getNodeProps({ onClick: handleExpand })}
                style={{ marginLeft: 40 * (level - 1) }}
              >
                {isBranch && <ArrowIcon isOpen={isExpanded} />}
                {type === "where" && element.parent === 0 ? (
                  ""
                ) : (
                  <CheckBoxIcon
                    className="checkbox-icon"
                    onClick={e => {
                      handleSelect(e)
                      e.stopPropagation()
                    }}
                    variant={
                      isHalfSelected ? "some" : isSelected ? "all" : "none"
                    }
                  />
                )}
                <span className="name">{element.name}</span>
              </div>
            ) : (
              ""
            )
          }}
        />
      </div>
    </div>
  )
}

const ArrowIcon = ({ isOpen, className }) => {
  const baseClass = "arrow"
  const classes = cx(
    baseClass,
    { [`${baseClass}--closed`]: !isOpen },
    { [`${baseClass}--open`]: isOpen },
    className
  )
  return <IoMdArrowDropright className={classes} />
}

ArrowIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
}

const CheckBoxIcon = ({ variant, ...rest }) => {
  switch (variant) {
    case "all":
      return <FaCheckSquare {...rest} />
    case "none":
      return <FaSquare {...rest} />
    case "some":
      return <FaMinusSquare {...rest} />
    default:
      return null
  }
}

CheckBoxIcon.propTypes = {
  variant: PropTypes.string.isRequired,
}

export default TreeViewList
