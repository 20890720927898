import React, { useState } from "react"
import { isEmpty } from "lodash"

import UseLoading from "./useLoading"
import UseStatus from "./useStatus"

import axiosInstance from "services/axiosInstance"

const UseKafka = () => {
  const { disableLoading, enableLoading, isLoading } = UseLoading()
  const { error, _SetError } = UseStatus()

  const [isDisabled, setIsDisable] = useState(true)
  const [status, setStatus] = useState(null)

  const validateHosts = async ({ seedBrokers, role }) => {
    if (isEmpty(seedBrokers)) return
    try {
      enableLoading()
      await axiosInstance.post(`/rest/v1/connect-kafka`, {
        seedBrokers,
        role,
      })
      _SetError(null)
      setStatus(true)
      setIsDisable(false)
      disableLoading()
    } catch (err) {
      console.log(`Failed to connect to kafka hostnames: `, err)
      _SetError(err.response.data.message)
      setStatus(false)
      setIsDisable(true)
      disableLoading()
    }
  }
  return {
    error,
    status,
    isDisabled,
    validateHosts,
    _SetError,
    setStatus,
    isLoading,
  }
}

export default UseKafka
