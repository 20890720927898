import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const SnowflakeToPostgresBlog = () => {
  const data = tags.find(items => items.blogName === "snowflakePostgres")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        Data is the lifeblood of modern enterprises, and as business needs
        evolve, so does the requirement to transition between data platforms.
        Migrating from Snowflake to PostgreSQL can be a strategic decision to
        align with evolving data management goals. In this blog post, we will
        explore an efficient and reliable approach to migrate data from
        Snowflake to PostgreSQL using the powerful combination of Benthos and
        Temporal.
      </p>
      <h4>Snowflake</h4>
      <p>
        Snowflake is a cloud-based data warehousing platform designed to handle
        large volumes of structured and semi-structured data. It provides a
        fully managed and scalable solution for storing, processing, and
        analyzing data in the cloud. Snowflake is known for its unique
        architecture, ease of use, and support for a wide range of data
        analytics and business intelligence applications.
      </p>
      <h4>Postgres</h4>
      <p>
        PostgreSQL, often referred to as Postgres, is known for its ACID
        compliance, extensibility, and support for advanced SQL features.
        It&lsquo;s suitable for a wide range of applications, from small-scale
        projects to large-scale enterprise systems.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source framework for building, testing, and
        deploying distributed systems. It provides developers with an easy way
        to manage workflows, maintain states, and handle errors. Temporal is
        particularly useful for managing long-running processes and complex,
        multi-step workflows.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos, on the other hand, is a powerful data streaming tool that can
        be used to connect various data sources and sinks. It provides a lot of
        flexibility in terms of data processing, transformation, and routing.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Input Conversion</b>
          <p>
            Click on the <b>Choose Input Conversion</b> dropdown and select{" "}
            <b>Snowflake</b>.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-postgres/snip-1.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on <b>Choose Conversion</b> dropdown and select{" "}
            <b>Snowflake to Postgres</b>.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-postgres/snip-2.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Validate the DB URL</b>
          <p>
            Enter the URL of the Snowflake database and click on validate
            button.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-postgres/snip-3.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select Columns and WHERE clause</b>
          <p>
            Select the Columns from the tables shown below and then choose the
            WHERE clause.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-postgres/snip-4.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Validate Output DB URL</b>
          <p>
            Enter the URL of the Postgres database and click on validate button.
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-postgres/snip-5.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select output table</b>
          <p>
            Select the output table from the list provided below. If you do not
            see the tables, kindly ensure that you have entered the correct
            database URL or verify that your database indeed contains tables
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-postgres/snip-6.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>
            Now we need to run the migration by clicking on Save & Run button
          </p>
          <img
            src="/assets/images/snowflake/snowflake-to-postgres/snip-7.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/snowflake/snowflake-to-postgres/activity.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default SnowflakeToPostgresBlog
