import React from "react"
import { Helmet } from "react-helmet-async"
import { Card, CardBody } from "reactstrap"
import { isEmpty } from "lodash"

import useInvoices from "hooks/useInvoices"

import { Loader } from "components"

import InvoiceTable from "./InvoiceTable"

import { ENUMS } from "utils/enums"

const Invoices = () => {
  const { invoices, isLoading, hasMore, fetchInvoices } = useInvoices({
    limit: 5,
  })

  const CardTitle = <h4 className="card-title">Billing History</h4>

  const LoadMoreBtn = (
    <button onClick={fetchInvoices} className="btn btn-outline-primary rounded">
      Load More
    </button>
  )

  const CaughtUpMessage = (
    <p className="text-black Text-fontWeight--600">
      Yayy! You are all caught up.
    </p>
  )

  const NoInvoiceMessage = (
    <div className="page-content d-flex flex-row w-100 h-100 align-items-center justify-content-center">
      <p className="text-black Text-fontWeight--600">No Invoices Yet!</p>
    </div>
  )

  if (isLoading) return <Loader />

  if (isEmpty(invoices)) return NoInvoiceMessage

  return (
    <div className="page-content">
      <Helmet>
        <title>Billing | {ENUMS.BRAND_NAME}</title>
      </Helmet>
      <Card>
        <CardBody>
          {CardTitle}
          <div className="table-responsive mt-4">
            <InvoiceTable invoices={invoices} />

            <div className="d-flex flex-row justify-content-center my-3">
              {hasMore ? LoadMoreBtn : CaughtUpMessage}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default Invoices
