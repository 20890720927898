import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useHistory } from "react-router-dom"

import { useAuthContext } from "context/authContextWrapper"

import useLoading from "hooks/useLoading"
import useAlert from "hooks/useAlert"

import { login } from "apis/auth"
import axiosInstance from "services/axiosInstance"
import { LoginFormSchema } from "utils/validationSchema"

const INITIAL_VALUES = {
  email: "",
  password: "",
}

const UseLoginForm = () => {
  const history = useHistory()
  const { storeUser, setAuthToken } = useAuthContext()
  const { disableLoading, enableLoading, isLoading } = useLoading()
  const { disableAlert, enableAlert, alert } = useAlert()

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm({
    defaultValues: INITIAL_VALUES,
    mode: "all",
    reValidateMode: "onSubmit",
    resolver: yupResolver(LoginFormSchema),
  })

  const onSubmit = async data => {
    enableLoading()
    try {
      const {
        data: { user, token, message },
      } = await axiosInstance.post(login(), data)
      setAuthToken(token)
      storeUser(user)
      enableAlert({
        visible: true,
        color: "success",
        message,
      })
      disableLoading()
      history.push("/dashboard")
    } catch (err) {
      console.log("Login Api failed: ", err?.response?.data?.message)
      enableAlert({
        visible: true,
        color: "danger",
        message: err?.response?.data?.message,
      })
      disableLoading()
    }
  }
  return {
    errors,
    touchedFields,
    isLoading,
    alert,
    register,
    handleSubmit,
    onSubmit,
    disableAlert,
  }
}

export default UseLoginForm
