import MultipleDatabaseSupportImg from "assets/images/banner.png"
import EntensiveDocsImg from "assets/svgs/extensive-documentation.svg"
import DataExportImg from "assets/svgs/data-export.svg"
import SourceCodeImg from "assets/svgs/source-code.svg"
import RichAPIImg from "assets/svgs/rich-api.svg"
import StreamProcessingImg from "assets/svgs/stream-processing.svg"
import SelfHostedSolutionImg from "assets/svgs/self-hosted-solution.svg"
import { ENUMS } from "../enums"

const FEATURES = [
  {
    title: "Real-Time Data Streaming",
    description: `${ENUMS.BRAND_NAME} can process data streams in real-time, allowing you to perform data migration and integration tasks in near real-time.`,
    image: StreamProcessingImg,
    alternate: "A guy processing data on streams",
  },
  {
    title: "Multiple Databases support",
    description:
      "Our platform allows you to customize data transformation as per your requirements, so you can ensure that your data is in the format that you need. Its supports multiple databases:",
    image: MultipleDatabaseSupportImg,
    list: ["Postgres", "MySQL", "MongoDB", "Redis", "Kafka"],
    alternate: "Multipl Datbase Support",
  },
  {
    title: "Self-hosted solution",
    description: `${ENUMS.BRAND_NAME} is completly self hosted solution, which makes it very easy to install and run in your own premises.`,
    image: SelfHostedSolutionImg,
    alternate: "A person exporting data on different databases in parallel",
  },
  {
    title: "Full database/multi-table replication",
    description: `With ${ENUMS.BRAND_NAME}, you can easily export complete database/multiple tables data to other data warehouses for further analysis and reporting.`,
    image: DataExportImg,
    alternate: "A person exporting data on different databases in parallel",
  },
  {
    title: "Source code included with perpetual license",
    description: `${ENUMS.BRAND_NAME} provides access to its source code with prepetual license.`,
    image: SourceCodeImg,
    alternate: "A person demonstrating data security",
  },
  {
    title: "Rich-API (all actions can be done programmatically via the API)",
    description: `${ENUMS.BRAND_NAME} provides open access to APIs which makes it very easy for other developers to integrate in their applications.`,
    image: RichAPIImg,
    alternate: "Demonstration of scalability on graph",
  },
  {
    title: "Extensive documentation",
    description: `${ENUMS.BRAND_NAME} provides an extensive documentation that is well explained for the customers to follow to easy the process of using the platform.`,
    image: EntensiveDocsImg,
    alternate: "Pipelining customized configurations for ETL",
  },
]

export default FEATURES
