import React from "react"
import PropTypes from "prop-types"

import { MiniWidget, StatsWidget } from "components/Widgets"

const StatsSection = ({ user, stats }) => {
  return (
    <div className="container-fluid">
      <div className="header-body">
        <div className="row">
          <div className="col-12 col-md-6" style={{ padding: "0 7px" }}>
            <StatsWidget
              title="Current Month Processed Records"
              description={`${user?.totalProcessedRecords || 0} / ${
                user?.plan?.maxActivities || 0
              }`}
              iconClass="bx-copy-alt"
            />
          </div>
          <div className="col-12 col-md-6" style={{ padding: "0 7px" }}>
            <MiniWidget title="Records Processed By Month" stats={stats} />
          </div>
        </div>
      </div>
    </div>
  )
}

StatsSection.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      processedCount: PropTypes.number.isRequired,
    })
  ),
  user: PropTypes.object.isRequired,
}

export default StatsSection
