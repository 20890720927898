import React, { Suspense, useState } from "react"
import { Switch, useHistory, Redirect } from "react-router-dom"
import Cookies from "js-cookie"

import { useAuthContext } from "context/authContextWrapper"
import { useStripeContext } from "context/stripeContextWrapper"

import { AlertModal, Loader } from "components"

import LocalRoute from "./authoutes"

import { logoutURL } from "apis/auth"
import axiosInstance from "services/axiosInstance"
import {
  ADDON_ROUTES,
  CLOUD_PLATFORM_ROUTES,
  DOCKER_PLATFORM_ROUTES,
} from "./routes"

const Routes = () => {
  const { isAddon } = useAuthContext()
  const { hasStripeAccess } = useStripeContext()
  const history = useHistory()

  const [modal, setModal] = useState({
    shouldModalDisplay: false,
    modalAction: "",
    shouldButtonDisplay: false,
    modalTitle: "",
    modalDescription: "",
  })

  axiosInstance.interceptors.request.use(
    req => {
      req.headers.mode = Cookies.get("stripeMode")
      return req
    },
    async error => {
      console.log("GET REQUEST ERROR")
      if (error?.response?.data?.statusCode == 401) {
        toggleModal(
          "warning",
          false,
          "Token Expired",
          "Open the Dashboard from Heroku"
        )
      }
      return Promise.reject(error.message)
    }
  )
  axiosInstance.interceptors.response.use(
    async response => {
      if (response?.status === 201 && !response?.data?.modalShow) {
        return response
      }
      if (response?.status === 201) {
        toggleModal("success", true, "Successful", "Task Performed")
      }

      return response
    },
    async error => {
      console.log("POST REQUEST ERROR")
      console.log("Post Interceptor: ", error?.response?.data?.statusCode)
      if (error?.response?.status === 401 && error?.response?.data?.logout) {
        axiosInstance.post(logoutURL(), {}).then(() => {
          history.push("/auth/login")
        })
      }
      if (error?.response?.data?.statusCode == 401) {
        toggleModal(
          "warning",
          false,
          "Token Expired",
          "Open the Dashboard from Heroku"
        )
      }
      return Promise.reject(error)
    }
  )

  const toggleModal = (
    modalAction,
    shouldButtonDisplay,
    modalTitle,
    modalDescription
  ) => {
    setModal({
      shouldModalDisplay: !modal.shouldModalDisplay,
      modalAction: modalAction,
      shouldButtonDisplay: shouldButtonDisplay,
      modalTitle: modalTitle,
      modalDescription: modalDescription,
    })
  }

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {!isAddon && !hasStripeAccess
          ? [...DOCKER_PLATFORM_ROUTES].map(
              ({ path, element: Element, layout, exact }) => (
                <LocalRoute
                  key={path}
                  path={path}
                  layout={layout}
                  exact={exact}
                >
                  <Element />
                </LocalRoute>
              )
            )
          : !isAddon && hasStripeAccess
          ? [...CLOUD_PLATFORM_ROUTES].map(
              ({ path, element: Element, layout, stripe, exact }) => (
                <LocalRoute
                  key={path}
                  path={path}
                  exact={exact}
                  layout={layout}
                  hasStripeAccess={hasStripeAccess}
                  isStripePath={stripe}
                >
                  <Element />
                </LocalRoute>
              )
            )
          : [...ADDON_ROUTES].map(
              ({ path, element: Element, layout, exact }) => (
                <LocalRoute
                  key={path}
                  path={path}
                  layout={layout}
                  exact={exact}
                >
                  <Element />
                </LocalRoute>
              )
            )}
        <Redirect
          from="*"
          to={!isAddon && !hasStripeAccess ? "/dashboard" : "/"}
        />
      </Switch>
      <AlertModal modal={modal} setModal={setModal} />
    </Suspense>
  )
}

export default Routes
