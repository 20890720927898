import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const RedisToRedisBlog = () => {
  const data = tags.find(items => items.blogName === "redis")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        Redis, a high-performance in-memory data structure store, offers
        lightning-fast data storage and retrieval. When it comes to seamless
        data transfer and synchronization between different Redis instances,
        businesses can benefit from improved performance and real-time data
        consistency. In this blog, we will explore how to efficiently connect
        and synchronize data between Redis databases, creating a robust data
        pipeline.
      </p>
      <h4>Redis</h4>
      <p>
        Redis is an open-source, in-memory data store that serves as a cache, a
        message broker, and more. Its speed and flexibility make it suitable for
        various use cases, from caching to real-time analytics and messaging.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source framework for building, testing, and
        deploying distributed systems. It provides developers with an easy way
        to manage workflows, maintain states, and handle errors. Temporal is
        particularly useful for managing long-running processes and complex,
        multi-step workflows.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos, on the other hand, is a powerful data streaming tool that can
        be used to connect various data sources and sinks. It provides a lot of
        flexibility in terms of data processing, transformation, and routing.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on the dropdown and select your desired conversion. In this
            case, we are converting from Redis to Redis.
          </p>
          <img
            src="/assets/images/redis/redis-to-redis/snip-1.png"
            alt="Redis to Redis conversion select"
          />
        </li>
        <br />
        <li>
          <b>Validate the DB URL</b>
          <p>
            In this step, we will validate the URL of redis server. Enter the
            URL of the server and click on validate button.
          </p>
          <img src="/assets/images/redis/redis-to-redis/snip-2.png" />
        </li>
        <br />
        <li>
          <b>Select Channels</b>
          <p>Select the channel as shown below.</p>
          <img src="/assets/images/redis/redis-to-redis/snip-3.png" />
        </li>
        <br />
        <li>
          <b>Validate output Server URL</b>
          <p>
            In this step, we will validat the URL of the output server URL.
            Enter the URL of the server and click on validate button.
          </p>
          <img src="/assets/images/redis/redis-to-redis/snip-4.png" />
        </li>
        <br />
        <li>
          <b>Select output Channel</b>
          <p>
            Once validated, we will see the channels in the server. Select your
            channel.
          </p>
          <img src="/assets/images/redis/redis-to-redis/snip-5.png" />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>Now we need to run the migration by clicking on Save & Run</p>
          <img src="/assets/images/redis/redis-to-redis/snip-6.png" />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/redis/redis-to-redis/activity.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Activity Logs</b>
          <p>These are the activity logs.</p>
          <img
            src="/assets/images/redis/redis-to-redis/logs.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default RedisToRedisBlog
