export const getUserStats = () => `/rest/v1/user/stats`
export const fetchUserActivities = () => `/rest/v1/api/user-activities`
export const updateUserAccountDetailsURL = userId =>
  `/rest/v1/auth/update/${userId}`
export const updateSubscriptonIdURL = userId =>
  `/rest/v1/auth/update-subscription/${userId}`
export const cancelSubscriptonURL = () => `/rest/v1/auth/cancel-subscription`
export const profile = () => `/rest/v1/user`
export const apiRequestLogs = (currentPage, search) =>
  `/rest/v1/api-requests?page=${currentPage}&search=${search}`

export const exportServerLogsURL = () => "/rest/v1/export-logs"
