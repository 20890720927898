import React from "react"

import "../assets/scss/custom/components/loader.css"

const loader = () => {
  return (
    <div className="waviy">
      <span style={{ "--i": 1 }}>L</span>
      <span style={{ "--i": 2 }}>O</span>
      <span style={{ "--i": 3 }}>A</span>
      <span style={{ "--i": 4 }}>D</span>
      <span style={{ "--i": 5 }}>I</span>
      <span style={{ "--i": 6 }}>N</span>
      <span style={{ "--i": 7 }}>G</span>
    </div>
  )
}

export default loader
