import React from "react"
import PropTypes from "prop-types"

const EnvironmentVariablesList = ({ userEnv = [] }) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        minHeight: "10vh",
        padding: "5vh",
      }}
    >
      {userEnv.map((env, index) => {
        return (
          <p key={index}>
            {env?.key} : {env?.value}
          </p>
        )
      })}
    </div>
  )
}

EnvironmentVariablesList.propTypes = {
  userEnv: PropTypes.array,
}

export default EnvironmentVariablesList
