import React from "react"
import { Helmet } from "react-helmet-async"
import Header from "./Header"
import Footer from "./Footer"

const BannerSection = React.lazy(() => import("./BannerSection"))
const FeatureSection = React.lazy(() => import("./FeatureSection"))
const BlogSection = React.lazy(() => import("./BlogSection"))

import { ENUMS } from "utils/enums.js"
import ContactSection from "./ContactSection"

const LandingPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Home | {ENUMS.BRAND_NAME}</title>
      </Helmet>
      <p className="text-center w-100 py-3 px-3 my-0 bg-light">
        You can read the API documentation here:{" "}
        <a
          href={`${REACT_APP_URL}/documentation`}
          target="_blank"
          rel="noreferrer"
        >
          LINK TO API DOCS
        </a>
      </p>
      <div className="bg-white">
        <Header />
        <BannerSection />
        <FeatureSection />
        <BlogSection />
        {!REACT_APP_IS_DOCKERIZED && <ContactSection />}
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default LandingPage
