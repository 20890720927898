export const validateSequelDBConnectionURL = () => `/rest/v1/connect-sequeldb`
export const retrieveSequelTablesURL = () => `rest/v1/sequeldb/tables`
export const retrieveSequelColumnsURL = () => `rest/v1/sequeldb/columns`

export const validateMongoConnectionURL = () => `/rest/v1/connect-mongo`

export const validateBigqueryConnectionURL = () => `/rest/v1/connect-bigquery`
export const retrieveBigqueryColumnsURL = () => `/rest/v1/bigquery/columns`

export const validateSnowflakeConnectionURL = () => `/rest/v1/connect-snowflake`
export const retrieveSnowflakeColumnsURL = () => `rest/v1/snowflake/columns`
export const retrieveSnowflakeTableColumnsURL = () =>
  `rest/v1/snowflake/table/columns`

export const validateRedshiftConnectionURL = () => `/rest/v1/connect-redshift`
export const retrieveRedshiftColumnsURL = () => `rest/v1/redshift/columns`
export const retrieveRedshiftTableColumnsURL = () =>
  `/rest/v1/redshift/table/columns`
