import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const RedshiftToMySQLBlog = () => {
  const data = tags.find(items => items.blogName === "redshiftMysql")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        As businesses grow and evolve, so do their data infrastructure needs. In
        this blog post, we explore a comprehensive strategy for migrating data
        from Amazon Redshift to MySQL, leveraging the power of Benthos for data
        streaming and Temporal for workflow orchestration. This dynamic duo
        provides a robust solution for a smooth and reliable data migration
        process.
      </p>
      <h4>AWS Redshift</h4>
      <p>
        Amazon Redshift is a fully managed, petabyte-scale data warehouse
        service in the cloud. Amazon Redshift Serverless lets you access and
        analyze data without all of the configurations of a provisioned data
        warehouse. Resources are automatically provisioned and data warehouse
        capacity is intelligently scaled to deliver fast performance for even
        the most demanding and unpredictable workloads. Regardless of the size
        of the dataset, Amazon Redshift offers fast query performance using the
        same SQL-based tools and business intelligence applications that you use
        today.
      </p>
      <h4>MySQL</h4>
      <p>
        MySQL is a popular open-source relational database management system. It
        is known for its stability, reliability, and performance in handling
        structured data. MySQL employs a table-based data storage model with
        ACID compliance, making it ideal for scenarios where data consistency
        and integrity are paramount.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source orchestration platform that enables you to
        build and manage complex workflows. It is designed to handle
        long-running and stateful processes, making it a powerful tool for
        orchestrating data migration and synchronization tasks.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos is a versatile open-source data processing tool that excels at
        connecting various data sources and sinks. It offers a range of
        processors and connectors for data transformation, enrichment, and
        routing, making it an excellent tool for creating data pipelines.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Input Conversion</b>
          <p>
            Click on the <b>Choose Input Conversion</b> dropdown and select{" "}
            <b>Redshift</b>.
          </p>
          <img
            src="/assets/images/redshift/redshift-to-mysql/snip-1.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on the <b>Choose Conversion</b> dropdown and select{" "}
            <b>Redshift To MySQL</b>.
          </p>
          <img
            src="/assets/images/redshift/redshift-to-mysql/snip-2.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Validate the DB URL</b>
          <p>
            Enter the URL of the AWS Redshift database and click on validate
            button.
          </p>
          <img
            src="/assets/images/redshift/redshift-to-mysql/snip-3.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select Columns and WHERE clause</b>
          <p>
            Select the Columns from the tables shown below and then choose the
            WHERE clause.
          </p>
          <img
            src="/assets/images/redshift/redshift-to-mysql/snip-4.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Validate Output DB URL</b>
          <p>
            Enter the URL of the MySQL database and click on validate button.
          </p>
          <img
            src="/assets/images/redshift/redshift-to-mysql/snip-5.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Select output Table</b>
          <p>
            Select the output table from the list. If you do not see the tables,
            kindly ensure that you have entered the correct database URL or
            verify that your database indeed contains tables.
          </p>
          <img
            src="/assets/images/redshift/redshift-to-mysql/snip-6.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>
            Now we need to run the migration by clicking on Save & Run button
          </p>
          <img
            src="/assets/images/redshift/redshift-to-mysql/snip-7.png"
            width={"600px"}
          />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/redshift/redshift-to-mysql/activity.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default RedshiftToMySQLBlog
