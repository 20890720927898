import React from "react"
import { useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { signup } from "apis/auth"
import useLoading from "hooks/useLoading"
import { SignupFormSchema } from "utils/validationSchema"
import axiosInstance from "services/axiosInstance"
import useAlert from "hooks/useAlert"

const INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  email: "",
  username: "",
  password: "",
  confirmPassword: "",
}

const UseSignup = () => {
  const history = useHistory()

  const { disableLoading, enableLoading, isLoading } = useLoading()
  const { enableAlert, disableAlert, alert } = useAlert()

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm({
    defaultValues: INITIAL_VALUES,
    mode: "all",
    resolver: yupResolver(SignupFormSchema),
  })

  const onSubmit = async data => {
    enableLoading()
    try {
      const {
        data: { message },
      } = await axiosInstance.post(signup(), data)
      enableAlert({
        visible: true,
        color: "success",
        message,
      })
      disableLoading()
      history.push("/auth/login")
    } catch (err) {
      console.log(err.response.data.message)
      enableAlert({
        visible: true,
        color: "danger",
        message: err.response.data.message,
      })
      disableLoading()
    }
  }

  return {
    alert,
    errors,
    touchedFields,
    isLoading,
    onSubmit,
    register,
    disableAlert,
    handleSubmit,
  }
}

export default UseSignup
