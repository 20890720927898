import React from "react"
import { isEmpty } from "lodash"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"

import { useAuthContext } from "context/authContextWrapper"

import { AlertBar, Modal, ConfirmDialogBox, Spinner } from "components"

import { useDashboard } from "hooks"

import StatsSection from "./statsSection"
import ConversionForm from "./conversionForm"
import UserActivityTable from "./userActivityTable"
import EnvironmentVariablesList from "./envVariablesList"
import ActivityProcedureModalWrapper from "./activityProcedureModalWrapper"
import UserActivitySkeleton from "./userActivityTable/userActivitySkeleton"
import { useStripeContext } from "context/stripeContextWrapper"

import "./dashboard.scss"

const Dashboard = () => {
  const {
    alert,
    allActivities,
    configuration,
    configurationData,
    isActivityAllow,
    isButtonSave,
    userActivityLoading,
    allActivitiesLoading,
    isSaved,
    modal_standard,
    selectedActivityProcedure,
    showProcedureModal,
    userActivities,
    userEnv,
    userInputs,
    userOutputs,
    responseMsg,
    showConfirmDialog,
    user,
    stats,
    activitiesStatus,
    isSubscribed,
    disableAlert,
    setConfiguration,
    setUserInputs,
    setUserOutputs,
    gotoActivityLogs,
    onSaveAndRun,
    handleSave,
    onCancel,
    handleStatusUpdate,
    tog_standard,
    _ToggleProcedureModal,
    setmodal_standard,
    sequelColumnTreeData,
    setSequelColumnTreeData,
    sequelWhereTreeData,
    setSequelWhereTreeData,
    setShowUniqueWhereClauseAlert,
    showUniqueWhereClauseAlert,
  } = useDashboard()

  const { isAddon, user: authUser } = useAuthContext()
  const { hasStripeAccess } = useStripeContext()

  return (
    <div>
      <div className="page-content">
        <Helmet>
          <title>Data Bash Dev</title>
        </Helmet>
        <div className="container">
          <StatsSection stats={stats} user={user} />
          <AlertBar
            color={alert?.color}
            disableAlert={disableAlert}
            showAlert={alert?.visible}
          >
            {alert?.message}
          </AlertBar>
          {userActivityLoading ? (
            <UserActivitySkeleton />
          ) : !isEmpty(userActivities) ? (
            <UserActivityTable
              activitiesStatus={activitiesStatus}
              userActivities={userActivities}
              toggleProcedureModal={_ToggleProcedureModal}
              gotoActivityLogs={gotoActivityLogs}
              handleStatusUpdate={handleStatusUpdate}
            />
          ) : (
            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
              <p>No user activities available</p>
            </div>
          )}
          {allActivitiesLoading || userActivityLoading ? (
            <div className="w-100 vh-75 d-flex flex-column justify-content-center align-items-center">
              <Spinner />
              <p>Loading configuration form, please wait ...</p>
            </div>
          ) : (
            <div className="row">
              {!isAddon && hasStripeAccess && !isSubscribed ? (
                <p className="text-danger">
                  Please subscribe to a plan in{" "}
                  <Link
                    to="/billing"
                    className="text-danger text-decoration-underline Text-fontWeight--600 "
                  >
                    &quot;Billing section&quot;
                  </Link>{" "}
                  to start using the application..
                </p>
              ) : !isAddon &&
                hasStripeAccess &&
                !authUser?.additionalInfo?.paid ? (
                <p className="text-danger">
                  Your payment was blocked, Please update your Payment Method to
                  retry your failed invoice.
                </p>
              ) : !isActivityAllow ? (
                <p className="text-danger">
                  Please upgrade your Plan to sync more records.
                </p>
              ) : !isEmpty(allActivities) ? (
                <>
                  <div className="col-md-6">
                    <ConversionForm
                      allActivities={allActivities}
                      configurationData={configurationData}
                      handleSave={onSaveAndRun}
                      isButtonSave={isButtonSave}
                      userInputs={userInputs}
                      userOutputs={userOutputs}
                      isSaved={isSaved}
                      configuration={configuration}
                      setConfiguration={setConfiguration}
                      setUserInputs={setUserInputs}
                      setUserOutputs={setUserOutputs}
                      sequelColumnTreeData={sequelColumnTreeData}
                      sequelWhereTreeData={sequelWhereTreeData}
                      setSequelColumnTreeData={setSequelColumnTreeData}
                      setSequelWhereTreeData={setSequelWhereTreeData}
                      showUniqueWhereClauseAlert={showUniqueWhereClauseAlert}
                      setShowUniqueWhereClauseAlert={
                        setShowUniqueWhereClauseAlert
                      }
                    />
                  </div>
                  <div className="col-md-1"></div>
                  {!isEmpty(userEnv) && (
                    <div className="col-md-5">
                      <EnvironmentVariablesList userEnv={userEnv} />
                    </div>
                  )}
                </>
              ) : (
                <p className="text-danger">
                  No Conversion is added yet. Please contact admin.
                </p>
              )}
            </div>
          )}
          {modal_standard && (
            <Modal
              isOpen={modal_standard}
              openModal={tog_standard}
              closeModal={() => setmodal_standard(false)}
              closeText="Close"
              submitBtn={false}
            >
              <h5>{responseMsg}</h5>
            </Modal>
          )}
          {showProcedureModal && (
            <ActivityProcedureModalWrapper
              modalTitle="Activity Procedure"
              selectedActivityProcedure={selectedActivityProcedure}
              showProcedureModal={showProcedureModal}
              _ToggleProcedureModal={_ToggleProcedureModal}
            />
          )}
          {showConfirmDialog && (
            <ConfirmDialogBox
              handleSave={handleSave}
              onCancel={onCancel}
              title="Record count exceeds plan quota"
              text="The number of rows in the selected input table(s) exceed your current plan quota. If you continue some records may be skipped"
              confirmBtnText={"Run anyway"}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Dashboard
