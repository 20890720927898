/* eslint-disable react/prop-types */
import React from "react"
import { Badge, Table } from "reactstrap"
import moment from "moment"

import { CustomTooltip } from "components"

const InvoiceTable = ({ invoices }) => {
  return (
    <Table className="table mb-0">
      <thead>
        <tr>
          <th>Date</th>
          <th>Details</th>
          <th className="text-center">Total Amount</th>
          <th className="text-center">Amount Due</th>
          <th className="text-center">Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {invoices.map(
          (
            {
              id,
              created,
              billing_reason,
              amount_due,
              paid,
              total,
              attempt_count,
              next_payment_attempt,
              invoice_pdf,
            },
            index
          ) => (
            <tr key={id}>
              <td>{moment(created * 1000).format("MMM Do, YYYY")}</td>
              <td>{billing_reason}</td>
              <td className="text-center">${(total / 100).toFixed(2)}</td>
              <td className="text-center">${(amount_due / 100).toFixed(2)}</td>
              <td className="text-center">
                {" "}
                {!paid && (
                  <CustomTooltip
                    target={`invoice-${id}`}
                    description={`Attempted to charge the customer's payment method ${attempt_count} time and failed. Payment will be retried on ${moment(
                      next_payment_attempt * 1000
                    ).format("MMM DD, YYYY")}.`}
                  />
                )}
                <Badge
                  id={`invoice-${id}`}
                  className={`font-size-12 ${
                    paid ? "badge-soft-success" : "badge-soft-danger"
                  }`}
                  color={`${!paid ? "danger" : ""}`}
                  pill
                >
                  {paid ? "Paid" : "Retry"}
                </Badge>
              </td>
              <td>
                {" "}
                <CustomTooltip
                  description="Download Invoice in PDF"
                  target={`downloadPDF-${index}`}
                />
                <a
                  id={`downloadPDF-${index}`}
                  href={invoice_pdf}
                  target="_blank"
                  className="btn p-0 text-info Text-fontWeight--600"
                  rel="noreferrer"
                >
                  Invoice {moment(created * 1000).format("DD MMM, YY")}
                </a>
              </td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  )
}

export default InvoiceTable
