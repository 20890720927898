import React from "react"
import { Button, Form } from "reactstrap"

import { AlertBar, Spinner } from "components"

import UsePasswordVisiblity from "hooks/usePasswordVisiblility"
import UseLoginForm from "./useLoginForm"

import { ControlledInput } from "components/Inputs"

const LoginForm = () => {
  const { isVisible, TogglePasswordVisiblity } = UsePasswordVisiblity()
  const {
    errors,
    touchedFields,
    isLoading,
    alert,
    handleSubmit,
    onSubmit,
    register,
    disableAlert,
  } = UseLoginForm()
  return (
    <Form
      name="login-form"
      className="login-form"
      inline="true"
      onSubmit={handleSubmit(onSubmit)}
    >
      <AlertBar
        showAlert={alert?.visible}
        color={alert?.color}
        disableAlert={disableAlert}
      >
        {alert?.message}
      </AlertBar>
      <ControlledInput
        type="text"
        label="Email"
        name="email"
        errors={errors}
        touchedFields={touchedFields}
        register={register}
        icon={
          <span
            className="position-absolute fs-2"
            style={{ right: "9px", bottom: "8px", pointerEvents: "none" }}
          >
            <i className="mdi mdi-email"></i>
          </span>
        }
      />
      <ControlledInput
        errors={errors}
        label="Password"
        name="password"
        register={register}
        touchedFields={touchedFields}
        type={isVisible ? "text" : "password"}
        icon={
          <span
            onClick={TogglePasswordVisiblity}
            className="position-absolute fs-2"
            style={{ right: "9px", bottom: "8px", cursor: "pointer" }}
          >
            {isVisible ? (
              <i className={`mdi mdi-eye`}></i>
            ) : (
              <i className={`mdi mdi-eye-off text-muted`}></i>
            )}
          </span>
        }
      />
      <Button
        type="submit"
        color="primary"
        size="lg"
        className="w-100 mt-1 d-flex justify-content-center align-items-center"
        disabled={isLoading}
      >
        Login {isLoading && <Spinner />}
      </Button>
    </Form>
  )
}

export default LoginForm
