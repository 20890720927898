/* eslint-disable react/prop-types */
import React from "react"
import { Badge, Table } from "reactstrap"
import moment from "moment"

import { CustomTooltip, Placeholder } from "components"

const RecentInvoiceTable = ({ isLoading, invoices }) => {
  return (
    <Table className="table mb-0">
      <thead>
        <tr>
          <th>Date</th>
          <th className="text-center">Total Amount</th>
          <th className="text-center">Amount Due</th>
          <th className="text-center">Paid</th>
          <th className="text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <tr>
            {Array(6)
              .fill("*")
              .map((item, index) => (
                <td key={index}>
                  <Placeholder />
                </td>
              ))}
          </tr>
        ) : (
          invoices.map(
            (
              {
                id,
                created,
                total,
                amount_due,
                paid,
                attempt_count,
                next_payment_attempt,
                invoice_pdf,
              },
              index
            ) => (
              <tr key={id}>
                <td>{moment(created * 1000).format("MMM Do, YYYY")}</td>
                <td className="text-center">${(total / 100).toFixed(2)}</td>
                <td className="text-center">
                  ${(amount_due / 100).toFixed(2)}
                </td>
                <td className="text-center">
                  {" "}
                  {!paid &&
                    (next_payment_attempt ? (
                      <CustomTooltip
                        target={`invoice-${id}`}
                        description={`Attempted to charge the customer's payment method ${attempt_count} time and failed. Payment will be retried on ${moment(
                          next_payment_attempt * 1000
                        ).format("MMM DD, YYYY")}.`}
                      />
                    ) : (
                      <CustomTooltip
                        target={`invoice-${id}`}
                        description={`Attempted to charge the customer's payment method ${attempt_count} time and failed. Please update your payment method to recharge this invoice.`}
                      />
                    ))}
                  <Badge
                    id={`invoice-${id}`}
                    className={`font-size-12 ${
                      paid ? "badge-soft-success" : "badge-soft-danger"
                    }`}
                    color={`${!paid ? "danger" : ""}`}
                    pill
                  >
                    {paid ? "Paid" : "Retry"}
                  </Badge>
                </td>
                <td className="text-center">
                  <CustomTooltip
                    description="Download Invoice in PDF"
                    target={`downloadPDF-${index}`}
                  />
                  <a
                    id={`downloadPDF-${index}`}
                    href={invoice_pdf}
                    target="_blank"
                    className="btn p-0"
                    rel="noreferrer"
                  >
                    <i className="bx bxs-download fs-5"></i>
                  </a>
                </td>
              </tr>
            )
          )
        )}
      </tbody>
    </Table>
  )
}

export default RecentInvoiceTable
