/* eslint-disable react/prop-types */
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js"
import React from "react"
import { Col, Input, Label, Row } from "reactstrap"

const StripeCardElement = ({ name, setName, handleChange }) => {
  return (
    <>
      <div className="mb-3">
        <Label htmlFor="formrow-firstname-Input">FULL NAME</Label>
        <Input
          type="text"
          value={name}
          className="form-control"
          id="formrow-firstname-Input"
          placeholder="First and last name"
          onChange={e => setName(e.target.value)}
        />
      </div>
      <div id="card-section" className="mb-3">
        <Row>
          <Col>
            <Label htmlFor="card-element">Card Number</Label>
            <div className="form-control">
              <CardNumberElement onChange={handleChange} />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md="6">
            <Label htmlFor="card-expiry">Expiry Date</Label>
            <div className="form-control">
              <CardExpiryElement onChange={handleChange} />
            </div>
          </Col>
          <Col md="6">
            <Label htmlFor="card-cvc">CVC</Label>
            <div className="form-control">
              <CardCvcElement onChange={handleChange} />
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default StripeCardElement
