import React from "react"
import { Link } from "react-router-dom"
import { Card } from "reactstrap"
import PropTypes from "prop-types"
import moment from "moment"

const BlogCard = ({
  id,
  title,
  createdAt,
  thumbnail,
  preview,
  content,
  handleClick,
  ...rest
}) => {
  return (
    <Card
      onClick={handleClick}
      className="p-1 border shadow-sm rounded-3"
      style={{ minHeight: "350px", cursor: "pointer" }}
    >
      <div className="p-3">
        <h5 className="text-dark">{title}</h5>
        <p className="text-muted mb-0">
          {moment(createdAt).format("MMM Do YYYY")}
        </p>
      </div>

      <div className="position-relative">
        <img src={thumbnail} alt="" className="img-fluid" />
      </div>

      <div className="p-3">
        <p className="text-truncate">{preview}</p>

        <div>
          <Link
            to={{
              pathname: `/blog/${title}`,
            }}
            className="text-primary"
          >
            Read more <i className="mdi mdi-arrow-right"></i>
          </Link>
        </div>
      </div>
    </Card>
  )
}

BlogCard.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.any.isRequired,
  thumbnail: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  preview: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
}

export default BlogCard
