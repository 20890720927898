/* eslint-disable react/prop-types */
import React, { useEffect } from "react"
import ReactSelect from "react-select"
import { FormFeedback } from "reactstrap"

import { Spinner } from "components"
import ReactstrapInput from "components/Inputs/ReactstrapInput"

import {
  collectionDropdownOptions,
  databaseDropdownOptions,
} from "utils/helpers"
import useMongoInputForm from "./useMongoInputForm"

const MongoInputForm = ({ configurationData, userInputs, setUserInputs }) => {
  const {
    collections,
    databases,
    error,
    isDisable,
    isLoading,
    status,
    collectionNames,
    setCollections,
    setCollectionNames,
    setDatabases,
    setSelectedCollections,
    _SetError,
    setIsDisable,
    setStatus,
    getCollections,
    handleCollections,
    validateDbConnectionAndGetDatabases,
  } = useMongoInputForm({ userInputs, setUserInputs })

  useEffect(() => {
    setIsDisable(true)
    setCollections(
      setUserInputs({
        ...userInputs,
        collections: [],
      })
    )
    setDatabases([])
    setCollectionNames([])
    setSelectedCollections([])
  }, [configurationData?.activity?.name])
  return (
    <>
      {configurationData?.activity?.requiredInputs.map(
        (requiredInput, index) => {
          return (
            <div className="mb-3" key={index}>
              {requiredInput.Key === "url" ? (
                <div className="d-flex flex-row w-100">
                  <div className="w-100 me-2">
                    <ReactstrapInput
                      className={`form-control ${
                        status === null
                          ? ""
                          : status === true
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      name={requiredInput.Key}
                      value={userInputs[requiredInput.Key] || ""}
                      placeholder={
                        requiredInput.isRequired === true
                          ? requiredInput.Label + "*"
                          : requiredInput.Label
                      }
                      onChange={e => {
                        if (!e.target.value) setStatus(false)
                        if (!e.target.value) _SetError()
                        setUserInputs({
                          ...userInputs,
                          [requiredInput.Key]: e.target.value.trim(),
                        })
                      }}
                    />
                    {error && <FormFeedback invalid>{error}</FormFeedback>}
                  </div>
                  <div className="">
                    <button
                      disabled={!userInputs?.url || isLoading ? true : false}
                      className="d-flex btn btn-primary align-items-center"
                      onClick={() =>
                        validateDbConnectionAndGetDatabases(userInputs.url)
                      }
                    >
                      <span>Validate</span> {isLoading && <Spinner />}
                    </button>
                  </div>
                </div>
              ) : requiredInput.Key === "database" ? (
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder={
                    requiredInput.isRequired
                      ? `${requiredInput.Label}*`
                      : requiredInput.Label
                  }
                  isDisabled={isDisable}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  menuPlacement="auto"
                  name={requiredInput.Key}
                  options={databaseDropdownOptions(databases)}
                  onChange={selected => {
                    getCollections({
                      key: requiredInput.Key,
                      value: !selected ? "" : selected.value.trim(),
                    })
                  }}
                />
              ) : requiredInput.Key === "collection" ? (
                <div className="d-flex flex-column w-100">
                  <p className="p-0 mb-1">
                    Select from already created collections
                  </p>
                  <ReactSelect
                    className="multi"
                    classNamePrefix="select"
                    placeholder={
                      requiredInput.isRequired
                        ? `${requiredInput.Label}*`
                        : requiredInput.Label
                    }
                    isDisabled={isDisable}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    menuPlacement="auto"
                    isMulti
                    value={collectionNames ? collectionNames : null}
                    closeMenuOnSelect={false}
                    name={requiredInput.Key}
                    options={collectionDropdownOptions(collections)}
                    onChange={selected =>
                      handleCollections({
                        key: requiredInput.Key,
                        value: selected,
                        type: "select",
                      })
                    }
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          )
        }
      )}
    </>
  )
}

export default MongoInputForm
