import { useState } from "react"

const INITIAL_STATES = {
  visible: false,
  color: "",
  message: "",
}

const UseAlert = () => {
  const [alert, setAlert] = useState(INITIAL_STATES)

  const enableAlert = data => {
    setAlert(data)
  }

  const disableAlert = () => {
    setAlert(INITIAL_STATES)
  }

  return {
    alert,
    enableAlert,
    disableAlert,
  }
}

export default UseAlert
