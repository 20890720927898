/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Col, FormFeedback, Row } from "reactstrap"
import Select from "react-select"
import { compact } from "lodash"

import { Spinner } from "components"
import TextField from "components/Inputs/textField"
import ReactstrapInput from "components/Inputs/ReactstrapInput"

import useSequel from "hooks/useSequelDB"
import { tableDropdownOptions } from "utils/helpers"
import { ActivitiesEnum } from "utils/enums"

const SequelOutputsForm = ({
  configuration,
  configurationData,
  userOutputs,
  userInputs,
  setUserOutputs,
  sequelTreeData,
}) => {
  const {
    status,
    isLoading,
    isDisable,
    tables,
    error,
    setStatus,
    _SetError,
    getTables,
    validateDbConnection,
    setTables,
    setIsDisable,
  } = useSequel({
    type: "output",
    conversionName: configurationData?.activity.name,
  })
  // tables to show in multi select input
  const [tableNames, setTableNames] = useState(null)
  // selected data to send to backend
  const [isDynamicOutput, setIsDynamicOutput] = useState(false)
  const [dynamicTables, setDynamicTables] = useState([])

  useEffect(() => {
    const remainingDynamicTables = []
    userOutputs &&
      "table" in userOutputs &&
      Array.isArray(userOutputs?.table) &&
      userOutputs?.table?.forEach(({ inputTableId }) => {
        dynamicTables?.forEach((dynamicInput, index) => {
          if (dynamicInput?.inputTableId === inputTableId) {
            remainingDynamicTables[index] = dynamicInput
          }
        })
      })
    setDynamicTables([...remainingDynamicTables])
  }, [userOutputs])

  useEffect(() => {
    setTableNames(
      setUserOutputs({
        ...userOutputs,
        table: [],
      })
    )
    setTables([])
    setIsDisable(true)
  }, [configurationData?.activity?.name])

  const validateDbConnectionAndGetTables = async connectionURL => {
    await getTables(connectionURL)
  }

  const handleTables = ({ key, value: selected, type }) => {
    function filterValues(tables) {
      return tables.map(({ value }) => value.trim())
    }
    if (type === "input") {
      setTableNames(null)
      setUserOutputs({
        ...userOutputs,
        [key]: !selected ? "" : selected.trim(),
      })
    } else if (type === "select") {
      setIsDynamicOutput(false)
      setDynamicTables([])
      // handle value to display in field
      const allSelected = selected.find(option => option.value === "all")
      const remainingTables = tableDropdownOptions(tables).slice(2)
      allSelected ? setTableNames(remainingTables) : setTableNames(selected)

      //handle value to send to backend
      const values = allSelected
        ? filterValues(remainingTables).join(",")
        : filterValues(selected).join(",")
      setUserOutputs({
        ...userOutputs,
        [key]: !values ? "" : values.trim(),
      })
    }
  }

  const handleDynamicOutputs = checked => {
    setIsDynamicOutput(checked)
    setUserOutputs({
      ...userOutputs,
      table: [],
    })
    setDynamicTables([])
    setTableNames(null)
  }

  const handleDynamicTables = ({ value, inputTableId, index }) => {
    const data = {
      table: value,
      inputTableId,
    }
    setDynamicTables(tables => {
      tables[index] = value ? data : null
      setUserOutputs({
        ...userOutputs,
        table: compact(tables),
      })
      return tables
    })
  }

  return (
    <>
      {configurationData?.activity?.requiredOutputs.map(
        (requiredOutput, index) => {
          return (
            <div className="mb-3" key={index}>
              {requiredOutput.Key === "dsn" ? (
                <div className="d-flex flex-row w-100">
                  <div className="w-100 me-2">
                    <ReactstrapInput
                      className={`form-control  ${
                        status === null
                          ? ""
                          : status === true
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      name={requiredOutput.Key}
                      value={userOutputs[requiredOutput.Key] || ""}
                      placeholder={
                        requiredOutput.isRequired === true
                          ? requiredOutput.Label + "*"
                          : requiredOutput.Label
                      }
                      onChange={e => {
                        if (!e.target.value) setStatus(false)
                        if (!e.target.value) _SetError()
                        setUserOutputs({
                          ...userOutputs,
                          [requiredOutput.Key]: e.target.value.trim(),
                        })
                      }}
                    />
                    {error && <FormFeedback invalid>{error}</FormFeedback>}
                  </div>
                  <div className="">
                    <button
                      disabled={!userOutputs?.dsn || isLoading ? true : false}
                      className="d-flex btn btn-primary align-items-center"
                      onClick={() =>
                        validateDbConnectionAndGetTables(userOutputs.dsn)
                      }
                    >
                      <span>Validate</span> {isLoading && <Spinner />}
                    </button>
                  </div>
                </div>
              ) : requiredOutput.Key === "table" ? (
                <div className="d-flex flex-column w-100">
                  <p className="p-0 mb-1">Select from already created tables</p>
                  <Select
                    className="multi"
                    classNamePrefix="select"
                    placeholder={
                      requiredOutput.isRequired
                        ? `${requiredOutput.Label}*`
                        : requiredOutput.Label
                    }
                    isDisabled={isDisable}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    menuPlacement="auto"
                    isMulti
                    value={tableNames ? tableNames : null}
                    closeMenuOnSelect={false}
                    name={requiredOutput.Key}
                    options={tableDropdownOptions(tables)}
                    onChange={selected =>
                      handleTables({
                        key: requiredOutput.Key,
                        value: selected,
                        type: "select",
                      })
                    }
                  />
                  {[
                    ActivitiesEnum.PostgresToPostgres,
                    ActivitiesEnum.MySQLToMySQL,
                  ].includes(configuration) && (
                    <>
                      <p className="text-center w-100 my-1">OR</p>
                      <div className="form-check mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={isDynamicOutput}
                          disabled={isDisable}
                          id="defaultCheck2"
                          onChange={event =>
                            handleDynamicOutputs(event.target.checked)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck2"
                        >
                          Dynamic Tables
                        </label>
                      </div>
                      {isDynamicOutput &&
                        userInputs?.columns?.map(column =>
                          sequelTreeData?.map(
                            ({ name, id }, index) =>
                              name === column.table && (
                                <Row
                                  key={column.table + index}
                                  className="d-flex mb-2 align-items-center"
                                >
                                  <Col md={4} className=" text-start">
                                    <p>{column.table}</p>
                                  </Col>
                                  <Col md={2}>
                                    <p>to</p>
                                  </Col>
                                  <Col md={6}>
                                    <ReactstrapInput
                                      className={`form-control`}
                                      placeholder="Create new table"
                                      value={dynamicTables[index]?.table}
                                      onChange={e => {
                                        handleDynamicTables({
                                          value: e.target.value.trim(),
                                          inputTable: column.table,
                                          inputTableId: column.id,
                                          index,
                                        })
                                      }}
                                    />
                                  </Col>
                                </Row>
                              )
                          )
                        )}
                    </>
                  )}
                </div>
              ) : (
                <TextField
                  className="form-control"
                  type="text"
                  name={requiredOutput.Key}
                  placeholder="Enter Table name"
                  disabled={isDisable}
                  onChange={e => {
                    setUserOutputs({
                      ...userOutputs,
                      [requiredOutput.Key]: e.target.value.trim(),
                    })
                  }}
                />
              )}
            </div>
          )
        }
      )}
    </>
  )
}

export default SequelOutputsForm
