import React from "react"
import { Container, Row } from "reactstrap"

import FeatureCard from "./FeatureCard"

import FEATURES from "utils/mocks/SelfHostedFeatures"

import StyledHeader from "components/StyledHeader"

const FeatureSection = () => {
  return (
    <section className="py-4 d-flex flex-column bg-light">
      <StyledHeader name="Features" />
      <Container className="pt-4 px-4">
        <Row className="d-flex justify-content-center">
          {FEATURES.map(
            ({ title, description, image, alternate, list }, index) => (
              <FeatureCard
                key={index}
                title={title}
                list={list}
                description={description}
                image={image}
                alternate={alternate}
              />
            )
          )}
        </Row>
      </Container>
    </section>
  )
}

export default FeatureSection
