import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import { Col, Container, Row } from "reactstrap"

import SubscriptionCard from "./subscriptionCard"
import WelcomeComp from "./welcomeComp"
import UserUpdateForm from "./userUpdateForm"
import { ENUMS } from "utils/enums"

const Profile = () => {
  const [greetingMsg] = useState(() => {
    let today = new Date()
    let curHr = today.getHours()
    if (curHr < 12) {
      return "Good Morning"
    } else if (curHr < 18) {
      return "Good Afternoon"
    } else {
      return "Good Evening"
    }
  })

  return (
    <div className="page-content">
      <Helmet>
        <title>Profile | {ENUMS.BRAND_NAME}</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col>
            <WelcomeComp text={greetingMsg} />
          </Col>
        </Row>
        <Row>
          <Col>
            <UserUpdateForm />
          </Col>
        </Row>
        <Row>
          <Col>
            <SubscriptionCard />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Profile
