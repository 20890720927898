import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const MySQLToSnowflakeBlog = () => {
  const data = tags.find(items => items.blogName === "mysqlSnowflake")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        As organizations evolve and embrace cloud-native solutions, the need to
        migrate data from traditional databases like MySQL to modern cloud-based
        data warehouses like Snowflake becomes increasingly crucial. MySQL, a
        popular open-source relational database, has long been a stalwart for
        various applications. However, as organizations scale and require
        advanced analytics capabilities, migrating to Snowflake, a cloud-based
        data warehousing platform, becomes a strategic move. Snowflake offers
        scalability, flexibility, and ease of use, making it an ideal
        destination for organizations looking to harness the power of
        cloud-native data warehousing.
      </p>
      <h4>MySQL</h4>
      <p>
        MySQL is a popular open-source relational database management system. It
        is known for its stability, reliability, and performance in handling
        structured data. MySQL employs a table-based data storage model with
        ACID compliance, making it ideal for scenarios where data consistency
        and integrity are paramount.
      </p>
      <h4>Snowflake</h4>
      <p>
        Snowflake is a cloud-based data warehousing platform designed to handle
        large volumes of structured and semi-structured data. It provides a
        fully managed and scalable solution for storing, processing, and
        analyzing data in the cloud. Snowflake is known for its unique
        architecture, ease of use, and support for a wide range of data
        analytics and business intelligence applications.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source orchestration platform that enables you to
        build and manage complex workflows. It is designed to handle
        long-running and stateful processes, making it a powerful tool for
        orchestrating data migration and synchronization tasks.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos is a versatile open-source data processing tool that excels at
        connecting various data sources and sinks. It offers a range of
        processors and connectors for data transformation, enrichment, and
        routing, making it an excellent tool for creating data pipelines.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on the <b>Choose Input Conversion</b> dropdown and click on{" "}
            <b>MySQL</b> and then click on <b>Choose Conversion</b> dropdown and
            click on <b>MySQL to Snowflake</b>.
          </p>
          <img
            src="/assets/images/mysql/mysql-to-snowflake/snip-1.png"
            alt="Mysql to Mysql conversion select"
          />
        </li>
        <br />
        <li>
          <b>Validate the DB URL</b>
          <p>
            Enter the URL of the MySQL database and click on validate button.
          </p>
          <img src="/assets/images/mysql/mysql-to-snowflake/snip-2.png" />
        </li>
        <br />
        <li>
          <b>Select Columns and WHERE clause</b>
          <p>
            Select the Columns from the tables shown below and then choose the
            WHERE clause.
          </p>
          <img src="/assets/images/mysql/mysql-to-snowflake/snip-3.png" />
        </li>
        <br />
        <li>
          <b>Validate Output DB URL</b>
          <p>
            Enter the URL of the Snowflake database and click on validate
            button.
          </p>
          <img src="/assets/images/mysql/mysql-to-snowflake/snip-4.png" />
        </li>
        <br />
        <li>
          <b>Select output Table</b>
          <p>
            Select the output table from the list provided below. If you do not
            see the tables, kindly ensure that you have entered the correct
            database URL or verify that your database indeed contains tables.
          </p>
          <img src="/assets/images/mysql/mysql-to-snowflake/snip-5.png" />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>
            Now we need to run the migration by clicking on Save & Run button
          </p>
          <img src="/assets/images/mysql/mysql-to-snowflake/snip-6.png" />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/mysql/mysql-to-snowflake/activity.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default MySQLToSnowflakeBlog
