import React from "react"
import { Table } from "reactstrap"
import PropTypes from "prop-types"

const ActivityInfoTable = ({
  name,
  isCron,
  inputName,
  outputName,
  ...rest
}) => {
  return (
    <Table borderless responsive>
      <tbody>
        <tr>
          <td> Activity Name </td>
          <td> {name} </td>
        </tr>
        <tr>
          <td> isCron </td>
          <td> {isCron.toString()} </td>
        </tr>
        <tr>
          <td> Input Name </td>
          <td> {inputName} </td>
        </tr>
        <tr>
          <td> Output Name </td>
          <td> {outputName} </td>
        </tr>
      </tbody>
    </Table>
  )
}

ActivityInfoTable.propTypes = {
  name: PropTypes.string.isRequired,
  isCron: PropTypes.bool.isRequired,
  inputName: PropTypes.string.isRequired,
  outputName: PropTypes.string.isRequired,
}

export default ActivityInfoTable
