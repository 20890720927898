/* eslint-disable react/prop-types */
import React from "react"
import { FormFeedback } from "reactstrap"

import ReactstrapInput from "components/Inputs/ReactstrapInput"
import { Spinner } from "components"
import UseKafka from "hooks/useKafka"

const KafkaOutputsForm = ({
  configurationData,
  userOutputs,
  setUserOutputs,
}) => {
  const {
    error,
    status,
    isDisabled,
    isLoading,
    validateHosts,
    setStatus,
    _SetError,
  } = UseKafka()

  return (
    <>
      {configurationData?.activity?.requiredOutputs.map(
        (requiredOutput, index) => {
          return (
            <div className="mb-3" key={index}>
              {requiredOutput.Key === "seed_brokers" ? (
                <div className="d-flex flex-row w-100">
                  <div className="w-100 me-2">
                    <ReactstrapInput
                      className={`form-control  ${
                        status === null
                          ? ""
                          : status === true
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      type={requiredOutput.Type}
                      name={requiredOutput.Key}
                      value={userOutputs[requiredOutput.Key] || ""}
                      placeholder={
                        requiredOutput.isRequired === true
                          ? requiredOutput.Label + "*"
                          : requiredOutput.Label
                      }
                      onChange={e => {
                        if (!e.target.value) setStatus(false)
                        if (!e.target.value) _SetError()
                        setUserOutputs({
                          ...userOutputs,
                          [requiredOutput.Key]: e.target.value.trim(),
                        })
                      }}
                    />
                    {error && <FormFeedback invalid>{error}</FormFeedback>}
                  </div>
                  <div className="">
                    <button
                      disabled={
                        !userOutputs?.seed_brokers || isLoading ? true : false
                      }
                      className="d-flex btn btn-primary align-items-center"
                      onClick={() =>
                        validateHosts({
                          seedBrokers: userOutputs.seed_brokers,
                          role: "consumer",
                        })
                      }
                    >
                      <span>Validate</span> {isLoading && <Spinner />}
                    </button>
                  </div>
                </div>
              ) : (
                <input
                  className="form-control"
                  type={requiredOutput.Type}
                  name={requiredOutput.Key}
                  placeholder={
                    requiredOutput.isRequired === true
                      ? requiredOutput.Label + "*"
                      : requiredOutput.Label
                  }
                  disabled={isDisabled}
                  onChange={e => {
                    setUserOutputs({
                      ...userOutputs,
                      [requiredOutput.Key]: e.target.value.trim(),
                    })
                  }}
                />
              )}
            </div>
          )
        }
      )}
    </>
  )
}

export default KafkaOutputsForm
