/* eslint-disable react/prop-types */
import React from "react"
import { Table } from "reactstrap"

const ActivityInputTable = ({ data, inputName }) => {
  console.log({ inputName, data })
  return (
    <Table bordered responsive>
      <thead>
        <tr>
          {Object.entries(data).map(
            ([key, value]) =>
              (typeof value !== "object" || Array.isArray(value)) && (
                <td key={key}>{key}</td>
              )
          )}
        </tr>
      </thead>
      <tbody>
        <tr>
          {Object.entries(data).map(([key, value]) => {
            return Array.isArray(value) ? (
              inputName === "sql_select" ? (
                <td>
                  {value?.map(
                    ({ name: whereClauseColumn, columns, id }, index) => {
                      return whereClauseColumn ? (
                        <ul
                          style={{ listStyle: "none", padding: 0, margin: 0 }}
                        >
                          <li>
                            {`${index + 1})`} {whereClauseColumn}
                          </li>
                        </ul>
                      ) : (
                        <>
                          <p>{`${index + 1})`}</p>
                          <ol type="a">
                            {columns.map(column => (
                              <li key={id}>{column}</li>
                            ))}
                            <hr className="w-100" />
                          </ol>
                        </>
                      )
                    }
                  )}
                </td>
              ) : (
                ""
              )
            ) : typeof value !== "object" ? (
              key === "table" ? (
                <td>
                  <ol style={{ listStyle: "none" }}>
                    {value?.split(",").map((table, index) => (
                      <li key={table}>
                        {`${index + 1})`} {table}
                      </li>
                    ))}
                  </ol>
                </td>
              ) : (
                <td>{value}</td>
              )
            ) : (
              ""
            )
          })}
        </tr>
      </tbody>
    </Table>
  )
}

export default ActivityInputTable
