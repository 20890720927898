import React from "react"
import { Button, Col, Row } from "reactstrap"
import PropTypes from "prop-types"

import { AlertBar, Spinner } from "components"

import UsePaymentForm from "./usePaymentForm"

import StripeCardElement from "./StripeCardElement"
import Notes from "./Notes"

const PaymentForm = ({ plan = {}, onCancel, modalType = "" }) => {
  const {
    togglePaymentSection,
    handleUpdateSubscription,
    createSubscription,
    handleChange,
    setName,
    disableAlert,
    name,
    showPaymentSection,
    complete,
    alert,
    isLoading,
  } = UsePaymentForm({ onCancel })
  return (
    <>
      <AlertBar
        color={alert?.color}
        disableAlert={disableAlert}
        showAlert={alert?.visible}
      >
        {alert?.message}
      </AlertBar>
      {modalType === "create" ? (
        <div className="pb-3 d-flex flex-column">
          <span>
            <i className="bx bx-right-arrow-alt"></i> Total due now{" "}
            <b>
              ${plan?.price} / {plan?.interval}
            </b>
          </span>
          <span>
            <i className="bx bx-right-arrow-alt"></i> Subscribing to:{" "}
            <b>{plan?.name}</b>
          </span>
        </div>
      ) : (
        <p>
          {" "}
          Your subscription will be changed to{" "}
          <b>
            {plan?.name} Plan USD ${plan?.price} / {plan?.interval}
          </b>
        </p>
      )}
      {modalType === "create" ? (
        <form
          id="payment-form"
          onSubmit={event => createSubscription(event, plan)}
          className=" border shadow-md rounded p-3 bg-light"
        >
          <StripeCardElement
            name={name}
            setName={setName}
            handleChange={handleChange}
          />
          <div className="d-flex flex-column">
            <Button
              color="primary"
              type="submit"
              disabled={
                isLoading
                  ? true
                  : !complete.cardCvc ||
                    !complete.cardExpiry ||
                    !complete.cardNumber
                  ? true
                  : false
              }
              className="d-flex mt-3 align-items-center justify-content-center"
            >
              {isLoading ? <Spinner /> : "Subscribe"}
            </Button>
          </div>
        </form>
      ) : (
        <>
          <Row>
            <Col>
              <Notes />
            </Col>
          </Row>
          <form
            id="payment-form"
            onSubmit={event => handleUpdateSubscription(event, plan)}
          >
            <button
              onClick={togglePaymentSection}
              type="button"
              className={`btn ${
                showPaymentSection ? "btn-dark" : "btn-outline-dark"
              } btn-sm`}
            >
              Add New Payment Method
            </button>
            {showPaymentSection && (
              <div className=" border shadow-md rounded p-3 mt-3 bg-light">
                <div className="d-flex flex-column">
                  <StripeCardElement
                    name={name}
                    setName={setName}
                    handleChange={handleChange}
                  />
                </div>
              </div>
            )}
            <Button
              color="primary"
              type="submit"
              disabled={
                isLoading
                  ? true
                  : showPaymentSection &&
                    (!complete.cardCvc ||
                      !complete.cardExpiry ||
                      !complete.cardNumber)
                  ? true
                  : false
              }
              className="d-flex mt-3 w-100 align-items-center justify-content-center"
            >
              {isLoading ? <Spinner /> : "Update Now"}
            </Button>
          </form>
        </>
      )}
    </>
  )
}

PaymentForm.propTypes = {
  plan: PropTypes.object,
  onCancel: PropTypes.func,
  modalType: PropTypes.string,
}

export default PaymentForm
