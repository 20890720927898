import React from "react"
import { Helmet } from "react-helmet-async"

import { ENUMS } from "utils/enums"
import tags from "../../seo-tags.json"

const RedisToPostgresBlog = () => {
  const data = tags.find(items => items.blogName === "redisPostgres")
  return (
    <>
      <Helmet>
        <title>
          {data.title} - Blog | {ENUMS.BRAND_NAME}
        </title>
        <meta name={data.title} content={data.content} />
        <meta name="og:locale" content={data.og_locale} />
        <meta name="og:description" content={data.og_description} />
        <meta name="og:site_name" content={data.og_site_name} />
        <meta name="og:type" content={data.og_type} />
      </Helmet>
      <h4>Introduction</h4>
      <p>
        In the dynamic landscape of data management, combining the strengths of
        different data storage systems can offer significant advantages. Redis,
        a lightning-fast in-memory data store, and PostgreSQL, a powerful
        open-source relational database, cater to distinct needs. By integrating
        Redis with PostgreSQL, businesses can achieve a balance between
        real-time data processing and persistent storage. In this blog, we will
        explore how to seamlessly connect Redis to PostgreSQL for efficient data
        integration and analysis.
      </p>
      <h4>Redis</h4>
      <p>
        Redis is an open-source, in-memory data structure store known for its
        speed and flexibility. It supports various data types, such as strings,
        lists, sets, and hashes, making it suitable for caching, real-time
        analytics, and messaging.
      </p>
      <h4>Postgres</h4>
      <p>
        PostgreSQL is one of the most widely used relational database management
        systems. As your business grows, you may need to migrate your data to a
        new PostgreSQL database. This process can be complicated, but using
        Temporal and Benthos on Node.js can simplify the process and make it
        more efficient.
      </p>
      <h4>Temporal</h4>
      <p>
        Temporal is an open-source framework for building, testing, and
        deploying distributed systems. It provides developers with an easy way
        to manage workflows, maintain states, and handle errors. Temporal is
        particularly useful for managing long-running processes and complex,
        multi-step workflows.
      </p>
      <h4>Benthos</h4>
      <p>
        Benthos, on the other hand, is a powerful data streaming tool that can
        be used to connect various data sources and sinks. It provides a lot of
        flexibility in terms of data processing, transformation, and routing.
      </p>
      <p>
        Using Temporal and Benthos together, we can create a robust and reliable
        data migration pipeline. Let&lsquo;s take a closer look at how this
        works.
      </p>
      <br />
      <h4>Steps</h4>
      <ol>
        <li>
          <b>Select the Conversion</b>
          <p>
            Click on the dropdown and select your desired conversion. In this
            case, we are converting from Redis to Postgres.
          </p>
          <img
            src="/assets/images/redis/redis-to-postgres/snip-1.png"
            alt="Redis to postgres conversion select"
          />
        </li>
        <br />
        <li>
          <b>Validate the DB URL</b>
          <p>
            In this step, we will validate the URL of our redis server. Enter
            the URL of the server and click on validate button.
          </p>
          <img src="/assets/images/redis/redis-to-postgres/snip-2.png" />
        </li>
        <br />
        <li>
          <b>Select Channels</b>
          <p>Choose the channel as shown below.</p>
          <img src="/assets/images/redis/redis-to-postgres/snip-3.png" />
        </li>
        <br />
        <li>
          <b>Validate Output DB URL</b>
          <p>
            In this step, we will validate the URL of Postgres database. Enter
            the URL of the database and click on validate button.
          </p>
          <img src="/assets/images/redis/redis-to-postgres/snip-4.png" />
        </li>
        <br />
        <li>
          <b>Select output table</b>
          <p>Select table from the dropdown menu by clicking on it.</p>
          <img src="/assets/images/redis/redis-to-postgres/snip-5.png" />
        </li>
        <br />
        <li>
          <b>Running the Migration</b>
          <p>Now we need to run the migration by clicking on Save & Run</p>
          <img src="/assets/images/redis/redis-to-postgres/snip-6.png" />
        </li>
        <br />
        <li>
          <b>Checking the Activity Logs</b>
          <p></p>
          <img
            src="/assets/images/redis/redis-to-postgres/activity.png"
            width={"600px"}
          />
        </li>
      </ol>
    </>
  )
}

export default RedisToPostgresBlog
