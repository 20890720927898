import KafkaToKafkaBlog from "blogs/kafka/KafkaToKafka"
import KafkaToPostgresBlog from "blogs/kafka/KafkaToPostgres"

import MySQLToMongoDbBlog from "blogs/mysql/MySQLToMongoDb"
import MySQLToMySQLBlog from "blogs/mysql/MySQLToMySQL"
import MySQLToBigqueryBlog from "blogs/mysql/MySQLToBigquery"
import MySQLToSnowflakeBlog from "blogs/mysql/MySQLToSnowflake"
import MySQLToRedshiftBlog from "blogs/mysql/MySQLToRedshift"

import PostgresToMongoDbBlog from "blogs/postgres/PostgresToMongoDb"
import PostgresToPostgres from "blogs/postgres/PostgresToPostgres"
import PostgresToSnowflakeBlog from "blogs/postgres/PostgresToSnowflake"
import PostgresToBigqueryBlog from "blogs/postgres/PostgresToBigquery"
import PostgresToRedshiftBlog from "blogs/postgres/PostgresToRedshift"

import RedisToPostgresBlog from "blogs/redis/RedisToPostgres"
import RedisToRedisBlog from "blogs/redis/RedisToRedis"

import MongoDbToSnowflakeBlog from "blogs/mongodb/MongoDbToSnowflake"
import MongoDbToBigqueryBlog from "blogs/mongodb/MongoDbToBigquery"
import MongoDbToRedshiftBlog from "blogs/mongodb/MongoDbToRedshift"

import RedshiftToPostgresBlog from "blogs/redshift/RedshiftToPostgres"
import RedshiftToMongoDbBlog from "blogs/redshift/RedshiftToMongoDb"
import RedshiftToMySQLBlog from "blogs/redshift/RedshiftToMySQL"

import SnowflakeToPostgresBlog from "blogs/snowflake/SnowflakeToPostgres"
import SnowflakeToMongoDbBlog from "blogs/snowflake/SnowflakeToMongoDb"
import SnowflakeToMySQLBlog from "blogs/snowflake/SnowflakeToMySQL"

import tags from "../../seo-tags.json"

const components = {
  "kafka-to-kafka": KafkaToKafkaBlog,
  "kafka-to-postgres": KafkaToPostgresBlog,
  "redis-to-postgres": RedisToPostgresBlog,
  "redis-to-redis": RedisToRedisBlog,
  "mysql-to-mysql": MySQLToMySQLBlog,
  "mysql-to-mongodb": MySQLToMongoDbBlog,
  "mysql-to-snowflake": MySQLToSnowflakeBlog,
  "mysql-to-bigquery": MySQLToBigqueryBlog,
  "mysql-to-redshift": MySQLToRedshiftBlog,
  "postgres-to-mongodb": PostgresToMongoDbBlog,
  "postgres-to-postgres": PostgresToPostgres,
  "postgres-to-snowflake": PostgresToSnowflakeBlog,
  "postgres-to-redshift": PostgresToRedshiftBlog,
  "postgres-to-bigquery": PostgresToBigqueryBlog,
  "mongodb-to-snowflake": MongoDbToSnowflakeBlog,
  "mongodb-to-bigquery": MongoDbToBigqueryBlog,
  "mongodb-to-redshift": MongoDbToRedshiftBlog,
  "redshift-to-postgres": RedshiftToPostgresBlog,
  "redshift-to-mongodb": RedshiftToMongoDbBlog,
  "redshift-to-mysql": RedshiftToMySQLBlog,
  "snowflake-to-postgres": SnowflakeToPostgresBlog,
  "snowflake-to-mongodb": SnowflakeToMongoDbBlog,
  "snowflake-to-mysql": SnowflakeToMySQLBlog,
}

const BLOGS = tags.map((value, index) => ({
  id: index + 1,
  title: value.title,
  preview: value.content,
  content: components[value.title],
  thumbnail: value.thumbnail,
  createdAt: new Date(value.date),
}))

export default BLOGS
