/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from "react"
import { isEmpty } from "lodash"

import { WHERE_CONDITIONS } from "utils/helpers"
import { useBigquery } from "hooks"

const useBigqueryInputForm = ({
  userInputs,
  userOutputs,
  setUserInputs,
  setUserOutputs,
  outputName,
  setSequelWhereTreeData,
}) => {
  const {
    status,
    isLoading,
    columns,
    setStatus,
    _SetError,
    error,
    getColumns,
    isFetchingColumns,
  } = useBigquery({ type: "input" })

  const handleValidateAndFetchColumns = useCallback(async connectionURL => {
    await getColumns(connectionURL)
  }, [])

  const handleMultiSelectedColumns = (selectedIds, data) => {
    // return when no id is selected
    if (!selectedIds || selectedIds.length < 1) {
      // clear table from state when no id is selected
      ;["Postgres", "MySQL"].includes(outputName)
        ? delete userOutputs?.table
        : outputName === "MongoDB"
        ? delete userOutputs?.collection
        : ""
    }
    let updatedWhereClause = []
    let updatedOutputTablesOrCollections = []
    const DEEP_COPY_DATA = [...data] //elements of tree including tablename and columns
    const TABLE_IDS = DEEP_COPY_DATA[0]?.children //tableIds in tree
    let selectedColumns = [],
      tables = []

    TABLE_IDS.map(tableId => {
      DEEP_COPY_DATA.map(({ children, id, name, parent }) => {
        if (tableId === id) {
          const columns = []
          const table = name
          const columnIds = children
          DEEP_COPY_DATA.map(item => {
            if (columnIds.includes(item.id) && selectedIds.includes(item.id)) {
              columns.push(item.name)
            }
          })
          if (!isEmpty(columns)) {
            selectedColumns.push({ table, columns, id, children, parent })
            tables.push([
              (!userInputs?.table ? userInputs?.table?.split(",") || "" : "") +
                table,
            ])
          }
        }
      })
    })

    createWhereClauseTreeData(DEEP_COPY_DATA, selectedColumns)

    const updatedInputs = { ...userInputs }
    updatedInputs.table = tables.join(",")
    !tables || (tables.length < 1 && delete updatedInputs.table)
    updatedInputs.columns =
      tables?.length === selectedColumns?.length ? selectedColumns : ""

    // removing outputTable when input table is completely unselected
    // filter table from where clause and outputDynamic tables only when input tableis unselected completely
    updatedInputs?.columns?.forEach(({ id, children }) => {
      if (children.length < 1) {
        updatedWhereClause = userInputs?.where?.filter(({ id }) =>
          selectedIds.includes(id)
        )
      }
    })

    // fetch outputTables that have a mapping with input tables and remove the mapping for unselected inputTables.
    userOutputs &&
      ("table" in userOutputs || "collection" in userOutputs) &&
      typeof userOutputs[
        ["Postgres", "MySQL"].includes(outputName)
          ? "table"
          : outputName === "MongoDB"
          ? "collection"
          : ""
      ] !== "string" &&
      userOutputs[
        ["Postgres", "MySQL"].includes(outputName)
          ? "table"
          : outputName === "MongoDB"
          ? "collection"
          : ""
      ]?.forEach(outputTable => {
        const isFound = updatedInputs?.columns?.some(
          ({ id, children }) => id === outputTable?.inputTableId
        )
        if (isFound) {
          updatedOutputTablesOrCollections.push(outputTable)
        }
      })

    updatedInputs.where =
      updatedWhereClause && updatedWhereClause.length > 0
        ? updatedWhereClause
        : userInputs?.where

    setUserInputs({
      ...updatedInputs,
    })
    userOutputs &&
      ("table" in userOutputs || "collection" in userOutputs) &&
      typeof userOutputs[
        ["MySQL", "Postgres"].includes(outputName)
          ? "table"
          : outputName === "MongoDB"
          ? "collection"
          : ""
      ] !== "string" &&
      setUserOutputs(prevState => ({
        ...prevState,
        [["Postgres", "MySQL"].includes(outputName)
          ? "table"
          : outputName === "MongoDB"
          ? "collection"
          : ""]:
          updatedOutputTablesOrCollections &&
          updatedOutputTablesOrCollections.length > 0
            ? updatedOutputTablesOrCollections
            : userOutputs && [
                ...userOutputs[
                  ["Postgres", "MySQL"].includes(outputName)
                    ? "table"
                    : outputName === "MongoDB"
                    ? "collection"
                    : ""
                ],
              ],
      }))
  }

  const handleSingleSelectedColumn = (selectedIds, data) => {
    if (isEmpty(selectedIds)) {
      delete userInputs.where
      return setUserInputs({
        ...userInputs,
      })
    }
    const COLUMNS = []
    const DEEP_COPY_DATA = [...data] //elements of tree including tablename and columns
    DEEP_COPY_DATA.map(({ id, name, type, isPrimaryKey, isUnique, parent }) => {
      if (selectedIds.includes(id)) {
        const column = {
          name,
          type,
          isPrimaryKey,
          isUnique,
          id,
          parent,
        }
        COLUMNS.push(column)
      }
    })
    setUserInputs({
      ...userInputs,
      where: [...COLUMNS],
    })
  }

  const createWhereClauseTreeData = (
    columnInputsFlattenTree,
    selectedColumns
  ) => {
    const parentTableIds = []
    selectedColumns.forEach(({ table }) => {
      columnInputsFlattenTree.forEach(({ id, name }) => {
        if (table === name) {
          parentTableIds.push(id)
        }
      })
    })

    setSequelWhereTreeData({
      whereClauseTreeData: [...columnInputsFlattenTree],
      selectedColumns: [...parentTableIds],
    })
  }

  return {
    WHERE_CONDITIONS,
    status,
    isLoading,
    error,
    columns,
    isFetchingColumns,
    _SetError,
    getColumns,
    handleSingleSelectedColumn,
    handleValidateAndFetchColumns,
    setStatus,
    handleMultiSelectedColumns,
  }
}

export default useBigqueryInputForm
