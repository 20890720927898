import React from "react"
import { useHistory } from "react-router-dom"
import { Card, CardBody } from "reactstrap"
import { isEmpty } from "lodash"

import RecentInvoiceTable from "./RecentInvoiceTable"
import useInvoices from "hooks/useInvoices"

const InvoiceSection = () => {
  const history = useHistory()
  const { invoices, isLoading } = useInvoices({ limit: 3 })

  const NoInvoiceMessage = (
    <div className="d-flex flex-row align-items-center justify-content-center mt-4">
      <p className="text-black Text-fontWeight--600">No Invoices Yet!</p>
    </div>
  )

  const ViewAllBtn = (
    <button
      onClick={() => history.push("/billing/invoices")}
      className="d-flex flex-row btn btn-primary btn-sm"
    >
      View All
    </button>
  )

  return (
    <Card>
      <CardBody>
        <h4 className="card-title">Recent Invoices</h4>
        {!isLoading && isEmpty(invoices) ? (
          NoInvoiceMessage
        ) : (
          <>
            <div className="table-responsive">
              <RecentInvoiceTable
                isLoading={isLoading}
                invoices={invoices.slice(0, 3)}
              />
            </div>
            <div className="d-flex flex-column w-100 mt-3 align-items-center justify-content-center">
              {ViewAllBtn}
            </div>
          </>
        )}
      </CardBody>
    </Card>
  )
}

export default InvoiceSection
