import moment from "moment"
import React from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

import BLOGS from "utils/mocks/Blogs"

const BlogDetails = () => {
  const { blogId } = useParams()

  return (
    <React.Fragment>
      <Container className="mt-5 mb-4">
        <Row>
          <Col lg={12}>
            {BLOGS.map(
              ({
                id,
                title,
                createdAt,
                preview,
                thumbnail,
                content: Content,
              }) => {
                return (
                  title === blogId && (
                    <Card key={id}>
                      <CardBody>
                        <div className="pt-3">
                          <div className="row justify-content-center">
                            <div className="col-xl-8">
                              <div>
                                <div className="text-center">
                                  <h1>{title}</h1>
                                  <p className="text-muted mb-4">
                                    <i className="mdi mdi-calendar me-1"></i>{" "}
                                    {moment(createdAt).format("MMM DD, YYYY")}
                                  </p>
                                </div>

                                <hr />
                                <div className="my-5">
                                  <img
                                    src={thumbnail}
                                    alt={preview}
                                    className="img-thumbnail mx-auto d-block"
                                  />
                                </div>

                                <hr />

                                <div className="mt-4 text-justify">
                                  <Content />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  )
                )
              }
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default BlogDetails
