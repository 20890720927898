import { Placeholder } from "components"
import React from "react"
import { Table } from "reactstrap"

const ActivityLogsSkeleton = () => {
  return (
    <div
      className="col mb-3 logs-container"
      style={{ height: "100%", overflowY: "auto" }}
    >
      <Table bordered responsive>
        <thead>
          <tr>
            <th style={{ minWidth: "300px" }}>UUID</th>
            <th style={{ minWidth: "120px" }}>STATUS</th>
            <th style={{ minWidth: "300px" }}>DETAILS</th>
            <th style={{ minWidth: "300px" }}>DATETIME</th>
          </tr>
        </thead>
        <tbody>
          {Array(5)
            .fill("*")
            .map((item, index) => (
              <tr key={index}>
                <td>
                  <Placeholder />
                </td>
                <td>
                  <Placeholder />
                </td>
                <td>
                  <Placeholder />
                </td>
                <td>
                  <Placeholder />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  )
}

export default ActivityLogsSkeleton
