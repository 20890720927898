import { Placeholder } from "components"
import React from "react"
import { Button, Table } from "reactstrap"

const UserActivitySkeleton = () => {
  return (
    <div className="row">
      <div className="col mb-3">
        <label className="mb-3">Activities</label>
        <Table bordered responsive>
          <thead>
            <tr>
              <th style={{ minWidth: "20px" }}>#</th>
              <th style={{ minWidth: "150px" }}>Name</th>
              <th style={{ minWidth: "100px" }}>Status</th>
              <th style={{ minWidth: "10px" }}>Details</th>
              <th style={{ minWidth: "100px" }}>Activity Logs</th>
              <th style={{ minWidth: "100px" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array(3)
              .fill("*")
              .map((item, index) => (
                <tr key={index} style={{ textAlign: "center" }}>
                  <td>
                    <Placeholder />
                  </td>
                  <td>
                    <Placeholder />
                  </td>
                  <td>
                    <Placeholder />
                  </td>
                  <td>
                    <Placeholder />
                  </td>
                  <td>
                    <Placeholder col="col-5" height="40px" />
                  </td>
                  <td>
                    <Placeholder col="col-5" height="40px" />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default UserActivitySkeleton
