import React from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import PropTypes from "prop-types"

const AlertModal = ({ modal, setModal }) => {
  const {
    shouldButtonDisplay = false,
    shouldModalDisplay = false,
    modalAction = "",
    modalTitle = "",
    modalDescription = "",
  } = modal

  return (
    <div>
      {shouldModalDisplay && (
        <SweetAlert
          warning={modalAction == "warning" ? true : false}
          success={modalAction == "success" ? true : false}
          showConfirm={shouldButtonDisplay}
          title={modalTitle}
          onConfirm={() => {
            setModal({ ...modal, shouldModalDisplay: false })
          }}
        >
          {modalDescription}
        </SweetAlert>
      )}
    </div>
  )
}

AlertModal.propTypes = {
  modal: PropTypes.shape({
    shouldButtonDisplay: PropTypes.bool,
    shouldModalDisplay: PropTypes.bool,
    modalAction: PropTypes.string,
    modalTitle: PropTypes.string,
    modalDescription: PropTypes.string,
  }),
  setModal: PropTypes.func.isRequired,
}

export default AlertModal
