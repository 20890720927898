import { Placeholder } from "components"
import React from "react"
import { Table } from "reactstrap"

const UpcomingInvoiceSkeleton = () => {
  return (
    <div className="table-responsive">
      <Table className="table mb-0">
        <thead>
          <tr>
            <th className="text-uppercase">description</th>
            <th className="text-uppercase text-end">qty</th>
            <th className="text-uppercase text-end">unit price</th>
            <th className="text-uppercase text-end">amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Placeholder />
            </td>
            <td className="text-end">
              <Placeholder />
            </td>
            <td className="text-end">
              <Placeholder />
            </td>
            <td className="text-end">
              <Placeholder />
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td className="text-end">
              <Placeholder />
            </td>
            <td className="text-end">
              <Placeholder />
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td className="text-end">
              <Placeholder />
            </td>
            <td className="text-end">
              <Placeholder />
            </td>
          </tr>
          <tr>
            <td colSpan={3} className="text-end">
              <div className="d-flex flex-column">
                <Placeholder />
                <Placeholder />
              </div>
            </td>
            <td className="text-end">
              <Placeholder />
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default UpcomingInvoiceSkeleton
