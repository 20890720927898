import React from "react"
import PropTypes from "prop-types"

import "./index.css"

const PlanCard = ({
  selected = true,
  name = "",
  interval = "",
  price = 0,
  features = [],
  btnText = "",
  handleClick,
}) => {
  return (
    <div
      className={`PriceColumn priceCard ${
        selected ? "is-highlight " : " "
      } d-flex flex-column justify-content-start align-items-center shadow-sm border-3`}
    >
      <div className="PriceColumn-content d-flex flex-column justify-content-start align-items-center">
        <div className="PriceColumn-aboutProduct d-flex flex-column">
          <div className="PriceColumn-textAndProductImage d-flex">
            <div
              className="PriceColumn-textContainerHeightAligner"
              style={{ height: "35px" }}
            >
              <div className="PriceColumn-textContainer d-flex flex-column">
                <div className="PriceColumn-name d-flex justify-content-between align-items-center">
                  <span className="Text Text-color--default Text-fontSize--20 Text-fontWeight--600">
                    {name}
                  </span>
                </div>
                <span className="PriceColumn-description Text Text-color--default Text-fontSize--14"></span>
              </div>
            </div>
          </div>
        </div>
        <div className="PriceColumn-priceAndButton d-flex flex-column">
          <div className="PriceColumn-priceContainerHeightAligner">
            <div className="PriceColumn-priceContainer">
              <div className="d-flex spacing-4 flex-column align-items-start">
                <div className="d-flex align-items-center">
                  <span className="PriceColumn-price Text Text-color--default Text-fontSize--36 Text-fontWeight--700">
                    <span>${price}</span>
                  </span>
                  <span className="PriceColumn-interval Text Text-color--default Text-fontSize--13">
                    per <br />
                    {interval}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <button
            className={`Button PriceColumn-button Button--primary Button--lg  ${
              selected ? "selected-price-btn border-0" : "default-price-btn"
            }`}
            type="button"
            onClick={handleClick}
            disabled={selected ? true : false}
          >
            <div className="d-flex justify-content-center align-items-center">
              <span className="Text Text-color--default Text-fontWeight--500 Text--truncate">
                {btnText}
              </span>
            </div>
          </button>
        </div>
      </div>
      <div className="PriceColumn-featureListContainer d-flex flex-column">
        <span className="PriceColumn-featureListHeader Text Text-color--default Text-fontSize--14">
          This includes:
        </span>
        <div className="PriceColumn-featureList d-flex flex-column align-items-start">
          {features.map((feature, index) => (
            <div
              key={`${index} + 1`}
              className="PriceColumn-feature d-flex align-items-start"
            >
              <div className="PriceColumn-checkContainer d-flex">
                <svg
                  className="InlineSVG Icon PriceColumn-check Icon--sm"
                  focusable="false"
                  fill="#1a1a1a"
                  color="#1a1a1a"
                  fillOpacity="0.5"
                  height="16"
                  viewBox="0 0 16 16"
                  width="16"
                >
                  <path
                    d="m8 16c-4.418278 0-8-3.581722-8-8s3.581722-8 8-8 8 3.581722 8 8-3.581722 8-8 8zm3.0832728-11.00479172-4.0832728 4.09057816-1.79289322-1.79289322c-.39052429-.39052429-1.02368927-.39052429-1.41421356 0s-.39052429 1.02368927 0 1.41421356l2.5 2.50000002c.39052429.3905243 1.02368927.3905243 1.41421356 0l4.79037962-4.79768495c.3905243-.3905243.3905243-1.02368927 0-1.41421357-.3905243-.39052429-1.0236893-.39052429-1.4142136 0z"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </div>
              <span className="PriceColumn-featureText Text Text-color--default Text-fontSize--14">
                {feature}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

PlanCard.propTypes = {
  selected: PropTypes.bool,
  name: PropTypes.string.isRequired,
  interval: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  features: PropTypes.arrayOf(PropTypes.string),
  btnText: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default PlanCard
