/* eslint-disable react/prop-types */
import React, { useState } from "react"
import { Button, FormFeedback } from "reactstrap"

import UseKafka from "hooks/useKafka"

import ReactstrapInput from "components/Inputs/ReactstrapInput"
import { Spinner } from "components"
import { ActivitiesEnum, DATA_FORMATS } from "utils/enums"
import StructureFormatToolTip from "../utils/structure-format-tooltip"

const KafkaInputForm = ({
  configuration,
  configurationData,
  userInputs,
  setUserInputs,
}) => {
  const {
    error,
    status,
    isDisabled,
    isLoading,
    validateHosts,
    _SetError,
    setStatus,
  } = UseKafka()

  const [selectedFormat, setSelectedFormat] = useState(DATA_FORMATS.STRUCTURED)

  const handleChangeFormat = value => {
    setSelectedFormat(value)
    setUserInputs({
      ...userInputs,
      dataStructure: value,
    })
  }

  return (
    <>
      {configurationData?.activity?.requiredInputs.map(
        (requiredInput, index) => {
          return (
            <div className="mb-3" key={index}>
              {requiredInput.Key === "seed_brokers" ? (
                <div className="d-flex flex-row w-100">
                  <div className="w-100 me-2">
                    <ReactstrapInput
                      className={`form-control  ${
                        status === null
                          ? ""
                          : status === true
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      type="text"
                      name={requiredInput.Key}
                      value={userInputs[requiredInput.Key] || ""}
                      placeholder={
                        requiredInput.isRequired === true
                          ? requiredInput.Label + "*"
                          : requiredInput.Label
                      }
                      required={
                        requiredInput.isRequired
                          ? requiredInput.isRequired
                          : true
                      }
                      onChange={e => {
                        if (!e.target.value) setStatus(false)
                        if (!e.target.value) _SetError()
                        setUserInputs({
                          ...userInputs,
                          [requiredInput.Key]: e.target.value.trim(),
                          dataStructure: selectedFormat,
                        })
                      }}
                    />
                    {error && <FormFeedback invalid>{error}</FormFeedback>}
                  </div>
                  <div className="">
                    <button
                      disabled={
                        !userInputs?.seed_brokers || isLoading ? true : false
                      }
                      className="d-flex btn btn-primary align-items-center"
                      onClick={() =>
                        validateHosts({
                          seedBrokers: userInputs.seed_brokers,
                          role: "producer",
                        })
                      }
                    >
                      <span>Validate</span> {isLoading && <Spinner />}
                    </button>
                  </div>
                </div>
              ) : (
                <ReactstrapInput
                  className="form-control"
                  type="text"
                  name={requiredInput.Key}
                  placeholder={
                    requiredInput.isRequired === true
                      ? requiredInput.Label + "*"
                      : requiredInput.Label
                  }
                  required={
                    requiredInput.isRequired ? requiredInput.isRequired : true
                  }
                  disabled={isDisabled}
                  onChange={e => {
                    setUserInputs({
                      ...userInputs,
                      [requiredInput.Key]: e.target.value.trim(),
                    })
                  }}
                />
              )}
            </div>
          )
        }
      )}
      {configuration === ActivitiesEnum.KafkaToPostgres && (
        <>
          <span className="d-flex align-items-center p-0 mb-2">
            Select Data Dump Format &nbsp;
            <i id="kafka-structure-label" className="bx bx-info-circle"></i>
          </span>
          <StructureFormatToolTip targetId="kafka-structure-label" />

          <div className="d-flex justify-content-evenly mb-3 gap-3">
            <div className="w-100">
              <Button
                className="button-hover w-100"
                outline={!(selectedFormat === DATA_FORMATS.STRUCTURED)}
                color="primary"
                value={DATA_FORMATS.STRUCTURED}
                onClick={() => handleChangeFormat(DATA_FORMATS.STRUCTURED)}
              >
                Structured
              </Button>
            </div>
            <div className="w-100">
              <Button
                className="button-hover w-100"
                outline={!(selectedFormat === DATA_FORMATS.UNSTRUCTURED)}
                color="primary"
                value={DATA_FORMATS.UNSTRUCTURED}
                onClick={() => handleChangeFormat(DATA_FORMATS.UNSTRUCTURED)}
              >
                Unstructured
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default KafkaInputForm
